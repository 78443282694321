@import "../../../assets/styles/base/base";

//.ConfirmDeleteModalHeader{
//  border-bottom: 1px solid #BDBDBD;
//}

.ConfirmDeleteModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  flex: 1;

  .text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--asr-a-grey-1);
  }

  .subText {
    margin-top: 30px;
  }
}

.ConfirmDeleteModalFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
  width: 100%;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--asr-a-grey-4);
  }
}
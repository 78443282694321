@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

// SINGLE
body {
  .asr-autocomplete-single-container {
    .MuiAutocomplete-endAdornment {
      top: 3px;
    }

    .select__popup-indicator {
      color: #333333;
      background-color: transparent !important;

      &:hover {
        background-color: transparent;

        .MuiIconButton-label {
          border-radius: 3px;
          background-color: transparent !important;
        }

        svg {
          fill: var(--asr-a-blue);
        }
      }

      svg {
        width: 20px;
        margin-left: 5px;
        height: 20px;
        background: white;
      }
    }

    &__form {
      height: 46px;
    }

    fieldset {
      legend {
        width: 0;
      }
    }

    &__error {
      fieldset {
        border-color: var(--asr-error) !important;
      }
    }
  }
}


.asr-autocomplete-single {
  .select__clear-indicator {
    background: #fff;
    border-radius: 0;
    padding-left: 0;

    &:hover {
      background-color: #fff;
    }
  }


  &__disabled {
    .select {
      background: var(--asr-a-grey-6);

      .MuiAutocomplete-endAdornment {
        display: none;
      }

      fieldset {
        border: 1px solid var(--asr-a-grey-3) !important;
      }

      &:hover {
        fieldset {
          border: 1px solid var(--asr-a-grey-3) !important;
        }
      }
    }
  }
}

.MuiPaper-root {
  &.MuiAutocomplete-paper {
    margin: 0;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }
}

.asr-autocomplete-paper {
  width: 100%;
  min-width: 360px;

  &.flow-paper {
    min-width: unset;
  }

  .MuiAutocomplete-listbox {
    @include scroll-bar-autocomplete;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      height: fit-content !important;

      &:hover {
        background: var(--asr-a-grey-6);
        cursor: pointer;
      }
    }

    .MuiAutocomplete-option {
      background: white !important;

      &:hover {
        background: #F2F2F2 !important;
      }
    }

    .MuiAutocomplete-option[aria-selected="true"] {
      background: #F2F2F2;
    }
  }

  @include phones() {
    width: 100%;
    min-width: 100%;
  }
}

.asr-autocomplete-text-field {
  .MuiInputBase-input {
    &::placeholder {
      font-family: Roboto, sans-serif;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      letter-spacing: 0.05em;
      color: #333333 !important;
      opacity: 1;
      text-transform: none;
    }
  }
}

.asr-table-autocomplete-text-field {
  position: relative;
  background: white;
  //.MuiAutocomplete-inputRoot {
  //  padding-right: 9px !important;
  //}

  .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    //transform: translateX(-4px) rotate(180deg);
  }

  .MuiAutocomplete-clearIndicator {
    //z-index: 1;
  }

  .MuiAutocomplete-popupIndicator {
    background: transparent;

    &:hover {
      background-color: transparent;

      .MuiIconButton-label {
        background-color: transparent;
      }
    }
  }

  .MuiInputBase-input {
    z-index: 1;

    &::placeholder {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #4F4F4F;
      text-transform: initial !important;
    }
  }
}

// FORMS MULTIPLE AUTOCOMPLETE
.asr-form-autocomplete-multi {
  height: 32px;
}

// TABLES
@import "table__autocomplete";
@import "field_views";
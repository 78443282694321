@import "src/assets/styles/base/base";

.asr-autocomplete-single {
  position: relative;
  width: 100%;

  .select__input {
    padding: 0 !important;
    height: 100%;
    color: $client-content-color;
    letter-spacing: 0.05em;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::placeholder {
      text-transform: capitalize;
      opacity: 1;
      padding: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      color: $client-content-color;
    }
  }

  .select__root {
    width: 100%;
    height: 100%;

    .MuiFormControl-root {
      height: 100%;
    }

    fieldset {
      border: 0.5px solid #242323;

      legend {
        width: fit-content;
      }
    }

    .Mui-focused {
      fieldset {
        border-color: #24b2b2;
      }
    }

    @media screen and (max-width: 767px) {
      flex: 1;
    }
  }

  .select {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    border-radius: 3px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    &:hover {
      fieldset {
        border-color: #24b2b2;
      }
    }
  }

  .select__clear-indicator {
    .MuiIconButton-label {
      border-radius: 3px;
      background-color: #c0bfbf;

      path {
        fill: #ffffff;
      }
    }
  }

  .select__popup-indicator {
    &:hover {
      background-color: transparent;

      .MuiIconButton-label {
        border-radius: 3px;
        background-color: #c0bfbf;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .select-loading-indicator {
    position: absolute;
  }
}
@import 'src/assets/styles/base/base';

.app-toolbar-private {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.app-header__client-theme {
  background-color: var(--asr-header-bg) !important;
}

.app-header__logo-wrapper {
  display: flex;
  flex-flow: nowrap;
  gap: 8px;

  .jr-menu-icon {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo-btn {
    margin-top: 0.4em;
  }
}

.app-header__language-list {
  background-color: #000000;
  top: 13px !important;

  .list-unstyled {
    li {
      border-bottom: 0.3px solid #C0BFBF;
    }

    h4 {
      font-family: Robot, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      color: var(--asr-header-language-list-item-color);
    }

    li:hover h4 {
      color: var(--asr-header-language-list-item-hover);
    }
  }

  pointer-events: none;

  &.show {
    pointer-events: auto;
  }
}

.quick-menu .dropdown-menu:after {
  border-bottom-color: #000000;
}

.app-header__language-current {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--asr-header-text-color);
}

.app-header__language-icon__wrapper {
  width: 37px !important;
  height: 37px !important;
  position: relative;
}

.app-header__language-icon_st0 {
  fill: var(--asr-header-logo-color-st0) !important;
}

.app-header__language-icon_st1 {
  fill: var(--asr-header-logo-color-st1) !important;
}

.app-header__language-icon_st1-ver2 {
  fill: var(--asr-header-logo-color-st1-ver2) !important;
}

.app-header__language-icon_st2 {
  fill: var(--asr-header-logo-color-st2)
}
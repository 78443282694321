.AsrAutocompleteMultipleRenderInput {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 20px;
  padding-right: 16px;
  padding-left: 8px;
  z-index: 0;
  width: 100%;
  //width: calc(100% - 20px);
  overflow: hidden;

  text-overflow: ellipsis;
}

.RenderInputLabel,.RenderInputLabelHidden, .RenderInputCount {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: var(--asr-a-grey-1);
  white-space: pre;
}

.RenderInputLabelHidden{
  position: absolute;
  top: -9999px;
}

.RenderInputCount {
  width: fit-content;
  padding-right: 5px;
}

.RenderInputLabel {
  text-overflow: ellipsis;
  overflow: hidden;
}
.systemNotificationsContainer {
  max-width: 600px;
  display: flex;
  align-items: center;
  min-width: 320px;
  background: #000000;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}

.systemNotificationsContentWrapper {
  display: flex;
  align-items: center;

  & > p {
    font-family: Roboto, sans-serif;
    margin: 0 5px 0 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #BDBDBD;
    letter-spacing: 0.05em;
  }

  & > .colorError {
    color: #EB5757 ;
  }
}

.systemNotificationsLogo {
  width: 40px;
  height: 40px;
}
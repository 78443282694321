@import 'src/assets/styles/abstracts/mixins_media';

.app-logo {
  display: flex;
  margin-left: .5rem;
}

.asr-header-logo {
  margin-right: .8rem;
}

.asr-header-logo--default {
  margin-right: .2rem;
  height: 19px ! important;
  @include phones() {
    height: 12px ! important;
  }
}

.asr-header-sub-logo {
  @include tablets() {
    display: none;
  }
}

.asr-header-sub-logo--default {
  @include tablets() {
    display: block;
  }
}
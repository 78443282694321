$expanded-width: 140px;
$collapsed-width: 35px;
$transitionType: ease-in-out;
$transitionDelay: .3s;

@mixin langSelectAnimate($x : 0, $delay: $transitionDelay ) {
  transform: translateX($x);
  transition: transform $delay $transitionType;
}

@mixin langSelectContent($content) {
  &:after {
    content: $content;
    position: absolute;
    left: 8px;
    top: 6px;
    right: 22px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.05em;
    color: #FFFFFF;

    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.LanguageSwitcher {
  position: absolute;
  top: 14px;
  right: 55px;
  width: $expanded-width;
  height: 28px;
  overflow: hidden;
  border-radius: 2px;
  /// SELECT
  .LangSelectWrapper {
    background: greenyellow;
    position: relative;
    height: 100%;
    background: var(--asr-a-grey-4);
    overflow: hidden;
    border-radius: 2px;

    @include langSelectAnimate(105px);

    & > div {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    .LangSelect, .LanguageSwitcherSelectCustom {
      position: relative;
      padding: 6px 28px 6px 8px !important;
    }

    .LangSelectIcon {
      color: white;
    }
  }

  .LangSelectWrapper {
    @include langSelectContent(attr(data-locale));

    &:hover {
      @include langSelectAnimate(0);
      @include langSelectContent(attr(data-lang))
    }
  }
}

// EXPANDED
.LanguageSwitcherExpanded {
  .LangSelectWrapper {
    @include langSelectAnimate(0);
  }
}

//
.LanguageSwitcherWithoutCloseBtn {
  right: 20px;
}


// MENU ITEM
.MenuRoot {
  .MenuPaper {
    transform: translateY(-28px) !important;
    background-color: var(--asr-a-grey-4);
    border-radius: 2px;
    box-shadow: none;

    .LanguageSwitcherMenuItemRoot {
      padding: 3px 10px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: white;
      transition: .3s;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: var(--asr-a-blue);
        transition: .3s;
      }
    }

    .LanguageSwitcherMenuItemSelected {
      background-color: var(--asr-a-blue) !important;
    }
  }
}

.LanguageSwitcherIconCustom {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 13px;
    height: 7px;

    path {
      fill: white;
    }
  }
}

.LangSelectCustomLabel {
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@import "src/assets/styles/abstracts/mixins_media";

.asr-survey-results-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 64px);
  padding-bottom: 50px;

  .row {
    margin: 10px 25px;
  }
}
$froala-error-indent: 20px;

.formContainer {

  button {
    min-width: 40px !important;
  }
}

.editorText {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: var(--asr-a-grey-2);
  padding: 20px;
  word-break: break-word;
  white-space: normal;

  p {
    margin: 0;
  }

  a {
    color: var(--asr-a-blue) !important;
  }
}

.formContainerFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.formContainerFooterCheckBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--asr-a-blue);
  letter-spacing: 0.01em;
  margin-top: 10px;
}

.formContainerFooterCheckBoxItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.noNotes {
  padding-left: 20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--asr-a-grey-3);
}

.formContainerFooterCheckBoxItemLabel {
  margin-right: 10px;

  &:hover {
    color: var(--asr-a-grey-1);
  }
}

.formContainerFooterCheckBoxItemLabelDisabled {
  color: var(--asr-a-grey-4);

  &:hover {
    color: var(--asr-a-grey-4);
  }
}

.formContainerFooterActionButtons {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: 10px;
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 15px !important;
  background: var(--asr-a-grey-1) !important;
  border-radius: 3px !important;
  height: var(--asr-form-button-height);
  margin-bottom: 0.25rem !important;
  border: none !important;

  &:hover {
    background: var(--asr-a-blue) !important;
    border: none !important;
  }

  &:disabled {
    background: var(--asr-a-grey-5) !important;
    color: #FFFFFF !important;
    border: none !important;
  }
}

.deleteButton {
  background: var(--asr-a-red) !important;
  margin-right: 20px !important;
  margin-bottom: 0.25rem !important;
}

.cancelButton {
  border: none !important;
  background: transparent !important;
  margin-right: 20px !important;

  .label {
    font-size: 14px;
    color: var(--asr-a-grey-1) !important;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
  }

  &:hover {
    background: transparent !important;

    .label {
      color: var(--asr-a-blue) !important;
    }
  }
}

.label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: white !important;
  text-transform: capitalize;
}


.froalaEditorContainer {
  position: relative;
  padding-bottom: $froala-error-indent;
}

.froalaEditorContainerError {
  position: absolute;
  bottom: 0;
}

.hidden {
  position: absolute;
  top: -9999px;
  visibility: hidden;
  opacity: 0;
}

// HASHTAGS
.noteHashtagsContainer {
  display: flex;
  padding: 5px 0 0 20px;
}

.noteEditor {
  display: flex;
  flex-direction: column;
}

.noteHashtagsInputContainer {

}

.noteHashtagsInputField {
  display: flex;
  flex: 1;
  padding: 0 !important;
  margin-left: 20px !important;
  background: #FFFFFF;

  fieldset {
    border: none;
  }

  input, input::placeholder {
    padding: 0 7px !important;
    opacity: 1;
    border: none !important;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
    color: var(--asr-a-grey-3) !important;

    &:focus {
      border: none !important;
    }
  }

  input {
    height: 30px !important;
  }
}

.noteContent {
  display: flex;
  flex-direction: column;
}

.noteHashtags {
  display: flex;
  flex-wrap: wrap;
  margin-left: 35px;
  flex: 3;
}

.noteHashtagsLabel {
  margin-right: 0;
  padding-bottom: 5px;
}

.noteHashtagsItem {
  margin-right: 8px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  background: #FFFFFF;
  border-radius: 3px;
  word-break: break-word;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: var(--asr-a-grey-2);

  &:last-child {
    margin-right: 0;
  }
}


.SimpleMessageModal {
  max-width: 500px;
  padding: 20px;
  border-radius: 6px;
  background-color: var(--asr-a-grey-bluish);
}

.SimpleMessageModalContent {
  word-wrap: break-word;
  color: white;
  letter-spacing: 0.05em;
  max-width: calc(100% - 40px);
}

.SimpleMessageModalCloseButton {
  svg {
    width: 30px;
    height: 30px;
  }

  path {
    fill: white;
  }
}
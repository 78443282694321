.OrganizationLinkList {
  max-height: 600px;
  overflow-y: auto;


  &::-webkit-scrollbar {
    width: 5px;
    background-color: #F2F2F2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #24B3B3;
    width: 10px;
  }
}
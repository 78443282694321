@import "src/assets/styles/abstracts/mixins_media";

.tab-indicator {
  background-color: var(--asr-active-tab-border-bg-color) !important;
  display: block;
  height: 3px !important;
}


.asr-tab-item {
  &--root {
    &.Mui-selected.MuiTab-root {
      color: var(--asr-a-grey-1);
    }

    .textColorPrimary {
      color: var(--asr-a-grey-1);
    }
  }
}

.tabs-wrapper {
  border-radius: 0;
  margin-right: 0;
  @include phones() {
    padding: 10px;
  }

  .card-title {
    margin-bottom: 15px;
    box-shadow: none;
    border-bottom: 1px solid var(--asr-a-grey-3);
  }

  .MuiTabs-root {
    min-height: 40px;
  }

  .MuiTab-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--asr-a-grey-1);
    @include tablets() {
      flex-direction: column;
    }
  }

  .MuiTab-root {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    padding: 10px 10px 10px 20px;
    min-height: 40px;

    @include tablets() {
      flex-direction: column;
    }
  }

  .MuiTab-labelIcon {
    min-height: 37px;

    & .MuiTab-iconWrapper {
      margin-bottom: 0;
      margin-top: 0;

      margin-right: 10px;
    }
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: var(--asr-a-grey-1);

    .MuiTab-wrapper {
      color: var(--asr-a-grey-1);
    }

    &:hover {
      background: white;
      color: var(--asr-a-grey-1);

      .MuiTab-wrapper {
        color: var(--asr-a-grey-1);
      }
    }
  }

  .MuiTab-textColorInherit {
    opacity: 1;

    .MuiTab-wrapper {
      color: var(--asr-a-grey-3);
    }

    &:hover {
      background: var(--asr-a-blue);
      opacity: 1;
      color: white;

      .MuiTab-wrapper {
        color: white;
      }
    }
  }
}

.MuiTab-labelIcon .MuiTab-wrapper:first-child {
  i, div {
    margin: 0 10px 0 0;
    font-size: 1rem;
  }
}

// FORM TABS
.asr-form-tabs-root{
  .asr-form-tabs-indicator {
    background-color: var(--asr-active-tab-border-bg-color);
    display: block;

    &--item {
      text-transform: initial;
      color: var(--asr-a-grey-3);
      font-weight: 400;
    }

    &--item.Mui-selected {
      color: var(--asr-a-grey-1);
      font-weight: 500;
    }
  }
}

// ========== PROGRESS BAR ==========
.sd-progress-buttons__list-container {
  // hide ul if only one child appeared
  ul:only-child > li:only-child {
    display: none;
  }
}

// FINAL
.sd-progress-buttons {
  .sd-progress-buttons__list {
    // <li> as page map iteration with general settings (+ no page number)
    li {
      // change steps connector with custom color
      &:not(:first-child) {
        .sd-progress-buttons__connector {
          background-color: var(--asr-a-grey-1);
        }
      }

      // add opacity to steps connector
      &:not(.sd-progress-buttons__list-element--passed):not(.sd-progress-buttons__list-element--current) {
        .sd-progress-buttons__connector {
          opacity: 0.3;
        }
      }

      &.sd-progress-buttons__list-element--current {
        .sd-progress-buttons__button {
          color: var(--asr-a-grey-1);
          border-color: var(--asr-a-grey-1);

          .sd-progress-buttons__button-background {
            left: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            top: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }

          .sd-progress-buttons__button-content {
            border-color: var(--asr-a-grey-6);
            left: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            top: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }
        }
      }

      &:not(.sd-progress-buttons__list-element--current) {
        .sd-progress-buttons__button {
          .sd-progress-buttons__button-content {
            color: var(--asr-a-grey-1);
            border-color: var(--asr-a-grey-1);
          }
        }
      }

      // ON <LI> HOVER
      &:hover {
        &.sd-progress-buttons__list-element--current {
          .sd-progress-buttons__button {
            color: var(--asr-a-grey-1);
            border-color: var(--asr-a-grey-1);

            .sd-progress-buttons__button-content {
              border-color: var(--asr-a-grey-6) !important;
              border: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--asr-a-grey-1);
            }
          }
        }

        &:not(.sd-progress-buttons__list-element--current) {
          .sd-progress-buttons__button {
            color: var(--asr-a-grey-1);
            border-color: var(--asr-a-grey-1);

            .sd-progress-buttons__button-background {
              left: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
              top: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            }

            .sd-progress-buttons__button-content {
              left: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
              top: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
              border: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--asr-a-grey-1);
              border-color: var(--asr-a-grey-6);
            }
          }
        }
      }
    }

    // <li> as page map iteration with a page number
    li:not([data-page-number=""]) {
      .sd-progress-buttons__button {
        margin: calc(1.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));

        .sd-progress-buttons__button-background,
        .sd-progress-buttons__button-content {
          left: calc(-0.125 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          top: calc(-0.125 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          width: 10px;
          height: 10px;
        }

        .sd-progress-buttons__button-content {
          border-color: var(--asr-a-grey-6);
          background-color: var(--asr-a-grey-6);
        }

        span {
          color: var(--asr-a-grey-1);
          letter-spacing: 0;
          font-size: 6px;
        }
      }

      &.sd-progress-buttons__list-element--current {
        .sd-progress-buttons__button {
          margin: calc(1.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          margin-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));

          span {
            margin: calc(-0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }
        }
      }

      &:not(.sd-progress-buttons__list-element--current) {
        .sd-progress-buttons__button {
          margin-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));

          span {
            margin: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }
        }
      }

      &.sd-progress-buttons__list-element--passed {
        .sd-progress-buttons__button {
          background-color: var(--asr-a-grey-3);
        }
      }

      // ON <LI> HOVER
      &:hover {
        .sd-progress-buttons__button {
          .sd-progress-buttons__button-content {
            background-color: var(--asr-a-grey-5);
            border-color: var(--asr-a-grey-5);
          }
        }

        &.sd-progress-buttons__list-element--current {
          .sd-progress-buttons__button {
            .sd-progress-buttons__button-background,
            .sd-progress-buttons__button-content {
              left: calc(-0.375 * (var(--sjs-base-unit, var(--base-unit, 8px))));
              top: calc(-0.375 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            }
          }

          span {
            margin: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }
        }

        &:not(.sd-progress-buttons__list-element--current) {
          .sd-progress-buttons__button {
            .sd-progress-buttons__button-background,
            .sd-progress-buttons__button-content {
              left: calc(-0.375 * (var(--sjs-base-unit, var(--base-unit, 8px))));
              top: calc(-0.375 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            }
          }

          span {
            margin: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
          }
        }
      }
    }
  }
}

.sd-progress {
  height: 40px !important;
  color: $client-content-color !important;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  max-width: 770px;
  width: 100%;
  // fix for creator
  margin-left: auto;
  margin-right: auto;

  &.sd-body__progress--top {
    margin-top: 30px;
  }

  .sd-progress__bar {
    background-color: $client-content-color !important;
    border-radius: 8px 0 0 8px;
    margin-top: 0 !important;

    .sd-hidden {
      display: block !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #FFFFFF;
      top: 5px !important;
      left: 20px;
      width: max-content;
    }
  }
}
.SideNavOrganizationInfo {
  display: flex;
  flex-direction: column;
}

.SideNavOrganizationInfoName {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #E0E0E0;
  margin-bottom: 10px;
  word-break: break-word;
}

.SideNavOrganizationInfoCode {
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  color: #E0E0E0;
}
@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";

.multipletext-input__table__wrapper {
  .sv_q_mt_row {
    border-bottom: none;
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: center;

    @include phones() {
      flex-direction: column;
      align-items: flex-start;
    }

    td {
      text-align: center;
      width: 100%;
      //&:first-child {
      //  flex: 1;
      //  max-width: 230px;
      //  margin-right: 10px;
      //  padding-left: 10px;
      //}
      //
      //&:last-child {
      //  flex: 1;
      //  max-width: 420px;
      //}

      padding: 0;

      .text-input {
        padding: 0;
        height: 60px !important;
        background: #FFFFFF !important;
        border-right: none !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: 1px solid #828282 !important;
        border-radius: 0 !important;

        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px !important;
        letter-spacing: 0.05em;
        color: $client-content-color !important;

        transition: $transition-time;

        &:hover {
          color: var(--asr-a-blue) !important;
          border-color: var(--asr-a-blue) !important;
        }
      }
    }
  }
}

.multipletext-input__item__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: $client-content-color;
  margin-right: 10px;
  max-width: 230px;
  flex: 1;
  text-align: left;

  @include phones() {
    font-size: 16px;
  }
}

.multipletext-input__item__value {
  background: #F4F4F4;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: $client-content-color;
}


//  READ ONLY
.sv_q.sv_qstn.q-root-multipletext.multiplex-input__wrapper {
  .survey-question-card-body.read-only {
    .sv_q_mt_row {
      .text-input {
        color: $client-content-color !important;
        border-bottom: 1px solid #828282 !important;
      }
    }
  }
}

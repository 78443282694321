@import "abstracts/variables";
// TABLES
@import "components/tables";
// FORMS
@import "components/forms";
// BUTTONS
@import "components/buttons";
// LABELS
@import "components/labels";
// HASHTAGS
@import "components/hashtags";
// SURVEY
@import "components/survey/survey";
// MODALS
@import "components/modals";
// TOOLTIPS
@import "components/tooltips";
// AUTOCOMPLETE
@import "components/autocomplete/autocomplete";
// DOTS MENU
@import "components/dots-menu";
// COLLAPSE CARD
@import "components/collapse-card";
// MUI GLOBAL STYLES
@import "components/mui-global";
// AUTH
@import "components/auth";
// TABS
@import "components/tabs";
// ICONS
@import "components/icons";
// LANDING
@import "components/landing";
// ERRORS
@import "components/errors";
// LOADERS
@import "components/loaders";
// PAGES
@import "components/pages";
// CUSTOM BOOTSTRAP
@import "components/bootsrap";
// FROALA
@import "components/froala";

.asr-select-loading-indicator {
  position: absolute;
  right: 50%;
  z-index: 1000;
}

.asr-link {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--asr-organization-link-color);

  &:hover {
    color: var(--asr-organization-link-color);
  }

  &__disabled {
    color: var(--asr-a-grey-1);

    &:hover {
      color: var(--asr-a-grey-1);
    }
  }
}

.asr-three-dots-icon {
  color: #4F4F4F;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//
.progress-bar {
  background: #ccc;
  //padding:10px 0;
  width: 100%;
  text-align: center;
  position: relative;
  color: var(--asr-a-blue-3);
}

.progress-fill {
  background: var(--asr-a-blue-3);
  color: #fff;
  //padding:10px 0;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.progress-fill-text {
  color: white;
}
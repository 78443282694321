@import "src/assets/styles/base/base";
@import "./mixins/questions-mixins";


.radio-input {
  width: 100%;
  padding-left: 0;


  .radio-input__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    margin-bottom: 5px;
    width: 100%;
    padding-right: 0;

    .form-group {
      width: 100%;
    }

    &.checked {
      background: transparent;

      .radio-input__item__label {
        color: white;
        background: var(--asr-a-grey);

        .circle {
          &:after {
            transform: scale(1);
          }
        }

        &:hover {
          background: #4F4F4F;
        }
      }
    }


    .radio-input__item__label {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 75px;
      padding-right: 10px;
      cursor: pointer;
      font-style: normal;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: $client-content-color;
      user-select: none;
      font-weight: 500;
      height: 100%;
      width: 100%;
      min-height: 60px;
      font-family: Roboto, sans-serif;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      input:checked ~ .circle {
        background-color: #fff;
        border: 1px solid #FFFFFF;

        .sv-hidden {
          display: flex !important;
          fill: var(--asr-a-red);
          transform: scale(1);
        }
      }

      .circle {
        @include radio-input-circle;
      }

      &:hover {
        background: var(--asr-a-blue);
        transition: $transition-time;
        color: white;

        a {
          color: #FFFFFF !important;
        }

        .circle {
          background-color: #fff;
          border: 1px solid #FFFFFF;

          .sv-hidden {
            transition: $transition-time;
            display: flex !important;
            fill: var(--asr-a-blue);
            transform: scale(1);
          }
        }

      }
    }
  }
}

.survey-question-card-body.read-only {
  .radio-input__item {
    @include read-only-radio-hover();

    &.checked {
      @include read-only-radio-hover(
              $color: white,
              $backgroundColor: var(--asr-a-grey),
              $checkedMarkFill: var(--asr-a-red),
              $checkedMarkBgColor: white,
              $borderColor: white);
    }
  }
}

//
.sv_cw_pretty_checkbox_radiogroup {
  label {
    word-break: break-word;
  }
}
@import "../../../../assets/styles/abstracts/mixins_media";

.Banner {
  position: absolute;
  z-index: 1000;
  bottom: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 90px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), white 25% 75%, rgba(0, 0, 0, 0));
  box-shadow: 0 0 10px 0 var(--asr-a-grey-4);
  letter-spacing: 0.05em;
  color: var(--asr-a-grey-bluish);

  @include desktop() {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white 20% 80%, rgba(0, 0, 0, 0));
  }

  @include laptop() {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white 15% 85%, rgba(0, 0, 0, 0));
  }

  @include tablets() {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white 12% 88%, rgba(0, 0, 0, 0));
  }

  @include phones() {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white 10% 90%, rgba(0, 0, 0, 0));
  }
}

.BannerContentContainer {
  width: 52%;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;

  @include desktop() {
    width: 62%;
    flex-flow: column;
  }

  @include laptop() {
    width: 72%;
  }

  @include tablets() {
    width: 78%;
  }

  @include phones() {
    width: 84%;
  }
}

.BannerTextBlock {
  padding: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: var(--asr-a-grey-bluish);

    &:hover {
      color: var(--asr-a-yellow);
      text-decoration: none;
    }
  }
}

.BannerActionsBlock {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 25px 20px;

  @include desktop() {
    padding-top: 0;
    flex-flow: nowrap;
    justify-content: flex-end;
    gap: 15px;
  }

  @include phones() {
    justify-content: space-around;
  }

  .ActionButton {
    display: block;
    padding: 6px 24px;
    border-radius: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: var(--asr-a-grey-1);
    cursor: pointer;
    text-transform: lowercase;
    box-shadow: 0 0 10px 0 var(--asr-a-grey-4);
    width: fit-content;
    min-width: 100px;
    text-align: center;

    &:hover {
      color: var(--asr-a-yellow);
    }

    &.AcceptButton {
      @include desktop() {
        order: 2;
      }
    }

    &.RejectButton {
      @include desktop() {
        order: 1;
      }

      &:hover {
        color: var(--asr-a-red);
      }
    }
  }
}

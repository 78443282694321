@import "src/assets/styles/base/base";
@import "mixins/questions-mixins";

@mixin circle-after($color: var(--asr-a-blue),$scale: 0) {
  &:after {
    content: "";
    position: absolute;
    left: 24%;
    right: 24%;
    top: 24%;
    bottom: 24%;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: $color;
    transform: scale($scale);
    transition: $transition-time;
  }
}

.sv_q.sv_qstn.matrix-input__wrapper {
  .question__root.matrix-input {
    th, td {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal !important;
      font-size: 14px;
      line-height: 23px !important;
      letter-spacing: 0.05em;
      color: $client-content-color;
      min-width: 100px !important;
    }

    td:first-child[data-responsive-title] {
      text-align: center;
    }

    th {
      text-align: center;
      font-size: 14px;
    }

    td[title] {
      text-align: center;
    }

    td {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: $client-content-color;
    }

    .matrix-input__label {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      height: 40px;
      width: 40px;
      margin-top: 0;

      & + div {
        margin-top: 15px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      input:disabled ~ .circle {
        @include circle-after();
      }

      input:checked ~ .circle {
        @include circle-after($color: var(--asr-a-red), $scale: 1);
      }

      .circle {
        @include radio-input-circle($bg-color: white);
        position: relative;
        top: 0;
        left: 0;
        @include circle-after;
      }

      &:hover {
        .circle {
          background-color: #fff;
          @include circle-after($scale: 1);
        }
      }
    }

  }
}
.survey_custom-navigation {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 24px;
}

.survey__navigation-button {
  background-color: $client-content-color;
  border-radius: 5px;
  height: 30px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-transform: capitalize;
  color: #FFFFFF;
  border: none;
  width: 110px;
}

.survey__navigation-group {
  display: flex;
  align-items: center;

  .survey__unanswered {
    color: #FFFFFF;
    margin-right: 20px;

    .page {
      font-weight: 500;
    }
  }
}
@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

.sv_row {
  padding: 0 !important;
  margin: 30px 0;
  box-shadow: none;
  background-color: transparent !important;
  border-bottom: none !important;
  max-width: 90vw;

  .survey-question-card-header, .survey-question-card-body {
    a {
      color: var(--asr-link-color) !important;
    }
  }


  .sv_q.sv_qstn {
    &[aria-invalid="true"] {
      .survey-question-card-header {
        background: var(--asr-a-red);
      }
    }
  }

  .sv_q.sv_qstn.question-not-valid {
    .survey-question-card-header {
      background: var(--asr-a-red);

      .title__link {
        color: var(--asr-a-grey-1) !important;
      }
    }
  }

  .sv_q.sv_qstn {
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);

    &.q-root-html {
      .survey-question-card-body {
        padding: 0;
        background: transparent;

        .question__root {
          background: transparent;
        }
      }

    }

    .survey-question-card-footer, .survey-question-card-auditor {
      padding-left: 0 !important;
      max-width: 90vw;
    }

    // ====================   HEADER  ====================================
    .survey-question-card-header {
      display: grid;
      grid-template-areas: "title actions"
                          "description actions";
      gap: 10px;
      padding: 15px 20px;
      background: var(--asr-a-grey-1);
      max-width: 90vw;

      &:last-child {
        background: red;
      }

      @include phones() {
        padding: $survey-card-body-padding-small;
      }

      .survey-assessor-controls__wrapper {
        position: absolute;
        top: 15px;
        right: 20px;

        .survey-assessor-note {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 3px;
          cursor: pointer;


          .survey-tooltip {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #FFFFFF;

            max-width: 100px;
            background-color: #000;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;

            visibility: hidden;
            z-index: 1;
            right: 0;
            top: calc(100% + 5px);
            transition: 0.3s;

            &:before {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #000;
              top: -10px;
              content: "";
              height: 10px;
              position: absolute;
              right: 8px;
              width: 10px;
            }
          }

          &:hover {
            background: #FFFFFF;
            transition: .4s;

            .survey-tooltip {
              visibility: visible;
            }

            .note-add-auditor {
              svg {
                path {
                  fill: var(--asr-a-blue);
                }
              }
            }
          }
        }
      }

      // HEADER TITLE
      .question__title {
        display: flex;
        //font-style: normal;
        //font-weight: 500 !important;
        //font-size: 20px;
        //line-height: 23px;
        //letter-spacing: 0.05em;
        //padding-right: 80px;
        grid-area: title;


        .sv-title-actions {
          width: 100%;
        }

        .q_num {
          min-width: fit-content;
        }

        span {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: #FFFFFF;
        }
      }

      // HEADER DESCRIPTION
      .question__description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 19px;
        color: var(--asr-a-grey-6);
        margin-bottom: 0;
        grid-area: description;
      }
    }

    .question-header-actions-container {
      grid-area: actions;
    }

    // ================= BODY  =============================
    .survey-question-card-body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $survey-card-body-padding;
      background: #F2F2F2;
      border-radius: 0;
      max-width: 90vw;

      & > div {
        width: 100%;
      }

      @include phones() {
        padding: $survey-card-body-padding-small;
      }

      table {
        height: auto;
        overflow: auto;
        @include scroll-bar;
      }

      .text-input {
        &:focus {
          border-color: white !important;
        }
      }

      .form-group {
        margin-bottom: 0;
        margin-top: 1rem;

        textarea {
          margin-left: 0;
          font-family: Roboto, sans-serif;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $client-content-color;
          margin-top: .5rem;
          padding: 10px 20px 10px;
        }
      }
    }

    //  ERRORS
    .question__error {
      padding: 0 !important;
      border: none;
      font-size: 16px;
      background: transparent;
      color: var(--asr-a-red);
      margin: 20px 0 0 0 !important;
    }
  }

  .question__root, .radio-input {
    width: 100%;

    .sv_q_select_column.sv-q-column-2 {
      min-width: 50%;
    }

    .sv_q_select_column.sv-q-column-3 {
      width: 33%;
    }

    .sv_q_select_column.sv-q-column-4 {
      width: 25%;
    }

    .sv_q_select_column.sv-q-column-5 {
      width: 20%;
    }

    @include phones() {
      display: flex;
      flex-direction: column;

      .sv_q_select_column {
        min-width: 100% !important;
      }
    }
  }

  // TEXT INPUT
  @import "questions/text-input";
  // DATEPICKER INPUT
  @import "questions/datepicker";
  // IMAGE PICKER INPUT
  @import "questions/imagepicker";
  // RADIO INPUT
  @import "questions/radio-input";
  // CHECKBOX
  @import "questions/checkbox";
  // MULTIPLE TEXT
  @import "questions/multipletext-input";
  // SORTABLE
  @import "questions/sortable";
  // SELECT
  @import "questions/select";
  // signature pad
  @import "questions/signaturepad";
  // MATRIX
  @import "questions/matrix-input";
  // RATING
  @import "questions/rating";
  // BOOLEAN
  @import "questions/boolean-switch";
  // FILE
  @import "questions/file";
}
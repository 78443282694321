@import 'src/assets/styles/base/base';
@import "src/assets/styles/abstracts/mixins_media";

.fullScreenDialogHeader {
  min-height: 70px;
  background-color: var(--asr-header-bg) !important;
}

.headerToolbar {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  min-height: inherit !important;
  height: 100%;
  z-index: 10000;
  @include media-breakpoint-down(600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

// TODO: check color
.fullScreenDialogCloseBtn {
  color: var(--asr-header-bg) !important;
  filter: invert(1);
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  margin-top: 8px;

  @include media-breakpoint-down(600px) {
    margin-right: 16px;
  }

  svg {
    &:hover {
      transform: none;

      path {
        fill: var(--asr-header-bg);
      }
    }

  }
}

.leftContent, .mainContent, .rightContent {
  display: flex;
  align-items: center;
  flex: 1;
}

.leftContent {
  justify-content: flex-start;
}

.mainContent {
  flex-wrap: wrap;
  justify-content: center;
}

.rightContent {
  justify-content: flex-end;
}

.mainContentDesktop {
  @include media-breakpoint-down(990px) {
    display: none;
  }
}

.mainContentTablets {
  justify-content: center;
  padding-bottom: 10px;

  &:empty {
    display: none;
  }

  @include media-breakpoint-up(991px) {
    display: none;
  }
}

.dialogRoot {
  z-index: 999;
}
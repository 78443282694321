@import '../../assets/styles/base/base';
@import "src/assets/styles/abstracts/mixins_media";

.onboarding-survey__wrapper, .assessment-survey__wrapper {
  // max-width: 730px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  .sv_header, .sv_body {
    padding-right: 0 !important;
  }
}

.sv_main {
  background-color: transparent !important;
}

.survey__navigation-button {
  background-color: $client-content-color !important;
  border-radius: 5px !important;
  height: 30px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px !important;
  text-transform: capitalize;
  color: #FFFFFF !important;
  margin-right: 0 !important;
}

.survey__complete-page {
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: $client-content-color;
  }
}

.sv_progress {
  height: 40px !important;
  color: $client-content-color !important;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .sv_progress_bar {
    background-color: $client-content-color !important;
    border-radius: 8px 0 0 8px;
    margin-top: 0 !important;

    .sv-hidden {
      display: block !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #FFFFFF;
      top: 7px !important;
      left: 20px;
    }
  }
}

.sv_custom_header {
  background: none !important;
}

.sv_header {
  padding-left: 0 !important;
}

.sv_body {
  border: none !important;
  background: none !important;
  padding-left: 0 !important;
}

.sv_row {
  background: #FFFFFF !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 30px !important;
  @include phones() {
    //min-width: 100% !important;
    padding: 1rem !important;
  }
}

.sv_qstn {
  padding: 0 !important;
  overflow: hidden !important;
}


.question__title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: $client-content-color !important;
  margin-bottom: 0;

  & a.title__link {
    color: #c64B6F;
    cursor: pointer;
  }

}

.sv_q_checkbox_control_label {
  span {
    a.title__link {
      color: #c64B6F;
      cursor: pointer;
    }
  }
}

.question__description {
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  color: $client-content-color;
  margin-bottom: 10px;
}

.radio-input__item {
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.multipletext-input__wrapper, .matrix-input__wrapper, .text-input__wrapper, .checkbox-input__wrapper {
  & > div:last-child {
    padding-left: 20px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
}

.matrix-input {
  th, td {
    text-align: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal !important;
    font-size: 19px;
    line-height: 23px !important;
    letter-spacing: 0.05em;
    color: $client-content-color;
    min-width: 100px !important;
  }

  td {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: $client-content-color;

    &:first-child {
      text-align: left;
    }
  }
}

.matrix-input__label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 35px;
  margin-top: 5px;

  & + div {
    margin-top: 15px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:disabled ~ .circle {
    background-color: #CCCCCC !important;
  }

  input:checked ~ .circle {
    background-color: $client-content-color;
  }

  .circle {
    height: 35px;
    width: 35px;
    background-color: #CCCCCC;
    border-radius: 50%;
    transition: 0.3s;
    z-index: 1;

    &:hover {
      background-color: $client-content-color;
    }
  }
}

.html-input_wrapper {
  margin-bottom: 20px;
}

.checkbox-input__container {
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;

    & > div {
      max-width: 100% !important;
    }
  }
}

.registrations-table, .invites-table {
  border: none;
}

.member-profile__link {
  &:hover {
    text-decoration: none;
  }
}

.card-title {
  .no-assessment-container {
    .no-assessment-wrapper {
      padding: 1em 2em 2em 2em;

      .no-assessment-title {
        border-bottom: 2px solid #dee2e6;
        font-weight: 500;
      }

      .no-assessment-description {
        border-bottom: 1px solid #dee2e6;
      }

      .no-assessment-description, .no-assessment-title {
        padding: 0.5em 0.5em;
      }
    }
  }
}

.assistant-tab {
  .assignment-wrapper {

    .assignment-table-container {
      min-height: 480px;

      .assignment-table-container-wrapper {
        display: flex;
        flex-direction: row;
        padding-top: 1em;
        justify-content: space-between;
        align-items: center;

        #assigned-users-wrapper, #all-users-wrapper {
          flex: 0 47%;

          #assigned-users-header {
            display: flex;

            h4 {
              &:last-child {
                color: $link-color;
              }
            }
          }

          #assigned-users, #all-users {
            .description-header {
              background: #000;
              color: #fff;
              position: sticky;
              top: 0;
              z-index: 1;
            }

            .assignment-item {
              padding: 10px 0px;
              background-color: #FFFFFF !important;

              &:nth-child(odd) {
                background-color: #F5F5F5 !important;
              }
            }

            .description-header, .assignment-item {
              display: flex;
              justify-content: center;
              align-items: center;

              .description-checkbox, .item-checkbox {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3em;

                input[type=checkbox] {
                  transform: scale(1.5);
                }
              }

              .description-checkbox, .description-name {
                flex: 0 10%;
              }

              .description-main {
                flex: 0 80%;
              }

              .item-checkbox {
                flex: 0 11.2%
              }

              .item-name {
                flex: 0 45%;
                word-break: break-word;
                display: flex;
                flex-direction: column;

                span {
                  &:first-child {
                    color: $link-color;
                  }
                }
              }

              .item-main {
                flex: 0 55%;

                span {
                  color: $link-color;
                }
              }

              .description-main, .item-main {
                display: flex;
                justify-content: flex-end;
                padding-right: 3em;
              }
            }
          }

          #assigned-users, #all-users {
            min-height: 350px;
            max-height: 350px;
            border: 1px solid darkgrey;
            border-radius: 4px;
            overflow: auto;
          }
        }
      }

      #arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;

        #empty {
          flex: 1 80%;
        }

        #arrows-container {
          flex: 1 20%;
          display: flex;
          flex-direction: column;

          svg {
            height: 50px;
            width: 35px;
            color: $link-color;
            cursor: pointer;

            &:hover {
              color: #138d8d;
            }
          }
        }
      }
    }
  }
}

.pad-lf-1 {
  padding-left: 1em;
}

.font-bold {
  font-weight: bold;
}

#assignment-header-selector, #search-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  .assignment-header-form {
    .campaign-users .MuiInputBase-formControl input {
      border: 2px solid red;
      border-radius: 4px;
    }

    .MuiOutlinedInput-input {
      padding: 11.5px 10px;
    }

    .MuiSelect-icon {
      top: -2px;
      color: $link-color;
      font-size: 3em;
    }
  }
}

@media (min-width: 240px) and (max-width: 767px) {
  .assistant-tab {
    .assignment-wrapper {
      .assignment-table-container {
        .assignment-table-container-wrapper {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }

  .item-main {
    flex-direction: column;
  }

  #arrows {
    #empty {
      display: none;
    }

    #arrows-container {
      flex-direction: row !important;

      svg {
        transform: rotate(-270deg);
        padding: 5px;
        height: 65px !important;
        width: 50px !important;
      }
    }
  }
}

.reports__wrapper {
  max-width: 1000px;
  margin-right: 0;
}

.reports__updating-cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
}

.reports__add-dataset__wrapper {
  display: flex;
  justify-content: flex-end;
}

.ast-page__list {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  // overflow: hidden; // Hidden box-shadow in "no assessment" component

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
}

.no-assessment-list {
  width: 82%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.no-item-placeholder {
  align-self: center;
  width: 189px;
}

.loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.UserInfoDetail {
  width: 100%;
}

.UserInfoDetailName {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserInfoAvatar{
  margin-right: 10px;
  width: 44px !important;
  height: 44px !important;
  min-width: 44px;
  min-height: 44px;

  img{
    height: 100%;
  }
}

.UserInfoCaret {
  position: absolute;
  right: 20px;
  top: 24px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 8px;

    path {
      fill: white;
    }
  }
}
.AsrLogoContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px;
}

.AsrLogoImage {
  width: 135px;
  height: 135px;

  &.Clickable {
    cursor: pointer;
  }
}

.AsrLogoText {
  span {
    font-family: Changa One, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-align: center;
    color: var(--asr-a-grey-bluish);

    &:last-child {
      color: var(--asr-a-yellow);
    }
  }
}
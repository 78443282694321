@import 'src/assets/styles/base/base';
@import "src/assets/styles/abstracts/mixins_media";

.CloseButton {
  padding-top: 2.5px;
  right: 20px;
}

.CloseButtonHidden {
  display: none;
}

.AsrModalBox {
  display: flex;
  flex-direction: column;
}

.TextCapitalize {
  text-transform: capitalize;
}

.IslandContentContainer {
  padding: 10px;
  background-color: var(--asr-a-grey-6);
}

.IslandContent {
  border-radius: 10px;
  background-color: white;
  border: 1px dashed var(--asr-a-grey-3);
}

.ModalFooter {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 20px;
  word-break: break-word;
  height: unset !important;
  min-height: 70px;
}

.DefaultFooterButton {
  background-color: var(--asr-a-grey-1) !important;
  border: none !important;

  &:hover {
    background-color: var(--asr-a-blue) !important;
  }

  span {
    color: white !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    word-break: initial;
  }
}

.TextFooterButton {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--asr-a-grey-1);
  cursor: pointer;
  align-self: center;

  &:hover {
    color: var(--asr-a-blue);
  }
}
.sd-element--with-frame, .sd-page__row {
  .sd-boolean {
    .sd-boolean__label {
      color: #333333;
    }

    .sd-boolean__thumb {
      background: var(--secondary);
      color: #FFFFFF;
    }
  }
}

.sd-boolean--disabled {
  .sd-boolean__label,
  .sd-boolean__thumb {
    opacity: unset !important;
  }
}
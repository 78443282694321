.term__title {
    font-size: 26px;
    line-height: 1;
    padding: 10px 0px 20px 0px;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none;
}

.term__text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}

.term__term {
    font-weight: 600;
    font-size: 16px;
}
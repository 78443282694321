.hashtag-grey {
  border-radius: 3px;
  padding: 3px;
  background: var(--asr-a-grey-6);

  span {
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.05em;
    color: var(--asr-a-blue);
  }
}

.hashtags-wrapper {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  height: unset;
}
@import "src/assets/styles/abstracts/mixins_media";

.saveButtonWrapper {
  position: relative;

  button:disabled {
    opacity: .7;
    cursor: initial;
  }
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5.5px 15px !important;
  background: #333333 !important;
  border-radius: 3px !important;
  min-height: var(--asr-form-button-height);

  &:hover {
    background: var(--asr-a-blue) !important;
    border-color: var(--asr-a-blue) !important;
  }
}

.label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: white !important;
  text-transform: capitalize;
}

.buttonProgress {
  color: #0d8aee !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.sa-choices-table__container {
  width: 100%;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
}

.sa-choices-table {
  width: 100%;
  font-family: var(--font-family, "Segoe UI", SegoeUI, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
  color: var(--text-color, #404040);
  background-color: var(--background-color, #f7f7f7);
}

.sa-choices-table__cell {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: var(--border-color, #e7e7e7);
  background-color: var(--background-color, #f7f7f7);
  min-height: 34px;
}

.sa-choices-table__cell-value {
  text-align: right;
  min-width: 30px;
}

.sa-choices-table__cell-header {
  font-weight: bold;
}

.sa-choices-sparkline {
  min-width: 100px;
  height: 24px;
  border: 1px solid var(--main-color, #1ab394);
}

.sa-choices-sparkline-value {
  height: 100%;
  background-color: var(--main-color, #1ab394);
}
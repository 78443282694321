@import "src/assets/styles/base/base";
@import "mixins/questions-mixins";

.sjs-sortablejs-root {
  .sjs-sortablejs-source, .sjs-sortablejs-result {
    .sjs-sortablejs-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px !important;

      width: 100%;
      min-height: 60px;
      margin-bottom: 10px !important;
      margin-top: 0 !important;
      border-radius: 0;

      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      word-break: break-word;
      letter-spacing: 0.05em;

      transition: $transition-time;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .handle {
        margin-right: 15px;
      }
    }
  }

  .sjs-sortablejs-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    min-height: 100px;

    background: #FFFFFF;

    border: 1px solid $client-content-color !important;
    box-sizing: border-box !important;
    border-radius: 5px;

    .sjs-sortablejs-item {
      background-color: var(--asr-a-grey) !important;
      color: white !important;

      .handle {
        svg {
          circle, ellipse {
            fill: white;
          }
        }
      }

      &:hover {
        background-color: var(--asr-a-blue) !important;
        color: white !important;

        a {
          color: white !important;
        }

        .handle {
          svg {
            circle, ellipse {
              fill: white;
            }
          }
        }
      }
    }

    span {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-4);
      width: 100%;
      height: 100%;
    }
  }


  .sjs-sortablejs-source {
    margin-top: 30px !important;

    .sjs-sortablejs-item {
      background-color: #FFFFFF !important;
      color: $client-content-color !important;

      .handle {
        svg {
          circle, ellipse {
            fill: var(--asr-a-blue);
          }
        }
      }

      &:hover {
        background-color: var(--asr-a-blue) !important;
        color: white !important;

        a {
          color: white !important;
        }

        .handle {
          svg {
            circle, ellipse {
              fill: white;
            }
          }
        }
      }
    }
  }

}

.sjs-sortablejs-root + .form-group {
  margin-top: 20px;
  color: $client-content-color;
  font-size: 20px;
  word-break: break-word;
  .question__comment {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: $client-content-color;

    &:focus {
      border-color: transparent !important;
    }
  }
}

//  READ ONLY
.survey-question-card-body.read-only {
  .sjs-sortablejs-item{
    @include read-only-sortable-hover;
  }
}
.asr-page-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  background: #FFFFFF;
  margin-bottom: 10px;
}

.asr-page-content-card-view {
  padding: 0;
}

.asr-page-content-wrapper {
  box-shadow: 0 2px 5px 0 #00000020;
}

.asr-page-content-card-view {
  padding: 0;
}

.asr-page-pagination {
  &--container {
    display: flex;
    justify-content: center;
  }
}

.asr-page-add-button {
  margin-left: 10px;
}
@import "src/assets/styles/abstracts/mixins_media";

.asrUploadImage {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include phones() {
    align-items: center;
    flex-direction: column;
  }
}

.imageBlock {
  position: relative;
  display: flex;
  justify-content: center;
  height: 120px;
}

.actionBlock {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-between;
}

.avatar, .pressToLoadImage {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 6px !important;
  margin-right: 20px;
  width: 120px !important;
  height: 120px !important;
  text-align: center;

}

.avatarInteractive {
  cursor: pointer;

  label {
    cursor: pointer
  }
}

.label {
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em !important;
  color: #333333 !important;

  @include phones() {
    margin: 1rem 0;
  }
}

.url {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 70px;
}

.urlField {
  flex: 2;
  margin-right: 1rem;

  .urlInput {
    height: 28px;
    width: 184px;
    @include phones() {
      width: 165px;
    }
  }
}

.accentColor {
  font-weight: bold;
}

.pressToLoadContainer {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  cursor: pointer;
}

.pressToLoadImage {
  margin-right: 0;
}


.pressToLoad {
  position: absolute;
  bottom: 0;
  padding: 2px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: white;

  //backdrop-filter: blur(5px);
  //background-color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, .7);
  backdrop-filter: grayscale(1) contrast(3) blur(4px);

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.sa-text-table__container {
  width: 100%;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
}

.sa-text-table {
  width: 100%;
  font-family: var(--font-family, "Segoe UI", SegoeUI, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
  color: var(--text-color, #404040);
  border-width: 1px;
  border-collapse: collapse;
  background-color: var(--background-color, #f7f7f7);
}

.sa-text-table__cell {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: var(--border-color, #e7e7e7);
  background-color: var(--background-color, #f7f7f7);
  min-height: 34px;
}
@import "src/assets/styles/abstracts/mixins_media";
@import "../../base/base";

// TEXT INPUT

.asr-survey-text-input--wrapper {
  outline: none !important;

  .text-input:disabled {
    &:hover {
      border-bottom-color: #CCCCCC !important;
    }
  }

  .text-input {
    border: none !important;
    border-bottom: 1px solid #CCCCCC !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 36px !important;
    line-height: 42px !important;
    letter-spacing: 0.05em !important;
    color: $client-content-color !important;
    height: auto !important;
    padding-left: 0 !important;
    transition: 0.3s;

    &:hover {
      border-bottom-color: $client-content-color !important;
    }

    &[type="color"] {
      height: 30px !important;
      min-height: 10px !important;
    }
  }
}

//  ========================== RADIO GROUP  ======================
.asr-survey-radio-input {
  overflow: hidden;

  &--item {
    align-items: center;
    margin-bottom: 14px;
    min-height: 35px;

    @include tablets() {
      height: fit-content;
    }

    &.checked {
      .asr-survey-radio-input--label {
        &:after {
          background: var(--asr-a-grey-1);
        }
      }
    }
  }

  &--label {
    display: flex !important;
    align-items: center;
    position: relative;
    height: 100%;
    min-height: 35px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 0 !important;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: $client-content-color;

    user-select: none;

    @include phones() {
      flex-direction: column;
      align-items: start;
    }

    & + div {
      margin-top: 15px;
    }

    input[type="radio"], input[type="checkbox"] {
      background: #000;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    //  CIRCLE STYLES
    .sv-string-viewer {
      padding-left: 60px;
    }

    .item_editable {
      margin-left: 60px;

      .sv-string-viewer {
        padding-left: 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 35px;
      width: 35px;
      border-radius: 20px;
      background: var(--asr-a-grey-4);
    }

    &:hover {
      &:after {
        background: var(--asr-a-grey-1);
      }
    }

    //
    input:disabled ~ .circle:hover {
      background-color: #CCCCCC;
    }

    input:checked ~ .circle {
      background-color: $client-content-color;
    }

    .circle {
      position: relative;
      margin-right: 25px;
      height: 35px;
      width: 35px;
      min-width: 35px;
      background-color: #CCCCCC;
      border-radius: 50%;
      transition: 0.3s;

      &:hover {
        background-color: $client-content-color;
      }
    }
  }
}

// ==================================  CHECK BOX   ============================
.asr-survey-checkbox-input--container {
  overflow: hidden;
  @include media-breakpoint-down(575px) {
    display: flex;
    flex-direction: column;

    & > div {
      max-width: 100% !important;
    }
  }

  .asr-survey-checkbox-input--label {
    display: flex !important;
    align-items: center;
    position: relative;
    padding-left: 60px;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 1px;
    color: $client-content-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 40px;
    margin-bottom: 15px;

    @media screen and (max-width: 575px) {
      padding-left: 50px;
      font-size: 16px;
      letter-spacing: normal;
    }

    input[type="radio"], input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-input__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 40px;
      background-color: #CCCCCC;
      transition: 0.3s;

      &:hover {
        background-color: $client-content-color;
      }
    }

    input:disabled ~ .checkbox-input__checkmark {
      background-color: #CCCCCC;
    }

    input:checked ~ .checkbox-input__checkmark {
      background-color: $client-content-color;
    }
  }
}

// RATING
.asr-survey-rating-input {
  flex-wrap: wrap;
  z-index: 1;

  rating-item-editor {
    .svda-content {
      z-index: 10;
    }

  }
}

//.asr-survey-rating-input--item {
//  width: 60px;
//  height: 60px !important;
//  background: #CCCCCC;
//  border: 1px solid #C0BFBF !important;
//  margin-left: 5px !important;
//  font-family: Roboto;
//  font-style: normal;
//  font-weight: normal;
//  font-size: 24px;
//  line-height: 60px;
//  text-align: center;
//  letter-spacing: 0.05em;
//  color: #FFFFFF;
//  transition: 0.3s;
//
//  @media screen and (max-width: 499px) {
//    width: 50px;
//    height: 50px;
//    line-height: 50px;
//  }
//
//  &:hover {
//    background-color: $client-content-color !important;
//  }
//}

// =================================== EXPRESSION CONDITION =============================
.svd-propertyeditor-condition {
  .svd-expression-content {
    .svd_wrap_elements {
      survey-widget {
        .sv_p_root.svd-property-editor-survey-page {
          .sv_row {
            display: flex;
            justify-content: space-between;
            padding: 30px 10px !important;

            .sv_select_wrapper {
              padding-left: 0;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

// ================================== IMPORT QUESTIONS LOGIC ===================================
svd-accordion {
  .row.svd-popup-editor-tab-Import.Questions {
    .svd-control-label {
      display: none;
    }
  }
}

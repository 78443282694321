.app-content__client-theme {
  background-image: var(--asr-app-background-image);
  background-color: var(--asr-app-bg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.app-wrapper {
  position: relative;
}
.cancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px !important;
  border-radius: 3px !important;
  height: var(--asr-form-button-height);


  &:hover {
    background: transparent !important;

    .label {
      color: var(--asr-a-blue) !important;
    }
  }
}

.label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333 !important;
  text-transform: capitalize;
}

.AsrCancelButtonMr1 {
  margin-right: 4px;
}

.AsrCancelButtonMr2 {
  margin-right: 8px;
}
@import "src/assets/styles/base/base";

.sv_q.sv_qstn.boolean-input__wrapper {
  .boolean-input {
    .sv-boolean__label {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-1);
    }

    .sv-boolean__switch {
      transition: $transition-time;
      background-color: var(--asr-a-red);

      &:hover {
        background-color: var(--asr-a-blue);
      }
    }
  }

  // READ ONLY
  .survey-question-card-body.read-only {
    .sv-boolean__switch{
      &:hover{
        cursor: initial;
        background-color: var(--asr-a-red);
      }
    }
  }
}

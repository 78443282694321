.AsrMuiInputWrapper {
  position: relative;;
  width: 100%;
}

.ErrorText {
  position: absolute;
  margin-top: 0 !important;
}

.AsrMuiInputRoot {
  color: var(--asr-a-grey-bluish) !important;
}

.AsrMuiInputFocused {
  color: var(--asr-a-grey-bluish) !important;
}

.AsrMuiInputUnderline {
  color: var(--asr-a-grey-bluish) !important;
  border-bottom: 1px solid #7D7C7D;

  &:after, &:before {
    border-bottom: initial !important;
  }
}

.AsrMuiInputLabel {
  color: var(--asr-a-grey-bluish) !important;
}

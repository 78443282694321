@import "src/assets/styles/abstracts/mixins_media";
@import "../base/base";

.asr-table-box {
  position: relative;
}

.asr-table-header-button {
  margin-left: 10px;
}

body {
  table {
    table-layout: fixed;
    height: 1px;

    .asr-table-row {
      vertical-align: top;
      border-bottom: none;
    }

    .asr-table-td {
      padding: 10px;
      color: var(--asr-a-grey-1);
      word-break: break-word;
      white-space: break-spaces;
      height: inherit;
      border-bottom: 1px solid var(--asr-a-grey-5);

      & > div {
        height: 100%;
      }

      &__code, &__last-edited {
        font-style: normal;
        font-weight: 300;
        font-size: 9px;
        line-height: 11px;
      }
    }

    .asr-table-actions-container {
      padding: 0 5px 0 20px !important;
    }

    .asr-table-actions {
      display: flex;
      justify-content: center;

      .asr-table-actions--button {
        height: 20px;
        width: 20px;
      }

      .asr-table-submenu {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: .5rem 10px;
        background-color: var(--asr-table-colorized-td-background);
        //min-width: 40px;
      }

      .menu {
        padding-top: .5rem;
      }
    }

    .asr-table-actions--button {
      background: #FFFFFF;
      border-radius: 3px;
      height: 20px;
      width: 20px;
      cursor: pointer;

      svg {
        height: 12px;
        width: 12px;

        path {
          fill: var(--asr-a-blue)
        }
      }

      &:hover {
        background: var(--asr-icon-hover-bg-acent);

        svg {
          path {
            fill: white;
          }
        }
      }

      &__locked {
        svg {
          path {
            fill: var(--asr-a-red)
          }
        }

        &:hover {
          background: var(--asr-a-red);
        }
      }

      &__locked_disabled {
        svg {
          path {
            fill: var(--asr-a-red)
          }
        }

        &:hover {
          background: white;

          svg {
            path {
              fill: var(--asr-a-red)
            }
          }
        }
      }
    }
  }
}


.table-responsive-material {
  border: none;
}

tr {
  border-bottom: 0.5px solid #7D7C7D;
  height: 100%;
}

.asr-table {
  &__data-row {
    background-color: #ffffff;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  &__vertical-menu {
    padding: 10px 0 0 0;

    .MuiIconButton-root:hover {
      background-color: transparent;
    }
  }
}


.asr-table__search {
  width: 190px;
  align-items: center;

  @include tablets() {
    width: 100%;
    margin-right: 0;
  }

  &--default {
    width: fit-content;
    @include tablets() {
      width: 100%;
      margin-right: 5px;
    }
  }
}

.asr-table-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}


.asr-table-th {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--asr-a-grey-1);
  white-space: nowrap;
  padding: 5px 10px !important;
  max-width: 160px;

  &__id {
    width: 120px;
  }

  &__actions {
    width: 65px;
  }

  span {
    margin-right: 5px;
  }

  i {
    cursor: pointer;
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.asr-header-dot-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4px;
  height: 28px;
  padding: 0;
  border: none;
  background: var(--asr-a-grey-2) !important;
  margin-left: 10px;

  &:hover {
    background: var(--asr-a-blue) !important;
  }

  svg {
    path {
      fill: white !important;
    }
  }

  &__paper {
    border-radius: 0 !important;
    padding: 0 !important;
  }
}

.asr-add-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 10px;

  .table__add-button {
    width: fit-content;
  }
}

// TABLE HEADER
.table__header {
  display: flex;
  justify-content: space-between;
  background: var(--asr-a-grey-5);
  padding: 5px 5px 5px 13px;
  flex-wrap: wrap;

  &--card-view {
    padding: 5px 10px 5px 18px;
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls {
    display: flex;
    flex-flow: wrap;
    gap: 5px;
    align-items: center;
    background: var(--asr-a-grey-5);
    padding: 4px 5px;

    @include tablets() {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 15px;

      & > div {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__left {
      padding: 4px 10px 4px 0;

      .select-items {
        @include tablets() {
          margin-right: 0;
          width: 100%;
        }

        &--last {
          margin-right: 0;
        }
      }
    }

    &__right {
      flex-direction: row;
      align-items: center;
      padding-left: 0;

      @include tablets() {
        padding-right: 10px;
      }

      & > div {
        margin-bottom: 0;
      }
    }
  }

  .controls-count {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .controls {
      width: fit-content;
    }

    @include tablets() {
      .controls {
        width: 100%;
      }
    }

    @include tablets() {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .items-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: #4F4F4F;
    text-transform: capitalize;
    margin: 0 20px;

    @include tablets() {
      margin: 0 5px;
    }
  }
}

.asr-no-items-placeholder-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

// PAGINATION
.asr-table-pagination-container{
  display: flex;
  justify-content: center;
}

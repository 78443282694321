// POPUP
.sv-popup.sv-single-select-list.sv-popup--dropdown {
  .sv-popup__shadow {

    .sv-list__item {
      &.sv-list__item--selected {
        .sv-list__item-body{
          background: var(--asr-a-grey-2);
        }
      }
    }
  }
}
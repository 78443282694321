.sa-panel__header {
  margin: 0 1em;
}

.sa-grid {
  position: relative;
}

.sa-grid__grid-sizer {
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 1400px) {
  .sa-grid__grid-sizer {
    width: 50%;
  }
}

.sa-question {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1em;
}

.sa-question-layouted {
  position: absolute;
}
@media screen and (min-width: 1400px) {
  .sa-question-layouted {
    width: calc(50% - 1px);
  }
}

.sa-question__content {
  background-color: var(--background-color, #f7f7f7);
  padding: 20px;
}

.sa-question__title {
  margin-left: 30px;
  font-family: var(--font-family-secondary, Raleway, Arial, sans-serif);
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color, #404040);
  margin: 0;
  margin-bottom: 1em;
}

.sa-question__title--draggable {
  cursor: move;
}
.sa-question__title--draggable:after {
  content: " ";
  display: block;
  float: right;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg%3E%3Cpolygon style='fill: %231ab394' points='13,5 12,6 13,7 9,7 9,3 10,4 11,3 8,0 5,3 6,4 7,3 7,7 3,7 4,6 3,5 0,8 3,11 4,10 3,9 7,9 7,13 6,12 5,13 8,16 11,13 10,12 9,13 9,9 13,9 12,10 13,11 16,8 '/%3E%3C/g%3E%3C/svg%3E ");
}

.sa-toolbar {
  font-family: var(--font-family, "Segoe UI", SegoeUI, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
  color: var(--text-color, #404040);
  line-height: 34px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.sa-toolbar.sa-toolbar > * {
  display: inline-flex;
  line-height: 1em;
}

.sa-toolbar__button {
  font-family: var(--font-family, "Segoe UI", SegoeUI, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
  padding: 8px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  margin: 0 10px;
}
.sa-toolbar__button:hover {
  border-color: var(--border-color, #e7e7e7);
  background-blend-mode: darken;
}
.sa-toolbar__button:first-child {
  margin-left: 0;
}
.sa-toolbar__button:last-child {
  margin-right: 0;
}

.sa-toolbar__svg-button {
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
}
.sa-toolbar__svg-button use {
  fill: var(--svg-color, rgba(159, 159, 159, 0.4));
}
.sa-toolbar__svg-button:hover use {
  fill: var(--svg-hover-color, #9f9f9f);
}
.sa-toolbar__svg-button svg {
  width: 15px;
  height: 15px;
}

.sa-toolbar__button--right {
  float: right;
}

.sa-question__select-wrapper {
  position: relative;
  display: inline-block;
  min-width: 100px;
  max-width: 300px;
}
.sa-question__select-wrapper .sa-question__select {
  width: 100%;
  display: block;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 2px;
  background: var(--text-color-inverse, #fff);
  border-color: var(--border-color, #e7e7e7);
  height: 34px;
  padding: 5px 23px 5px 15px;
  line-height: initial;
}
.sa-question__select-wrapper .sa-question__select::-ms-expand {
  display: none;
}
.sa-question__select-wrapper .sa-question__select:focus {
  outline: 0;
  box-shadow: none;
}
.sa-question__select-wrapper .sa-question__select:disabled {
  color: var(--disabled-color, #b0b0b0);
}
.sa-question__select-wrapper:before {
  margin-top: -4px;
  top: 50%;
  right: 10px;
  z-index: 1;
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-right: 2px solid var(--text-color, #404040);
  border-bottom: 2px solid var(--text-color, #404040);
  transform: rotate(45deg);
  box-sizing: content-box;
  pointer-events: none;
}

.sa-question__filter {
  display: inline-block;
  padding: 0 1em;
}

.sa-question__filter-text {
  vertical-align: middle;
  margin-top: 1px;
  line-height: 32px;
}

.sa-commercial {
  font-size: 12px;
  padding: 1em 0 0.5em 2em;
}
.sa-commercial svg {
  width: 20px;
  height: 20px;
  fill: var(--main-color, #1ab394);
}

.sa-commercial__text {
  font-weight: bold;
  text-decoration: none;
  color: var(--main-color, #1ab394);
}

.sa-commercial__product {
  padding-left: 0.5em;
}

/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
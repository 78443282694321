@import "src/assets/styles/abstracts/mixins_media";


.AsrAutocompleteMultipleRenderOptionContainer {
  position: relative;
}

.AsrAutocompleteMultipleRenderOption {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  padding: 7px 10px;

  .MuiIconButton-root.MuiIconButton-colorSecondary:hover {
    background-color: lime !important;
  }
}

.checkbox {
  min-height: 26px;
  padding: 0 12px 0 2px !important;

  &:hover {
    background-color: transparent !important;
  }

  &:hover .icon {
    background: var(--asr-a-blue);
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    border: none;
  }

}

.icon {
  width: 12px;
  height: 12px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 3px;
}

.checkedIcon {
  background: #EB5757;
  border: none;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.code {
  width: 55px;
  height: 100%;
  min-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #333333;
  padding-right: 5px;
  margin-right: 10px;

  border-right: 1px solid #E0E0E0;
}

.mainContent {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.mainContentName {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #333333;
  word-break: break-word;
  padding-right: 5px;
}

.mainContentNameStatus {
  display: flex;
  align-items: center;
  height: 100%;
}

.lockIcon {
  width: 16px;
  height: 16px;
  margin-top: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: white;

    svg {
      path {
        fill: var(--asr-a-red);
      }
    }
  }
}

.globalIcon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  @include phones() {
    margin-left: 0;
  }
}

.loader {
  width: 100%;
  height: 100%;
  background: var(--asr-a-grey-6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .7;
  z-index: 1300;
}
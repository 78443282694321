@import "src/assets/styles/abstracts/mixins_media";

.DefaultModalBox {
  width: calc(min(calc(100vw - 2.4rem), 440px));

  .ContentContainer {
    padding: 30px;

    @include media-breakpoint-down(400px) {
      padding: 20px;
    }
  }

  .ModalFooter {
    justify-content: flex-end;
    gap: 30px;

    @include phones() {
      flex-flow: column;
      gap: 20px;
    }

    .SubmitButtonWrapper {
      @include phones() {
        margin: auto;
      }

      .SubmitButton {
        background-color: var(--asr-a-red) !important;

        &:hover {
          background-color: var(--asr-a-blue) !important;
        }
      }
    }

    .ForgotPasswordText {
      @include phones() {
        width: 100%;
      }
    }
  }
}

.LightModalBox {
  width: calc(min(calc(100vw - 2.4rem), 400px));

  .ModalHeader {
    padding-bottom: 20px;
  }

  .ModalFooter {
    justify-content: flex-start;
    gap: 10px;

    @include phones() {
      flex-flow: column;
      gap: 20px;
    }

    .SubmitButtonWrapper {
      order: 1;

      @include phones() {
        order: 2;
        margin: auto;
      }
    }

    .ForgotPasswordText {
      order: 2;

      @include phones() {
        order: 1;
        width: 100%;
      }
    }
  }
}

.ModalContentForm {
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 100%;
}

.PasswordFieldContainer {
  position: relative;

  .PasswordInput {
    padding: 0 32px 0 10px;
    border-radius: 5px;
    border: 1px solid var(--asr-a-grey-3);
    height: 36px;
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: var(--asr-a-grey-bluish);
  }

  .EyeIconContainer {
    top: 6px;
    right: 4px;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    background-color: var(--asr-a-grey-3);

    &.CloseEye {
      background-color: var(--asr-a-red);
    }

    path {
      stroke: white;
    }
  }
}

.EyeIconTooltip {
  background-color: var(--asr-a-blue) !important;

  span::before {
    color: var(--asr-a-blue) !important;
  }

  &.CloseEye {
    background-color: var(--asr-a-red) !important;

    span::before {
      color: var(--asr-a-red) !important;
    }
  }
}

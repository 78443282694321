.ImageTypeSwitcher {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;

  width: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ImageTypeSwitcherLabel {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 1px;
  color: var(--asr-a-grey-1);
  text-transform: lowercase;
  cursor: pointer;
  width: calc((100% - 36px) / 2);
  word-break: break-word;
}

.ImageTypeSwitcherLabelInactive {
  color: var(--asr-a-grey-3);
}

.ImageTypeSwitcherLabelOff {
  text-align: right;
}
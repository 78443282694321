@import "../../../../../../../assets/styles/base/base";
@import "../../../../../../../assets/styles/abstracts/mixins_media";
@import "../../../../../../../assets/styles/components/survey/survey-analytics/survey-analytics-variables";

.root {
  border: none !important;
  border-radius: 0 !important;
  margin-bottom: 2px;
  overflow: inherit !important;

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px !important;
  background: var(--asr-a-grey-6);

  @include phones() {
    padding: 0 10px 10px 10px !important;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px !important;
  min-height: 43px;
  background: var(--asr-a-grey-6);
  border-radius: 0;

  .MuiCardHeader-avatar {
    background: red !important;
    width: 100px;
  }

  .headerAvatar {
    margin-right: 5px;
  }

  .actionIconContainer {
    display: flex;
    height: 23px;
    align-items: center;
  }

  &.close:hover {
    background: var(--asr-a-blue);
    color: white;

    .actionIconContainer {
      i {
        transform: rotate(-90deg);
        transition: $transition-time;
      }
    }

    .headerTitle, .headerSubTitle {
      color: white !important;
    }

    .headerAvatar {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }

  &.open {
    .actionIconContainer {
      i:hover {
        transform: rotate(180deg);
        color: var(--asr-a-blue);
        transition: $transition-time;
      }
    }
  }

  &.hiddenQuestionType {
    &:hover {
      background: var(--asr-a-grey-6);

      .headerTitle {
        color: $client-content-color !important;
      }

      .headerSubTitle {
        color: var(--asr-a-grey-3) !important;
        opacity: 1;
      }
    }
  }
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.actionIconContainer {
  align-self: center !important;
  margin-top: 0 !important;
  margin-right: 0 !important;

  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;

  i {
    cursor: pointer;
    font-size: 27px;
  }
}

.headerTitle {
  font-size: 20px !important;
  line-height: 23px !important;
  letter-spacing: 0.05em !important;
  color: $client-content-color;

  @include phones() {
    font-size: 16px !important;
  }
}

.headerSubTitle {
  font-size: 14px !important;
  line-height: 16px !important;
  color: var(--asr-a-grey-3) !important;
  margin-left: 20px !important;
  padding-top: 3px;
  @include phones() {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
}

.analyticsContainer {
  position: relative;
  min-height: $container-min-height;
}

.noAnalyticsPlaceholderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.noAnalyticsPlaceholder {
  padding: 10px;
  border: 1px dashed var(--asr-a-grey-1);

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: var(--asr-a-grey-1);
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 17px;
  border-radius: 8px;
  background: white;
}
.asr-select-error {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  width: max-content;
  word-break: break-word;
  color: var(--asr-error);
  margin-top: 6px;

  &__adaptive {
    width: 100%;
    top: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 12px;

    &:hover {
      overflow: visible;
      background: white;
      width: auto;
      z-index: 10;
      white-space: initial;
      height: fit-content;
    }
  }
}
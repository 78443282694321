:root {
  --asr-error: #EB5757;
  --asr-a-blue: #24B2B2;
  --asr-a-blue-2: #2D9CDB;
  --asr-a-blue-3: #56CCF2;
  --asr-a-red: #EB5757;
  --asr-a-yellow: #E1C123;
  --asr-a-grey: #4F4F4F;
  --asr-a-grey-1: #333333;
  --asr-a-grey-2: #4F4F4F;
  --asr-a-grey-3: #828282;
  --asr-a-grey-4: #BDBDBD;
  --asr-a-grey-5: #E0E0E0;
  --asr-a-grey-6: #F2F2F2;
  --asr-a-grey-bluish: #243B47;
  --asr-a-grey-bluish-muted: #445863;
  --asr-a-grey-bluish-light: #3B6175;
  --asr-a-green-1: #C0CF62;
  --asr-a-green-2: #6FCF97;
  --asr-a-purple-light: #BB6BD9;

  // APP MAIN
  --asr-app-background-image: "";
  --asr-app-background-logo-image: "";
  --asr-app-bg: #ffffff;
  --asr-app-font: Roboto,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Oxygen-Sans,
  Ubuntu,
  Cantarell,
  "Helvetica Neue",
  sans-serif;

  // ERROR LABEL
  --asr-error-label-fw: 400;
  --asr-error-label-fz: .75rem;
  --asr-error-label-margin: 3px 14px 0 14px;
  // LINKS
  --asr-link-color: #24B2B2;
  // TABLE
  --asr-table-odd-bg: white; // default #f4f4f4
  --asr-table-odd-hover-bg: #FFFFFF; // default #FFFFFF
  --asr-table-even-bg: #FFFFFF; // default #FFFFFF
  --asr-table-even-hover-bg: #f4f4f4; // default #f4f4f4
  // TABLE ACTIONS
  --asr-table-actions-icon-size: 20px;
  --asr-table-actions-icon-background: white; // #7D7C7D

  --asr-table-colorized-td-background: #f4f4f4;

  // TABS
  --asr-active-tab-border-bg-color: #24B2B2;

  // HEADER
  --asr-header-bg: #242323;
  --asr-header-logo-color-st0: #231F20;
  --asr-header-logo-color-st1: #D2F1DB;
  --asr-header-logo-color-st2: #243B47;
  --asr-header-logo-color-st1-ver2: #E1C123;
  --asr-header-text-color: #D2F1DB;
  --asr-header-language-list-item-color: #C0BFBF;
  --asr-header-language-list-item-hover: #C0BFBF;

  // NAVIGATION
  --asr-side-nav-bg: #252525;
  --asr-nav-user-profile-bg: #000000;
  --asr-nav-link-color: #24B2B2;
  --asr-nav-link-hover-bg: #24B2B2;
  --asr-nav-link-hover-color: #000000;
  --asr-nav-link-active-bg: #000000;
  --asr-nav-link-active-home-bg: #000000;
  --asr-nav-link-active-color: #BFBFBF;
  --asr-nav-link-active-hover-color: #BFBFBF;
  --asr-nav-link-active-hover-bg: #000000;

  // ORGANIZATION
  --asr-campaign-dashboard-gradient-bg: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 5%, #000000 54.69%, rgba(51, 51, 51, 0.1) 95%);
  --asr-organization-link-color: #24B3B3;
  --asr-organization-header-background-image: "";

  // BUTTONS
  --asr-to-crm-btn-bg: #757171;
  --asr-to-accept-btn-bg: #3f51b5;
  --asr-btn-bg-main: #4F4F4F;
  --asr-btn-bg-hover: #24B2B2;
  // ICONS
  --asr-small-icon-size: 24px;
  --asr-large-icon-size: 32px;
  --asr-icon-hover-bg-acent: #24B2B2;
  --asr-icon-hover-bg-danger: #EB5757;
  // FORMS
  --asr-input-border-color: #242323;
  --asr-input-border--hover-color: #24B2B2;
  --asr-form-button-height: 34px;
  // DYNAMIC COLUMNS
  --asr-dynamic-columns-bg-static: #79718b;
  --asr-dynamic-columns-bg-dynamic: #0c7cd5;

  // TOOLTIP
  --term-tooltip-left: 40%;

  // PAGE
  --asr-app-wrapper-max-width: 100%;
}
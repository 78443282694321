@import "src/assets/styles/base/base";
@import "./mixins/questions-mixins";


.sv_q_checkbox {
  padding-right: 0;

  &.checked {
    .checkbox-input__label {
      background: var(--asr-a-grey);
      color: white;

      &:hover {
        background: var(--asr-a-grey);

        .title__link {
          color: var(--asr-a-blue) !important;
        }

        .checkbox-input__checkmark {
          border: 1px solid #BDBDBD;
        }
      }
    }
  }

  &:hover {
    .checkbox-input__label {
      background: var(--asr-a-blue);
      color: white;
      transition: $transition-time;

      a {
        color: #FFFFFF !important;
      }

      .title__link {
        color: white !important;
      }

      .checkbox-input__checkmark {
        border-color: white;

        &:after {
          @include checkbox-checked-mark(var(--asr-a-blue));
        }
      }
    }
  }

  .checkbox-input__label {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-left: 75px;
    align-items: center;
    min-height: 60px;
    padding-right: 10px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;


    cursor: pointer;
    color: $client-content-color;
    user-select: none;
    margin-bottom: 5px;

    background: #ffffff;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-input__checkmark {
      position: absolute;
      left: 10px;
      top: 10px;
      height: 40px;
      width: 40px;
      background-color: #F2F2F2;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      transition: $transition-time;

      &:after {
        @include checkbox-checked-mark(transparent, 0);
      }
    }

    input:checked ~ .checkbox-input__checkmark {
      &:after {
        @include checkbox-checked-mark(var(--asr-a-red));
      }
    }
  }
}

.survey-question-card-body.read-only {
  .sv_q_checkbox {
    @include read-only-choice-hover;

    &.checked {
      @include read-only-choice-hover(white, var(--asr-a-grey), var(--asr-a-red));
    }
  }
}
@import "../../../base/base";
@import "src/assets/styles/abstracts/mixins_media";

.asr-assessment-survey {
  width: 100%;

  .sv_header, .sv_body {
    padding-right: 0 !important;
  }

  // HEADER
  .sv_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px !important;
    max-width: 770px;

    word-break: break-word;

    margin: 30px 0;
    background: rgba(128, 211, 190, 0.1);
    border: 1px solid $client-content-color;
    box-sizing: border-box;
    border-radius: 8px;

    .sv_header__text {
      font-family: Roboto, sans-serif;
      font-style: normal;
      letter-spacing: 0.05em;

      .survey__title {
        font-weight: 500 !important;
        font-size: 24px !important;
        line-height: 28px;
        margin-bottom: 15px;
      }

      .survey__description {
        font-weight: normal !important;
        font-size: 20px;
        line-height: 23px;

      }
    }
  }

  .sv_main {
    display: flex;
    justify-content: center;
    background-color: transparent !important;

    .sv_p_root {
      width: 730px;

      @include tablets() {
        width: 100%;
      }


      // CARD
      @import "survey-card";
    }
  }
}
@import "src/assets/styles/abstracts/mixins_media";

.DefaultModalBox {
  width: calc(min(calc(100vw - 2.4rem), 600px));

  .ModalContent {
    padding: 30px;

    @include phones() {
      padding: 20px;
    }
  }

  .AgreementBlock {
    padding: unset;
  }
}

.LightModalMox {
  width: calc(min(calc(100vw - 2.4rem), 600px));

  .ModalTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.05em;
  }
}

.FormContainer {
  color: var(--asr-a-grey-bluish);
  display: flex;
  flex-flow: column;
  gap: 30px;
}

.NamesBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @include phones() {
    grid-template-columns: 1fr;
  }
}

.AgreementBlock {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @include phones() {
    flex-flow: column;
  }

  .CheckBoxBlock {
    display: flex;
    flex-flow: nowrap;
    gap: 10px;
    color: var(--asr-a-grey-bluish);
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}

.ErrorText {
  margin-top: 0 !important;
}

.MessageRoot {
  label {
    width: 133%;

    .MessageLabel {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;

      .MessageLabelCounter {
        color: var(--asr-a-grey-3);
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.05em;
        font-weight: 400;
      }
    }
  }
}

.SendItButton {
  display: flex;
  flex-flow: nowrap;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: var(--asr-a-yellow) !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--asr-a-grey-bluish) !important;
  cursor: pointer;

  .Dark {
    color: var(--asr-a-grey-bluish) !important;
    text-transform: uppercase;
  }

  .Light {
    color: white !important;
    text-transform: lowercase;
  }

  &:hover {
    background: var(--asr-a-blue) !important;
  }
}
.asr-dots-menu--icon {
  display: flex;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  &__common_color {
    svg {
      path {
        fill: rgb(79, 79, 79);
      }
    }
  }
}

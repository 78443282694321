@import '/src/assets/styles/base/base';
@import "/src/assets/styles/abstracts/mixins_media";

@import "placeholders";
@import "sidebar";
@import "questions/index";
@import "navigation";
@import "modals/index";
@import "viewer";
@import "creator";
@import "progressbar";


// COLOR THEME
body {
  --primary: var(--asr-a-blue);
  --secondary: var(--asr-a-red);
  --font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

// HIDDEN ON LOGO IMAGE INPUT
.svc-logo-image {
  display: none !important;
}

// FIX ISSUE OF INCORRECT LINE HEIGHT FOR SELECTOR (too large than initial)
.svc-page__question-type-selector {
  line-height: initial;
}

// FIX ISSUE OF incorrect height
.svc-dragged-element-shortcut {
  height: calc(5 * var(--base-unit, 8px));
  z-index: 1301;
}


// FIX page navigator size issue
.svc-page-navigator__navigator-icon {
  width: 100% !important;
  height: 100% !important;
}

// FIX icon size on drag element
.spg-drag-element__svg {
  width: calc(5 * var(--base-unit, 8px));
  height: calc(5 * var(--base-unit, 8px));
}

[data-sv-drop-target-page="imported_questions"] {
  pointer-events: none;
  background: var(--asr-a-grey-5);
  //display: none;

  .svc-page__add-new-question {
    display: none;
  }
}

.creator_mobile_save_btn:disabled {
  fill: var(--asr-a-grey-3) !important;
}

.svc-creator--mobile {
  height: calc(100% - 70px);
}

// SURVEY TITLE
.sd-container-modern__title {
  align-items: flex-start;
  background: rgba(128, 211, 190, .1) !important;
  border: 1px solid #333 !important;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  max-width: 770px;
  padding: 20px !important;
  word-break: break-word;
  box-shadow: none !important;

  .sd-header__text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    letter-spacing: 0.05em;

    .survey__title {
      font-weight: 500 !important;
      font-size: 24px !important;
      line-height: 28px;
      margin-bottom: 15px;
      color: #333 !important;
    }

    .survey__description {
      font-weight: normal !important;
      font-size: 20px;
      line-height: 23px;

    }
  }
}

.survey-page-title,
.sd-page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: $client-content-color !important;
  margin-bottom: 20px;
  margin-top: 20px;
}

.survey-page-description,
.sd-page__description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: $client-content-color !important;
  margin-bottom: 20px;
}

.sd-question__erbox--tooltip {
  height: fit-content !important;
}

// HIDE CUSTOM ELEMENTS ON QUESTION IS COLLAPSED
.sd-element--collapsed {
  .sd-question__header {
    margin-left: 0 !important;
    margin-right: 0;
    max-width: 100%;
  }

  .survey-question-card-footer {
    display: none;
  }

  .survey-question-card-auditor {
    display: none;
  }
}

.sd-element__title {
  &.sd-element__title--disabled {
    opacity: unset !important;
  }
}

.sv-components-row {
  @include media-breakpoint-up(690px) {
    gap: 15px;
  }
}

.svc-page__content {
  .svc-row {
    .sd-row.sd-row--multiple {
      width: calc(100% + 2 * var(--sjs-base-unit, var(--base-unit, 8px)));
      margin-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
      padding-bottom: calc(0.25 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;
      padding-top: calc(0.25 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;

      & > div:last-child {
        padding-right: calc(0.25 * var(--sjs-base-unit, var(--base-unit, 8px)));
      }
    }
  }
}

.sv-popup {
  z-index: 10001 !important;
}

@import "src/assets/styles/abstracts/mixins_media";

// Creator styles
.svc-question__content {
  .svc-widget__content, .sd-question {
    @include survey-phones() {
      padding-top: 12px;
    }
  }

  .sd-question.sd-question--table {
    @include survey-phones() {
      padding-top: 18px;
    }
  }
}

// QUESTIONS: Title
.sd-element--with-frame, .sd-page__row {
  padding: 0 !important;

  .sd-question:not(.sd-question--html):not(.sd-question--image) {
    border-radius: 0;
    //box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);

    .sd-question__header {
      background: var(--asr-a-grey-1);
      padding: 15px 25px;
      border-radius: 0;

      // Title & Description of a question
      .sv-string-viewer, .svc-string-editor {
        color: white;
      }

      .sd-element__title {
        .sv-string-viewer, .svc-string-editor, .sv-string-editor {
          color: white;
          font-size: 22px;
          line-height: 30px;

          span[tabindex] {
            color: var(--asr-a-grey-1);
          }
        }
      }

      .sd-element__title--expandable:before {
        top: calc(1 * var(--base-unit, 8px));
      }

      .sd-element__description, .sd-question__description {
        position: relative;
        top: 4px;

        .sv-string-viewer, .svc-string-editor {
          color: white;
          font-size: 16px;

          span[tabindex] {
            color: var(--asr-a-grey-1);
          }
        }
      }

      // Number of a question
      .sd-element__num {
        color: white;
        font-size: 20px;
        line-height: 20px;
        width: auto;
        margin-left: 0;
      }

      // required asterisk
      .sd-question__required-text {
        color: white;
      }
    }

    .sd-question__content {
      padding: 30px;
      background: var(--asr-a-grey-6);
      margin-left: unset;
      margin-right: unset;

      @include survey-phones() {
        padding: 15px;
      }

      .sv-string-viewer.sv-string-viewer--multiline {
        word-break: break-word;
      }
    }

    // ERROR BOX ON QUESTION ANSWER FAILED
    .sd-question__erbox {
      margin: 0;
      border-radius: 0;
      width: 100%;
      bottom: 0;

      .sv-string-viewer {
        font-size: 14px;
      }
    }
  }

  .sd-question--error {
    .sd-question__header {
      background: var(--asr-a-red) !important;
    }
  }

  .sd-selectbase {
    .svc-item-value-wrapper {
      .sd-item__control-label {
        margin: calc(1 * var(--base-unit, 8px)) 0;
      }

      .svc-item-value-controls {
        margin: calc(2 * var(--base-unit, 8px)) 0;
      }
    }
  }
}

// DROPDOWN
.svc-question__dropdown-choices--wrapper {
  width: 100%;
  background: var(--asr-a-grey-6);
  padding-left: 30px;

  @include survey-phones() {
    padding-left: 15px;
  }
}

.sd-row {
  margin-top: calc(4 * var(--base-unit, 8px));
}

.sd-element--collapsed, .sd-element--expanded {
  .sd-question__header {
    padding-left: 35px !important;
  }
}

.sd-item--disabled .sd-item__control-label {
  opacity: unset !important;
}

.sd-question__title {
  word-break: break-word;
}

// fix for dropdown long items
.sv-list {
  .sv-list__item {
    .sv-list__item-body {
      word-break: break-word;
    }
  }
}


// QUESTIONS SPECIFIC STYLES
@import "multiple_text";
@import "matrix";
@import "radiogroup";
@import "checkbox";
@import "sortablejs";
@import "rating";
@import "boolean";
@import "input";
@import "insert_image";
@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";


.asr-modal-overlay {
  z-index: 1300;
}

.asr-modal {
  padding: 0;
  border-radius: 6px;
  @include phones() {
    width: 100%;
  }
}

.asr-modal-paper {
  min-width: 440px;
  max-width: 500px;
  @include phones() {
    min-width: 100%;
  }
}

.asr-modal-header {
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid var(--asr-a-grey-4);
  align-items: center;
  padding: 14px 40px 14px 20px;
  word-break: break-word;
}

.asr-modal-title {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px;
  color: #333333;
  margin-bottom: 0;
}

.asr-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.organization-content {
    position: relative;
    min-height: 160px;
  }

  .fr-wrapper {
    height: 100% !important;
  }
}

.asr-modal-wrapper {
  width: 440px;

  & > div {
    min-height: fit-content;
  }

  @include phones() {
    width: 100%;
  }
}

.asr-modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  padding: 18px 20px;
  border-top: 1px solid var(--asr-a-grey-4);
  width: 100%;

  &__no-border {
    border-top: none;
  }

  &__no-border {
    border-top: none;
  }
}

.asr-modal-confirm-button {
  button {
    border-color: var(--asr-a-red) !important;
    background: var(--asr-a-red) !important;
  }
}

.asr-modal-main-content {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: $client-content-color;
}

.asr-modal-sub-content {
  margin-top: 18px;
}

// FORMIK FORM
.asr-formik-form {
  width: 100%;
}

.modal440 {
  width: 440px;
  max-width: 440px;
}
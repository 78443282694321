@import "src/assets/styles/abstracts/mixins_media";

.BgLogo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.BgLogoImage {
  width: 400px;
  height: 400px;
  background-image: var(--asr-app-background-logo-image);
  background-size: contain;
  background-repeat: no-repeat;

  @include phones() {
    width: 90%;
    height: 300px;
  }
}
.svc-side-bar__wrapper {
  .spg-panel__content {
    width: auto;
    overflow-x: scroll;

    .spg-question__content {
      overflow-x: scroll;
    }

    .spg-table {
      width: auto;
      max-width: none;

      .spg-table__cell:not(:first-of-type) {
        min-width: 100px;
      }
    }
  }
}
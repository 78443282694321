.sd-element--with-frame, .sd-page__row {

  .sd-table {
    background: transparent;

    td.sd-table__cell--actions {
      background: transparent;

      .sd-action-bar {
        background: transparent !important;
      }
    }
  }

  .sd-question.sd-question--table {
    overflow-x: unset;

    .sd-question__content {
      position: relative;
      max-width: 100%;
      //overflow-x: auto;
      min-width: auto;
    }
  }

  .sd-question__content {
    .sd-matrix:before, .sd-table-wrapper:before {
      position: relative;
      left: 10px;
      background: transparent;

      .sd-action-bar {
        margin-right: auto;
      }
    }

    .sd-matrix:after, .sd-table-wrapper:after {
      position: relative;
      right: 10px;
      background: transparent;

      .sd-action-bar {
        margin-right: auto;
      }
    }

    .sd-matrix {
      .sd-matrix__table {
        table-layout: auto;
        position: relative;

        tbody tr td {
          word-break: break-word;

          &:first-child {
            min-width: calc(min(40vw, 320px));
          }
        }
      }

      th {
        padding: 8px !important;
        min-width: 120px;
        word-break: break-word;
      }
    }

    .sd-table-wrapper {
      max-width: 100%;
      width: 100%;
    }
  }

  .sd-matrixdynamic {
    .sd-action-bar {
      margin-right: auto !important;
    }
  }

  .sd-matrix__label.sd-radio--allowhover {
    cursor: pointer;

    &:hover {
      .sd-radio__decorator {
        box-shadow: 0 0 0 2px var(--primary);
        background-color: white;

        &:after {
          content: " ";
          display: block;
          background-color: var(--primary);
        }
      }
    }
  }

  .sd-matrix__label.sd-radio--checked {
    &:hover {
      .sd-radio__decorator {
        box-shadow: 0 0 0 2px var(--secondary);

        &:after {
          content: " ";
          display: block;
          background-color: var(--secondary);
        }
      }
    }
  }
}

// remove unnecessary :after for matrix questions (1px long divider)
.sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
  display: none !important;
}


.sd-row__question {
  // PREVIEW
  .sd-question__content {
    .sd-matrix {
      overflow-x: hidden;

      fieldset, table {
        overflow-x: auto;
      }
    }

    overflow-x: hidden;

    .sd-table-wrapper {
      overflow-x: auto;
      padding-bottom: 2px;
    }
  }

  // DESIGNER
  .svc-question__content--matrixdropdown, .svc-question__content--matrixdynamic {
    .sd-question__content {
      overflow-x: auto;
    }
  }

  .svc-question__content--matrix {
    .sd-question__content {
      overflow-x: hidden;

      fieldset, table {
        overflow-x: auto;
      }
    }
  }

  .sd-matrixdropdown, .sd-matrixdynamic {
    .sd-table__cell--header.sd-table__cell--empty {
      min-width: calc(14 * var(--base-unit, 8px));
      width: calc(28 * var(--base-unit, 8px));
    }

    .sd-table__cell {
      width: 150px;
      word-break: break-word;

      .sd-checkbox, .sd-radio {
        background: transparent;

        .sd-checkbox__decorator, .sd-radio__decorator {
          border: 1px solid var(--asr-a-grey-4) !important;
        }

        .sd-item__control-label {
          color: var(--sjs-general-forecolor, var(--foreground, #161616)) !important;
        }
      }
    }

    &.sd-table--columnsautowidth .sd-table__cell:not(.sd-table__cell--actions):not(.sd-table__cell--action):not(.sd-table__cell--empty.sd-table__cell--error) {
      width: 150px;
    }
  }
}


.sd-table__cell--actions:not(.sd-table__cell--vertical):last-of-type, .sd-table__cell--empty:last-of-type, .sd-table__cell--row-text:last-of-type, .sd-matrix__cell:first-of-type:last-of-type, .sd-matrix tr > td:first-of-type:last-of-type {
  background: transparent;
  right: auto !important;
}

.sd-table__cell--actions:not(.sd-table__cell--vertical):first-of-type, .sd-table__cell--empty:first-of-type, .sd-table__cell--row-text:first-of-type, .sd-matrix__cell:first-of-type:first-of-type, .sd-matrix tr > td:first-of-type:first-of-type {
  background: transparent;
  left: auto !important;
}

// SET MATRIX ROW NOT MOVABLE
.sd-table__cell--actions:not(.sd-table__cell--vertical):first-of-type, .sd-table__cell--empty:first-of-type, .sd-table__cell--row-text:first-of-type, .sd-matrix__cell:first-of-type:first-of-type, .sd-matrix tr > td:first-of-type:first-of-type {
  background: transparent;
  left: unset;
}

// SET MATRIX ACTION NOT MOVABLE
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-of-type, .sd-table__cell--empty:last-of-type, .sd-table__cell--row-text:last-of-type, .sd-matrix__cell:first-of-type:last-of-type, .sd-matrix tr > td:first-of-type:last-of-type {
  background: transparent;
  right: unset;
}

.sd-matrix {
  .sd-table {
    .sd-table__row {
      .sd-table__cell {
        .sd-radio {
          background: transparent;

          .sd-item__decorator {
            border: 1px solid var(--asr-a-grey-4);
          }

          .sd-matrix__responsive-title {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}

// MOBILE VERSION
.sd-root-modern--mobile {
  .sd-row__question {
    .sd-matrixdropdown, .sd-matrixdynamic {
      .sd-table__cell {
        width: unset;
      }
    }
  }
}
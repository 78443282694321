@import "src/assets/styles/abstracts/mixins_media";

.sd-question {
  .sd-text__content {
    input {
      border-radius: 0;
      border-bottom: 1px solid var(--asr-a-blue);
      height: 60px;
      font-family: Roboto,sans-serif;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 400;
      letter-spacing: .05em;
      line-height: 23px !important;
      box-shadow: none !important;
    }

    input::placeholder {
      font-size: 20px !important;
    }
  }

  &.sd-question--answered {
    .sd-text__content {
      &:after {
        content: url("/assets/images/verified-2.svg");
        position: absolute;
        width: 30px;
        height: 30px;
        background-size: contain;
        right: 44px;
        top: 48px;
      }

      input {
        padding-right: 55px;
      }
    }
  }

  &.sd-question--error {
    .sd-text__content {
      &:after {
        content: url("/assets/images/Exclamation.svg");
        position: absolute;
        width: 30px;
        height: 30px;
        background-size: contain;
        right: 44px;
        top: 43px;
      }

      input {
        padding-right: 55px;
        border-bottom: 1px solid var(--asr-a-red);
      }
    }
  }


  @include survey-phones() {
    &.sd-question--answered .sd-text__content {
      &:after {
        right: 26px;
        top: 34px;
      }

      input {
        padding-right: 47px;
      }
    }

    &.sd-question--error .sd-text__content {
      &:after {
        right: 26px;
        top: 28px;
      }

      input {
        padding-right: 47px;
      }
    }
  }
}

.sd-input--disabled {
  opacity: unset !important;
}
@import 'src/assets/styles/base/base';

.landing-page__container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main-background-color;
  position: relative;

  &.loading {
    background-color: #FFFFFF;
  }
}

.landing-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page__logo {
  width: 180px;
  height: 180px;
  margin-bottom: 18px;
}

.landing-page__label {
  font-family: Changa One, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-bottom: 46px;

  span:last-child {
    color: #E1C123;
  }
}

.landing-page__button {
  background: #E1C123;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $client-content-color;
  height: 50px;
  margin-bottom: 16px;
  border: none;
  padding: 15px 25px;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.05);
  }

  span:first-child {
    text-transform: uppercase;
  }

  &:hover {
    padding: 15px 30px;
  }

  span:last-child {
    color: #FFFFFF;
  }
}

.landing-page__learn-more {
  display: flex;
  flex-direction: column;
  align-items: center;

  span, a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #C0BFBF;
  }

  a {
    color: #E1C123;
  }
}

.landing-page__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-campaign-email-error-modal {
  z-index: auto;
}

// CARD FETCHING
.asr-fetching {
  filter: blur(2px);
}
@import "src/assets/styles/base/base";

.auth-page__container {
  width: 100%;
  display: flex;
  background-color: $main-background-color;

  @media screen and (max-width: 575px) {
    display: block !important;
    overflow-y: auto;
    padding: 15px;
  }
}

.auth-page__content {
  background-color: $main-background-color !important;
  border: 1px solid #7D7C7D;
  max-width: 680px;
  position: relative;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.auth-page__logo-container {
  flex-direction: column;
  background-color: $main-background-color !important;
}

.auth-page__logo-image {
  width: 135px;
  height: 135px;
  margin-bottom: 15px;
  cursor: pointer;
}

.auth-page__logo-label {
  font-family: Changa One, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-bottom: 10px;

  span:last-child {
    color: $yellow;
  }
}

.auth-page__label {
  margin-right: 40px;
  white-space: nowrap;

  h1 {
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    font-size: 23px;
    line-height: 29px;
  }
}

.auth-page__label__wrapper {
  display: flex;
}

.auth-page__forgot-link {
  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $yellow;

    &:hover {
      color: $yellow;
    }
  }
}

.auth-page__enter-code {
  position: fixed;
  top: 30px;
  right: 40px;
  display: flex;
  align-items: center;
  z-index: 1;

  @media screen and (max-width: 575px) {
    position: static;
    justify-content: center;
    margin-bottom: 15px;
  }

  span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #C0BFBF;
  }

  span:last-child {
    text-transform: uppercase;
    color: $yellow;
  }

  &.modal-window {
    z-index: 1200;
    position: fixed;
  }
}

.auth-page__enter-code__link {
  margin-right: 5px;

  span:first-of-type {
    margin-right: 5px;
  }
}

.auth-page__already-sign-up {
  display: flex;
  flex-direction: column;

  span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: #FFFFFF;
  }

  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: $yellow;

    &:hover {
      color: $yellow;
    }
  }
}

.auth-page__campaign-name__wrapper {
  display: flex;
  flex-direction: column;

  span {
    font-family: Roboto, sans-serif;
    width: 200px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #7D7C7D;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: $yellow;

    &:hover {
      color: $yellow;
    }
  }
}

.auth-page__registered-user {
  position: fixed;
  top: 30px;
  right: 40px;
  display: flex;
  align-items: center;
  z-index: 1;

  span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #C0BFBF;
  }

  span:last-child {
    text-transform: capitalize;
    color: $yellow;
  }
}

.auth-page__in-up {
  text-transform: none;
  color: #FFFFFF;
}

.react-responsive-modal-overlay {
  &.modal-overlay {
    background-color: $main-background-color;
  }
}

.auth-page__restore-password-text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #CCCCCC;
}

.sign-in-span {
  --c: #E1C123;
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.5em;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em 0;
  cursor: pointer;

  #signIn-action-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-page__in-up {
      padding-left: 4px;
    }
  }

  &:hover {
    color: black;

    & > span {
      transform: translateY(0) scale(4);
    }
  }

  & > span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: var(--c);
    transform: translateY(150%);
    border-radius: 50%;
    transition: 0.5s;
    z-index: -1;
    top: 0;

    &:nth-child(1) {
      left: 0;
      transition-delay: 0s;
    }

    &:nth-child(2) {
      left: 25%;
      transition-delay: 0.1s;
    }

    &:nth-child(3) {
      left: 50%;
      transition-delay: 0.2s;
    }

    &:nth-child(4) {
      left: 75%;
      transition-delay: 0.3s;
    }
  }
}

.app-login-form {
  .MuiFormControl-root  {
    .MuiInputBase-input {

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 50px #243B47 inset !important;
        -webkit-text-fill-color: #FFFFFF !important;
      }

      &:-webkit-autofill::first-line {
        font-size: 1rem;
      }
    }
  }
}

.auth-page__learn-more {
  display: flex;
  flex-direction: column;
  align-items: center;

  span, a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #C0BFBF;
  }

  a {
    color: $yellow !important;

    &:hover {
      color: $yellow;
    }
  }
}

.auth-page__learn-more-modal {
  background: $main-background-color !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px;
  width: 680px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 29px;
  color: #FFFFFF;
  padding: 40px 60px 40px 40px !important;
  max-width: 500px !important;
  max-height: 70vh;
  overflow-y: auto;

  a, .email {
    color: $yellow;
  }

  a {
    &:hover {
      color: #E43956;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 575px) {
    max-width: 100% !important;
  }
}

.auth-page__learn-more-modal__container {
  background-color: $main-background-color;
  z-index: 1100;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .modal-overlay {
    background-color: transparent;
  }
}

.auth-page__learn-more-modal__close {
  svg {
    fill: $yellow;
  }
}
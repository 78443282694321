//media query
@mixin desktopLarge() {
  @media screen and (min-width: 1201px) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin desktopAndLarger() {
  @media screen and (min-width: 993px) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones() {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin survey-phones() {
  @media screen and (max-width: 599px) {
    @content;
  }
}

@mixin media-breakpoint-up($up) {
  @media screen and (min-width: $up) {
    @content;
  }
}

@mixin media-breakpoint-down($down) {
  @media screen and (max-width: $down) {
    @content;
  }
}
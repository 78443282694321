.survey-viewer-v2 {
  width: 100%;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px 16px 10px;

  .sd-element {
    box-shadow: 8px 8px 8px rgba(0, 0, 0, .1) !important;
    background-color: var(--asr-a-grey-6);
  }

  .sd-root-modern__wrapper {
    min-height: max-content;
  }

  .sd-root-modern {
    background-color: transparent;

    .sd-page {
      min-width: auto; // fix style of defaultV2
      padding-left: initial;
      padding-right: initial;
      padding-top: 0;
    }

    .sd-body {
      width: 100%;
      max-width: 100%;
      padding: unset;
    }
  }

  .survey-question-card-header {
    padding: 15px 20px 7px 25px !important;
    display: grid;
    grid-template-areas: "title actions"
                          "description actions";
    grid-template-columns: calc(100% - 50px) 50px;

    .sd-title {
      grid-area: title;

      .sd-action-bar {
        position: relative;
        top: 4px;
      }
    }

    .sd-description {
      grid-area: description;
    }

    .question-header-actions-container {
      grid-area: actions;
    }

    a {
      color: var(--asr-link-color) !important;
    }
  }

  .sd-question__content {
    a {
      color: var(--asr-link-color) !important;
    }
  }
}

.sv_progress-toc {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
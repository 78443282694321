@import '../../../assets/styles/base/base';

.checkbox-tooltip__wrapper {
    display: flex;
    align-items: center;

    .checkbox {
        color: $client-content-color;
    }

    .checkbox-checked {
        color: $client-content-color !important;
    }


    .help-icon__wrapper {
        position: relative;

        .help-icon {
            color: #24B2B2;
            cursor: pointer;
        }

        .checkbox-tooltip {
            visibility: hidden;
            width: 200px;
            background-color: $client-content-color;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 3px 3px;
            position: absolute;
            z-index: 1;
            left: -7px;
            bottom: 22px;
            transition: 0.3s;

            @media screen and (max-width: 768px) {
                width: 150px;
                left: -120px;
            }

            &:before {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #000;
                top: 100%;
                content: "";
                height: 10px;
                position: absolute;
                left: 8px;
                width: 10px;


                @media screen and (max-width: 768px) {
                    left: 120px;
                }
            }
        }

        &:hover {
            .checkbox-tooltip {
                visibility: visible;
            }
        }
    }
}
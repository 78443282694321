@import "src/assets/styles/abstracts/mixins_media";

.ContentHeaderContainer {
  padding: 30px;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;

  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  background-color: var(--asr-a-grey-bluish);
  border-top-right-radius: 5px;

  @include tablets() {
    font-size: 24px;
    line-height: 28px;
    padding: 15px 30px;
    border-top-right-radius: unset;
  }

  .MenuAndTitleContainer {
    display: flex;
    flex-flow: nowrap;
    gap: 20px;
    align-items: center;
  }
}

.CloseIcon {
  padding-left: 8px;

  svg path {
    fill: white;
  }
}

.BurgerIconContainer {
  padding: 0 !important;

  span {
    &:after {
      bottom: -9px !important;
    }

    &:before {
      top: -9px !important;
    }
  }
}
@import "src/assets/styles/base/base";

.survey-question-card-body {
  .imagepicker-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .sv_q_imagepicker_inline {

      .sv_q_imgsel_control_item {
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
      }

      .sv_q_imgsel_image {
        background-color: transparent;
        border: 0.24em solid transparent;
        display: block;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          opacity: .6;
        }
      }

      &.checked {
        .sv_q_imgsel_image {
          background-color: $survey-image-picker-background-color;
          border: 0.24em solid $survey-image-picker-background-color;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
.AsrModalLanguageSwitcher {
  .MuiOutlinedInput-root.MuiInputBase-root {
    &:hover {
      fieldset {
        border-color: transparent;
      }
    }

    fieldset {
      border-color: transparent;
    }

    &.Mui-focused {
      & fieldset {
        border-color: transparent;
      }
    }
  }
}
.label_pill-20 {
  height: 20px !important;
  text-align: center;
  width: fit-content;

  span, .label-title {
    font-size: 13px !important;
    line-height: 14px;
  }
}

.label_pill-14 {
  height: 14px !important;
  text-align: center;
  width: fit-content;

  span, .label-title {
    font-size: 12px !important;
    line-height: 14px;
  }
}

.labels-in-table-wrapper {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  height: unset;
}

.labels-in-cards-wrapper {
  display: flex;
  flex-flow: wrap;
  gap: 8px;
  height: unset;
}
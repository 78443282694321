@import "src/assets/styles/base/base";

@mixin checkbox-checked-mark($color, $scale : 1) {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background: $color;
  left: 24%;
  right: 24%;
  top: 24%;
  bottom: 24%;
  transform: scale($scale);
  transition: $transition-time;
}

@mixin read-only-choice-hover($color:  $client-content-color, $backgroundColor: white, $checkedMarkColor: transparent, $borderColor: #BDBDBD ) {
  &:hover {
    a {
      //cursor: pointer;
      color: var(--asr-a-blue) !important;
    }

    .checkbox-input__label {
      cursor: initial;
      background: $backgroundColor;
      color: $color;
      transition: $transition-time;

      .checkbox-input__checkmark {
        border-color: $borderColor;

        &:after {
          @include checkbox-checked-mark($checkedMarkColor);
        }
      }
    }
  }
}

@mixin read-only-radio-hover(
  $color:  $client-content-color,
  $backgroundColor: white,
  $checkedMarkBgColor: #F2F2F2,
  $checkedMarkFill: transparent,
  $borderColor: #BDBDBD
) {
  .radio-input__item__label {
    cursor: initial;

    &:hover {
      background: $backgroundColor;
      color: $color;

      a {
        color: var(--asr-a-blue) !important;
        cursor: pointer;
      }

      .circle {
        background-color: $checkedMarkBgColor;
        border: 1px solid $borderColor;

        .sv-hidden {
          display: flex !important;
          fill: $checkedMarkFill;
        }
      }
    }
  }
}

@mixin radio-input-circle($bg-color: #F2F2F2) {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  width: 40px;
  background-color: $bg-color;
  transition: $transition-time;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 20px;

  .sv-hidden {
    transition: $transition-time;
    display: flex !important;
    fill: transparent;
    transform: scale(0);
  }
}


@mixin read-only-sortable-hover($bgColor: #FFFFFF ) {
  &:hover {
    cursor: initial;
    background-color: $bgColor !important;
    color: var(--asr-a-grey-1) !important;

    .handle {
      svg {
        circle, ellipse {
          fill: var(--asr-a-blue);
        }
      }
    }
  }
}
@import "src/assets/styles/abstracts/mixins_media";

.DefaultModalBox {
  width: calc(min(calc(100vw - 2.4rem), 440px));
}

.LightModalBox {
  width: calc(min(calc(100vw - 2.4rem), 520px));
}

.ModalContentContainer {
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 100%;
}

.ModalHeaderTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.AsrModalContentContainer {
  padding: 30px;

  @include phones() {
    padding: 20px;
  }
}

.AsrModalFooter {
  gap: 30px;
}
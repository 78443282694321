// TODO: remove !important
.asr-collapse-card--root {
  border: none !important;
  border-bottom: 1px solid var(--asr-a-grey-4) !important;
  border-radius: 0 !important;

  &[data-open=true] {
    background: var(--asr-a-grey-6);

    .asr-collapse-card--header {
      background: transparent;
    }
  }

  &[data-open=false] {
    background: var(--asr-a-grey-6);

    .asr-collapse-card--header {
      background: #FFFFFF;

      .asr-collapse-card--sub-header-title {
        color: var(--asr-link-color);
      }
    }
  }

  &[data-error="true"] {
    background: var(--asr-a-red);

    .asr-collapse-card--header {
      background: transparent;

      .asr-collapse-card--header__sub {
        color: var(--asr-a-grey-6);
      }
    }
  }
}

.asr-collapse-card--header {
  padding: 13px 20px !important;
}

.asr-collapse-card--sub-header {
  display: flex !important;
}

.asr-collapse-card--content {
  overflow: hidden;
  padding: 0 !important;
  margin: 10px;
  border-radius: 10px;
  background: #FFFFFF;

  &:hover {
    box-shadow: 0 0 6px var(--asr-link-color);
  }

  &.divided-content > div:not(:last-child) {
    border-bottom: 1px dashed var(--asr-a-grey-4);
  }
}

.asr-collapse-card--header-error {
  background: var(--asr-a-red);
  color: white;
}


.asr-collapse-card--header__sub {
  font-size: 14px;
  line-height: 16px;
  height: fit-content;
  color: var(--asr-a-grey-3);
}

.asr-collapse-card--sub-header-title {
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  letter-spacing: 0.05em !important;
  color: var(--asr-a-grey-1);
  word-break: break-word;
  flex: 1;
}

.asr-collapse-card--sub-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.asr-collapse-card--sub-header-action {
  display: flex;
  align-self: center !important;
  margin: 0 !important;

  button {
    padding: 0;
  }
}
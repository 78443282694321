
.Circle {
  fill: black;
}

.FillPath {
  fill: var(--asr-a-yellow);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

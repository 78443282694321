.ImageInfo {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: var(--asr-a-grey-1);
  margin-bottom: 10px;
}

.ImageInfoImage, .ImageInfoLink {
  font-weight: 500;
}
.Avatar {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  width: 120px;
  height: 120px;
  text-align: center;

  &.Rounded {
    border-radius: 50%;
  }

  &.Bordered {
    border: 2px solid var(--asr-a-yellow);
  }

  &.Bordered.Error {
    border: 2px solid var(--asr-a-red);
  }

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}
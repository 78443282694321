@import '../../../../assets/styles/base/base';
@import "../../../../assets/styles/abstracts/mixins_media";

.app-footer__private {
  padding: 0 20px;

  .app-footer__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    li {
      margin: 15px 10px;
      padding: 0;
      border-radius: 4px;
      transition: .4s;

      &:hover {
        background: #ffffff;
      }
    }

    .bold {
      font-weight: 500;
    }

    .asr-footer-link-list {
      display: flex;

      .link {
        display: block;
        padding: 5px 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: var(--asr-a-grey-1);
        text-transform: lowercase;
        cursor: pointer;
      }

    }
  }

  @include tablets() {
    .app-footer__inner {
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 30px;
    }
  }
}


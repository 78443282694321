@import "src/assets/styles/abstracts/mixins_media";

.ModalContentContainer {
  display: flex;
  flex-flow: column;
  gap: 30px;
}

.ModalHeaderBlock {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  gap: 20px;

  @include phones() {
    flex-flow: wrap;
  }
}

.ModalHeader {
  display: flex;
  flex-flow: column;
  text-align: left;
  gap: 5px;

  .ModalHeaderTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
}

.ModalFormFieldsBlockWrapper {
  display: flex;
  flex-flow: nowrap;
  gap: 40px;

  @include phones() {
    flex-flow: column;
    gap: 20px;
  }

  .ModalFormFieldsWrapper {
    flex-basis: calc(100% - 200px);
    order: 1;
    display: flex;
    flex-flow: column;
    gap: 20px;

    @include survey-phones() {
      flex-basis: calc(100% - 180px);
    }

    @include phones() {
      order: 2;
      flex: 1;
      flex-basis: initial;
    }

    .InputRoot {
      font-weight: 500;
    }

    .InputLabel {
      text-transform: capitalize;
    }
  }

  .ModalFormFieldsLogo {
    padding: 10px;
    order: 2;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;

    @include survey-phones() {
      right: 20px;
    }

    @include phones() {
      order: 1;
      position: initial;
      right: unset;
      bottom: unset;
      top: unset;
    }
  }

  .InfoText {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}

.ModalFooter {
  display: flex;
  flex-flow: nowrap;
  gap: 10px;
  word-break: break-word;

  @include phones() {
    flex-flow: column;
    gap: 30px;
  }

  .SubmitButtonWrapper {
    order: 1;

    @include phones() {
      order: 2;
      margin: auto;
    }

    .SubmitButton {
      background-color: var(--asr-a-yellow) !important;
      border: none !important;

      span {
        color: var(--asr-a-grey-bluish) !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        word-break: initial;
      }
    }
  }

  .CancelText {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: var(--asr-a-yellow);
    cursor: pointer;
    align-self: center;
    order: 1;

    @include phones() {
      order: 1;
      width: 100%;
    }
  }
}

.AsrModalLogo {
  width: 117px !important;
  height: 117px !important;
}

.svc-creator {
  #scrollableDiv-designer {
    .sd-rating {
      padding-top: 24px;
    }
  }
}

.sd-question {
  .sd-rating {
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto !important;
    margin-inline-start: unset !important;
    width: auto !important;

    fieldset {
      flex-wrap: initial !important;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      .sd-rating__min-text, .sd-rating__max-text {
        border: none;
        color: var(--asr-a-grey-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: .05em;
        line-height: 23px;
      }
    }

    .sd-rating__item {
      padding: 0;
      width: 60px;
      height: 60px;
      border-radius: unset;
      border: 1px solid var(--asr-a-grey-4) !important;
      box-shadow: none !important;
      cursor: pointer;

      .sd-rating__item-text {
        color: var(--asr-a-grey-1);
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: .05em;
        text-align: center;
        position: relative;
      }

      &:hover {
        background: var(--primary);

        .sd-rating__item-text {
          color: white;
        }
      }
    }
  }
}

.sd-question.sd-question--answered {
  .sd-rating {
    fieldset {
      .sd-rating__item--selected {
        background: var(--secondary);

        .sd-rating__item-text {
          color: white;
        }
      }
    }
  }
}

.sd-question--disabled .sd-rating__item-text {
  opacity: unset !important;
}
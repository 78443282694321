.ImageActionsSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  width: 100%;

  min-height: 120px;
  background: var(--asr-a-grey-6);
  border-radius: 5px;
  flex: 2;
}

.ImageActionsSectionFields {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
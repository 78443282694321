@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

//$white:    #fff !default;
//$gray-100: #f8f9fa !default;
//$gray-200: #e9ecef !default;
//$gray-300: #dee2e6 !default;
//$gray-400: #ced4da !default;
$gray-500: #8A92A5;
//$gray-600: #6c757d !default;
//$gray-700: #495057 !default;
//$gray-800: #343a40 !default;
//$gray-900: #212529 !default;
//$black:    #000 !default;

//$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$grays: map-merge(
//                (
//                        "100": $gray-100,
//                        "200": $gray-200,
//                        "300": $gray-300,
//                        "400": $gray-400,
//                        "500": $gray-500,
//                        "600": $gray-600,
//                        "700": $gray-700,
//                        "800": $gray-800,
//                        "900": $gray-900
//                ),
//                $grays
//);
//
//$blue:    #007bff !default;
//$indigo:  #6610f2 !default;
//$purple:  #6f42c1 !default;
//$pink:    #e83e8c !default;
//$red:     #dc3545 !default;
//$orange:  #fd7e14 !default;
//$yellow:  #ffc107 !default;
//$green:   #28a745 !default;
//$teal:    #20c997 !default;
//$cyan:    #17a2b8 !default;

//$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$colors: map-merge(
//                (
//                        "blue":       $blue,
//                        "indigo":     $indigo,
//                        "purple":     $purple,
//                        "pink":       $pink,
//                        "red":        $red,
//                        "orange":     $orange,
//                        "yellow":     $yellow,
//                        "green":      $green,
//                        "teal":       $teal,
//                        "cyan":       $cyan,
//                        "white":      $white,
//                        "gray":       $gray-600,
//                        "gray-dark":  $gray-800
//                ),
//                $colors
//);

$app-primary:   #3f51b5 !default;
$primary:       $app-primary;
$secondary:     #E91E63;
$success:       #4caf50;
$info:          #00bcd4;
$warning:       #ff9800;
$danger:        #f44336;
$light:         #e8eaf6;
$light-gray:    #808080;
$dark:          $gray-800;
$gray-default: #808080 !default;
$sepia: #7c3535;
$geekblue: #030852;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
      (
              "primary":    $primary,
              "secondary":  $secondary,
              "success":    $success,
              "info":       $info,
              "warning":    $warning,
              "danger":     $danger,
              "light":      $light,
              "dark":       $dark,
              "sepia":      $sepia,
              "geekblue":   $geekblue
      ),
      $theme-colors
);

// Set a specific jump point for requesting color jumps
//$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
//$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
//$yiq-text-dark:             $gray-900 !default;
//$yiq-text-light:            $white !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-caret:                                true !default;
//$enable-rounded:                              true !default;
//$enable-shadows:                              false !default;
//$enable-gradients:                            false !default;
//$enable-transitions:                          true !default;
//$enable-prefers-reduced-motion-media-query:   true !default;
//$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
//$enable-grid-classes:                         true !default;
//$enable-pointer-cursor-for-buttons:           true !default;
//$enable-print-styles:                         true !default;
//$enable-responsive-font-sizes:                false !default;
//$enable-validation-icons:                     true !default;
//$enable-deprecation-messages:                 true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

//$spacer: 1rem !default;
//$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$spacers: map-merge(
//                (
//                        0: 0,
//                        1: ($spacer * .25),
//                        2: ($spacer * .5),
//                        3: $spacer,
//                        4: ($spacer * 1.5),
//                        5: ($spacer * 3)
//                ),
//                $spacers
//);

// This variable affects the `.h-*` and `.w-*` classes.
//$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$sizes: map-merge(
//                (
//                        25: 25%,
//                        50: 50%,
//                        75: 75%,
//                        100: 100%,
//                        auto: auto
//                ),
//                $sizes
//);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #f4f4f7;
$body-color:                #575757;


// Links
//
// Style anchor elements.

$link-color:                              $app-primary;
//$link-decoration:                         none !default;
$link-hover-color:                        darken($app-primary, 10%);
//$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px
//) !default;
//
//@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
//@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px
//) !default;
//
//@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;


// Components
//
// Define common padding and border radius sizes and more.

//$line-height-lg:              1.5 !default;
//$line-height-sm:              1.5 !default;
//
//$border-width:                1px !default;
$border-color:                #daddf1;

$border-radius:               0.375rem;
$border-radius-lg:            0.5rem;
$border-radius-sm:            0.125rem;
$border-radius-xl:            0.625rem !default;
$border-radius-xxl:           1.875rem !default;
$border-radius-circle:        50% !default;

//$rounded-pill:                50rem !default;

//Default shadow

$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
$shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-lg: 0 1px 8px -1px rgba(0, 0, 0, 0.20);
$shadow-xl: 0 0 16px 0px rgba(0, 0, 0, 0.20);
$avatar-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.20);

$box-shadow-sm:               $shadow-sm;
$box-shadow:                  $shadow;
$box-shadow-lg:               $shadow-lg;

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

//$caret-width:                 .3em !default;
//$caret-vertical-align:        $caret-width * .85 !default;
//$caret-spacing:               $caret-width * .85 !default;
//
//$transition-base:             all .2s ease-in-out !default;
//$transition-fade:             opacity .15s linear !default;
//$transition-collapse:         height .35s ease !default;
//
//$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$embed-responsive-aspect-ratios: join(
//                        (
//                                        (21 9),
//                                        (16 9),
//                                        (4 3),
//                                        (1 1),
//                        ),
//                $embed-responsive-aspect-ratios
//);

// Typography
//
// Font, line-height, and color for body text, headings, and more.
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Roboto', sans-serif;
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 0.875rem; // Assumes the browser default, typically `14px`
$font-size-lg: ($font-size-base + 0.25); //18px
$font-size-sm: ($font-size-base - 0.125); //12px

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-thin:            100 !default;
$font-weight-medium:          500 !default;
$font-weight-black:           900 !default;

//$font-weight-base:            $font-weight-normal !default;
//$line-height-base:            1.5 !default;

$h1-font-size:                1.5rem; //24px
$h2-font-size:                1.25rem; //20px
$h3-font-size:                1.125rem; //18px
$h4-font-size:                1rem; //16px
$h5-font-size:                0.875rem; //14px
$h6-font-size:                0.75rem; //12px

$headings-margin-bottom: ($h1-font-size / 2);
$headings-font-family: inherit;
$headings-font-weight: 400;
$headings-line-height: 1.2;
$headings-color: #252525;

//$display1-size:               6rem !default;
//$display2-size:               5.5rem !default;
//$display3-size:               4.5rem !default;
//$display4-size:               3.5rem !default;
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
$display-line-height:           $headings-line-height;

$lead-font-size:                1.25rem;
//$lead-font-weight:            300 !default;
//
//$small-font-size:             80% !default;
//
//$text-muted:                  $gray-600 !default;
//
//$blockquote-small-color:      $gray-600 !default;
//$blockquote-small-font-size:  $small-font-size !default;
//$blockquote-font-size:        $font-size-base * 1.25 !default;
//
//$hr-border-color:             rgba($black, .1) !default;
//$hr-border-width:             $border-width !default;
//
//$mark-padding:                .2em !default;
//
//$dt-font-weight:              $font-weight-bold !default;
//
//$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
//$nested-kbd-font-weight:      $font-weight-bold !default;
//
//$list-inline-padding:         .5rem !default;
//
//$mark-bg:                     #fcf8e3 !default;
//
//$hr-margin-y:                 $spacer !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

//$table-cell-padding:          .75rem !default;
//$table-cell-padding-sm:       .3rem !default;
//
$table-color:                 $body-color;
//$table-bg:                    null !default;
//$table-accent-bg:             rgba($black, .05) !default;
//$table-hover-color:           $table-color !default;
//$table-hover-bg:              rgba($black, .075) !default;
//$table-active-bg:             $table-hover-bg !default;
//
//$table-border-width:          $border-width !default;
//$table-border-color:          $border-color !default;
//
//$table-head-bg:               $gray-200 !default;
//$table-head-color:            $gray-700 !default;
//
//$table-dark-color:            $white !default;
//$table-dark-bg:               $gray-800 !default;
//$table-dark-accent-bg:        rgba($white, .05) !default;
//$table-dark-hover-color:      $table-dark-color !default;
//$table-dark-hover-bg:         rgba($white, .075) !default;
//$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
//$table-dark-color:            $white !default;
//
//$table-striped-order:         odd !default;
//
//$table-caption-color:         $text-muted !default;
//
//$table-bg-level:              -9 !default;
//$table-border-level:          -6 !default;


// Buttons + forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.375rem;
$input-btn-padding-x:         0.75rem;
//$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .1rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      0.25rem;
$input-btn-padding-x-sm:      0.5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      0.6rem;
$input-btn-padding-x-lg:      1.2rem;
$input-btn-font-size-lg:      0.9375rem; //15px
$input-btn-line-height-lg:    $line-height-lg;

//$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               0.375rem;
$btn-padding-x:               0.75rem;
//$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $font-size-base;
$btn-line-height:             1.2;

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            0.9375rem;
$btn-font-size-sm:            $font-size-sm;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            0.6rem;
$btn-padding-x-lg:            1.2rem;
$btn-font-size-lg:            0.9375rem; //15px
//$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-padding-y-xs:            0.375rem;
$btn-padding-x-xs:            0.625rem;
$btn-font-size-xs:            0.625rem;

//$btn-border-width:            $input-btn-border-width !default;

//$btn-font-weight:             $font-weight-normal !default;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

//$btn-link-disabled-color:     $gray-600 !default;

//$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius;
$btn-border-radius-xs:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


//Button Variables
$btn-padding: $btn-padding-y $btn-padding-x !default;
$btn-padding-lg: $btn-padding-y-lg $btn-padding-x-lg !default;
$btn-padding-sm: $btn-padding-y-sm $btn-padding-x-sm !default;
$btn-padding-xs: $btn-padding-y-xs $btn-padding-x-xs !default;

//Fab Button Variables
$btn-fab-size-lg: 70px !default;
$btn-fab-size-sm: 46px !default;
$btn-fab-size-xs: 40px !default;


// forms

$label-margin-bottom:                   0.3125rem;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

//$input-bg:                              $white !default;
//$input-disabled-bg:                     $gray-200 !default;
//
//$input-color:                           $gray-700 !default;
//$input-border-color:                    $gray-400 !default;
//$input-border-width:                    $input-btn-border-width !default;
//$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                ($border-radius-lg - .2rem);
$input-border-radius-sm:                $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              lighten($component-active-bg, 25%);
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

//$input-placeholder-color:               $gray-600 !default;
//$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y});
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{$input-padding-y / 2});

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm:                       calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

//$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:                  .35rem;

//$form-check-input-gutter:               1.25rem !default;
//$form-check-input-margin-y:             .3rem !default;
//$form-check-input-margin-x:             .25rem !default;
//
//$form-check-inline-margin-x:            .75rem !default;
//$form-check-inline-input-margin-x:      .3125rem !default;
//
//$form-grid-gutter-width:                10px !default;
//$form-group-margin-bottom:              1rem !default;
//
//$input-group-addon-color:               $input-color !default;
//$input-group-addon-bg:                  $gray-200 !default;
//$input-group-addon-border-color:        $input-border-color !default;
//
//$custom-form-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter:                 .5rem !default;
$custom-control-spacer-x:               1rem !default;

$custom-control-indicator-size:         1rem !default;
$custom-control-indicator-bg:           $input-bg;

//$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow;
//$custom-control-indicator-border-color: $gray-500 !default;
//$custom-control-indicator-border-width: $input-border-width !default;

//$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
//$custom-control-label-disabled-color:           $gray-600 !default;

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-bg:           $component-active-bg;
//$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default;
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%);
//$custom-control-indicator-active-box-shadow:    none !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius:       $border-radius;
//$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
//$custom-checkbox-indicator-icon-indeterminate:         str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23") !default;
//$custom-checkbox-indicator-indeterminate-box-shadow:   none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

//$custom-radio-indicator-border-radius:          50% !default;
//$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;
//
//$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
//$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2 !default;
//$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) !default;

$custom-select-padding-y:           $input-padding-y;
$custom-select-padding-x:           $input-padding-x;
//$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   1.6rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight;
$custom-select-line-height:         $input-line-height;
$custom-select-color:               $input-color;
//$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:                  $input-bg;
//$custom-select-disabled-bg:         $gray-200 !default;
//$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
//$custom-select-indicator-color:     $gray-800 !default;
//$custom-select-indicator:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") !default;
//$custom-select-background:          $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
//
//$custom-select-feedback-icon-padding-right: calc((1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding}) !default;
//$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
//$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

//$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $border-radius;
//$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-width:         $input-focus-width;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color;

$custom-select-padding-y-sm:        $input-padding-y-sm;
$custom-select-padding-x-sm:        $input-padding-x-sm;
$custom-select-font-size-sm:        $input-font-size-sm;
$custom-select-height-sm:           $input-height-sm;

$custom-select-padding-y-lg:        $input-padding-y-lg;
$custom-select-padding-x-lg:        $input-padding-x-lg;
$custom-select-font-size-lg:        $input-font-size-lg;
$custom-select-height-lg:           $input-height-lg;

//$custom-range-track-width:          100% !default;
//$custom-range-track-height:         .5rem !default;
//$custom-range-track-cursor:         pointer !default;
//$custom-range-track-bg:             $gray-300 !default;
//$custom-range-track-border-radius:  1rem !default;
//$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;
//
//$custom-range-thumb-width:                   1rem !default;
//$custom-range-thumb-height:                  $custom-range-thumb-width !default;
//$custom-range-thumb-bg:                      $component-active-bg !default;
//$custom-range-thumb-border:                  0 !default;
//$custom-range-thumb-border-radius:           1rem !default;
//$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
//$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
//$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
//$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%) !default;
//$custom-range-thumb-disabled-bg:             $gray-500 !default;
//
//$custom-file-height:                $input-height !default;
//$custom-file-height-inner:          $input-height-inner !default;
//$custom-file-focus-border-color:    $input-focus-border-color !default;
//$custom-file-focus-box-shadow:      $input-focus-box-shadow !default;
//$custom-file-disabled-bg:           $input-disabled-bg !default;
//
//$custom-file-padding-y:             $input-padding-y !default;
//$custom-file-padding-x:             $input-padding-x !default;
//$custom-file-line-height:           $input-line-height !default;
//$custom-file-font-family:           $input-font-family !default;
//$custom-file-font-weight:           $input-font-weight !default;
//$custom-file-color:                 $input-color !default;
//$custom-file-bg:                    $input-bg !default;
//$custom-file-border-width:          $input-border-width !default;
//$custom-file-border-color:          $input-border-color !default;
//$custom-file-border-radius:         $input-border-radius !default;
//$custom-file-box-shadow:            $input-box-shadow !default;
//$custom-file-button-color:          $custom-file-color !default;
//$custom-file-button-bg:             $input-group-addon-bg !default;
//$custom-file-text: (
//        en: "Browse"
//) !default;


// Form validation

$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $small-font-size;
$form-feedback-valid-color:         theme-color("success");
$form-feedback-invalid-color:       theme-color("danger");

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
//$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
//$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") !default;

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
                (
                        "valid": (
                                "color": $form-feedback-valid-color,
                                "icon": $form-feedback-icon-valid
                        ),
                        "invalid": (
                                "color": $form-feedback-invalid-color,
                                "icon": $form-feedback-icon-invalid
                        ),
                ),
                $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

//$zindex-dropdown:                   1000 !default;
//$zindex-sticky:                     1020 !default;
//$zindex-fixed:                      1030 !default;
//$zindex-modal-backdrop:             1040 !default;
//$zindex-modal:                      1050 !default;
//$zindex-popover:                    1060 !default;
//$zindex-tooltip:                    1070 !default;


// Navs

//$nav-link-padding-y:                .5rem !default;
//$nav-link-padding-x:                1rem !default;
//$nav-link-disabled-color:           $gray-600 !default;
//
//$nav-tabs-border-color:             $gray-300 !default;
//$nav-tabs-border-width:             $border-width !default;
//$nav-tabs-border-radius:            $border-radius;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
//$nav-tabs-link-active-color:        $gray-700 !default;
//$nav-tabs-link-active-bg:           $body-bg !default;
//$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
//
//$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

//$nav-divider-color:                 $gray-200 !default;
//$nav-divider-margin-y:              $spacer / 2 !default;


// Navbar

//$navbar-padding-y:                  $spacer / 2 !default;
//$navbar-padding-x:                  $spacer !default;
//
//$navbar-nav-link-padding-x:         .5rem !default;
//
//$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

//$navbar-toggler-padding-y:          .25rem !default;
//$navbar-toggler-padding-x:          .75rem !default;
//$navbar-toggler-font-size:          $font-size-lg !default;
//$navbar-toggler-border-radius:      $btn-border-radius !default;
//
//$navbar-dark-color:                 rgba($white, .5) !default;
//$navbar-dark-hover-color:           rgba($white, .75) !default;
//$navbar-dark-active-color:          $white !default;
//$navbar-dark-disabled-color:        rgba($white, .25) !default;
//$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
//$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
//
//$navbar-light-color:                rgba($black, .5) !default;
//$navbar-light-hover-color:          rgba($black, .7) !default;
//$navbar-light-active-color:         rgba($black, .9) !default;
//$navbar-light-disabled-color:       rgba($black, .3) !default;
//$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
//$navbar-light-toggler-border-color: rgba($black, .1) !default;
//
//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
//$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem;
$dropdown-padding-y:                .5rem;
//$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white;
//$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius;
//$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
//$dropdown-divider-bg:               $gray-200 !default;
//$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
//$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
//
//$dropdown-link-color:               $gray-900 !default;
//$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
//$dropdown-link-hover-bg:            $gray-100 !default;

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;

//$dropdown-link-disabled-color:      $gray-600 !default;

$dropdown-item-padding-y:           .25rem;
$dropdown-item-padding-x:           1.5rem;

//$dropdown-header-color:             $gray-600 !default;


// Pagination

//$pagination-padding-y:              .5rem !default;
//$pagination-padding-x:              .75rem !default;
//$pagination-padding-y-sm:           .25rem !default;
//$pagination-padding-x-sm:           .5rem !default;
//$pagination-padding-y-lg:           .75rem !default;
//$pagination-padding-x-lg:           1.5rem !default;
//$pagination-line-height:            1.25 !default;

$pagination-color:                  $link-color;
$pagination-bg:                     $white;
//$pagination-border-width:           $border-width !default;
//$pagination-border-color:           $gray-300 !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
//$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $link-hover-color;
//$pagination-hover-bg:               $gray-200 !default;
//$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;

//$pagination-disabled-color:         $gray-600 !default;
//$pagination-disabled-bg:            $white !default;
//$pagination-disabled-border-color:  $gray-300 !default;


// Jumbotron

//$jumbotron-padding:                 2rem !default;
//$jumbotron-color:                   null !default;
//$jumbotron-bg:                      $gray-200 !default;


// Cards

$card-spacer-y:                     1.25rem;
$card-spacer-x:                     1.5rem;
//$card-border-width:                 $border-width;
$card-border-radius:                $border-radius;
$card-border-color:                 $gray-400;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
//$card-cap-bg:                       rgba($black, .03) !default;
//$card-cap-color:                    null !default;
//$card-color:                        null !default;
//$card-bg:                           $white !default;

$card-img-overlay-padding:          0.75rem;

$card-group-margin:                 $grid-gutter-width / 2;
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               1.875rem;

//Custom Card Variables
$jr-card-shadow: 0 2px 5px 0 rgba($black, 0.26) !default;
$jr-card-margin: $card-columns-margin !default;
$jr-card-padding: 30px !default;
$jr-border: 1px solid $card-border-color !default;
$sub-heading-color: $light-gray !default;

// Tooltips

$tooltip-font-size:                 $font-size-sm;
//$tooltip-max-width:                 200px !default;
//$tooltip-color:                     $white !default;
//$tooltip-bg:                        $black !default;
//$tooltip-border-radius:             $border-radius !default;
//$tooltip-opacity:                   .9 !default;
//$tooltip-padding-y:                 .25rem !default;
//$tooltip-padding-x:                 .5rem !default;
//$tooltip-margin:                    0 !default;
//
//$tooltip-arrow-width:               .8rem !default;
//$tooltip-arrow-height:              .4rem !default;
//$tooltip-arrow-color:               $tooltip-bg !default;

// Form tooltips must come after regular tooltips
//$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
//$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
//$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
//$form-feedback-tooltip-line-height:   $line-height-base !default;
//$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
//$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;


// Popovers

$popover-font-size:                 $font-size-sm;
//$popover-bg:                        $white !default;
//$popover-max-width:                 276px !default;
//$popover-border-width:              $border-width !default;
//$popover-border-color:              rgba($black, .2) !default;
//$popover-border-radius:             $border-radius-lg !default;
//$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;
//
//$popover-header-bg:                 darken($popover-bg, 3%) !default;
//$popover-header-color:              $headings-color !default;
//$popover-header-padding-y:          .5rem !default;
//$popover-header-padding-x:          .75rem !default;
//
//$popover-body-color:                $body-color !default;
//$popover-body-padding-y:            $popover-header-padding-y !default;
//$popover-body-padding-x:            $popover-header-padding-x !default;
//
//$popover-arrow-width:               1rem !default;
//$popover-arrow-height:              .5rem !default;
//$popover-arrow-color:               $popover-bg !default;
//
//$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;


// Toasts

//$toast-max-width:                   350px !default;
//$toast-padding-x:                   .75rem !default;
//$toast-padding-y:                   .25rem !default;
//$toast-font-size:                   .875rem !default;
//$toast-color:                       null !default;
//$toast-background-color:            rgba($white, .85) !default;
//$toast-border-width:                1px !default;
//$toast-border-color:                rgba(0, 0, 0, .1) !default;
//$toast-border-radius:               .25rem !default;
//$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;
//
//$toast-header-color:                $gray-600 !default;
//$toast-header-background-color:     rgba($white, .85) !default;
//$toast-header-border-color:         rgba(0, 0, 0, .05) !default;


// Badges

//$badge-font-size:                   75% !default;
//$badge-font-weight:                 $font-weight-bold !default;
//$badge-padding-y:                   .25em !default;
//$badge-padding-x:                   .4em !default;
//$badge-border-radius:               $border-radius !default;
//
//$badge-transition:                  $btn-transition !default;
//$badge-focus-width:                 $input-btn-focus-width !default;
//
//$badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
//$badge-pill-border-radius:          10rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               $card-spacer-y $card-spacer-x;

//$modal-dialog-margin:               .5rem !default;
//$modal-dialog-margin-y-sm-up:       1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-color:               null !default;
//$modal-content-bg:                  $white !default;
//$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
//$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
//$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;
//
//$modal-backdrop-bg:                 $black !default;
//$modal-backdrop-opacity:            .5 !default;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            $card-spacer-y;
$modal-header-padding-x:            $card-spacer-x;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

//$modal-xl:                          1140px !default;
//$modal-lg:                          800px !default;
//$modal-md:                          500px !default;
//$modal-sm:                          300px !default;
//
//$modal-fade-transform:              translate(0, -50px) !default;
//$modal-show-transform:              none !default;
//$modal-transition:                  transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

//$alert-padding-y:                   .75rem !default;
//$alert-padding-x:                   1.25rem !default;
//$alert-margin-bottom:               1rem !default;
//$alert-border-radius:               $border-radius !default;
//$alert-link-font-weight:            $font-weight-bold !default;
//$alert-border-width:                $border-width !default;
//
//$alert-bg-level:                    -10 !default;
//$alert-border-level:                -9 !default;
//$alert-color-level:                 6 !default;


// Progress bars

//$progress-height:                   1rem !default;
//$progress-font-size:                $font-size-base * .75 !default;
//$progress-bg:                       $gray-200 !default;
//$progress-border-radius:            $border-radius !default;
//$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) !default;
//$progress-bar-color:                $white !default;
$progress-bar-bg:                   theme-color("primary");
//$progress-bar-animation-timing:     1s linear infinite !default;
//$progress-bar-transition:           width .6s ease !default;


// List group

//$list-group-color:                  null !default;
//$list-group-bg:                     $white !default;
//$list-group-border-color:           rgba($black, .125) !default;
//$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius;

//$list-group-item-padding-y:         .75rem !default;
//$list-group-item-padding-x:         1.25rem !default;
//
//$list-group-hover-bg:               $gray-100 !default;
//$list-group-active-color:           $component-active-color !default;
//$list-group-active-bg:              $component-active-bg !default;
//$list-group-active-border-color:    $list-group-active-bg !default;
//
//$list-group-disabled-color:         $gray-600 !default;
//$list-group-disabled-bg:            $list-group-bg !default;
//
//$list-group-action-color:           $gray-700 !default;
//$list-group-action-hover-color:     $list-group-action-color !default;
//
//$list-group-action-active-color:    $body-color !default;
//$list-group-action-active-bg:       $gray-200 !default;


// Image thumbnails

//$thumbnail-padding:                 .25rem !default;
//$thumbnail-bg:                      $body-bg !default;
//$thumbnail-border-width:            $border-width !default;
//$thumbnail-border-color:            $gray-300 !default;
//$thumbnail-border-radius:           $border-radius !default;
//$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) !default;


// Figures

//$figure-caption-font-size:          90% !default;
//$figure-caption-color:              $gray-600 !default;


// Breadcrumbs

//$breadcrumb-padding-y:              .75rem !default;
//$breadcrumb-padding-x:              1rem !default;
//$breadcrumb-item-padding:           .5rem !default;
//
//$breadcrumb-margin-bottom:          1rem !default;
//
//$breadcrumb-bg:                     $gray-200 !default;
//$breadcrumb-divider-color:          $gray-600 !default;
//$breadcrumb-active-color:           $gray-600 !default;
//$breadcrumb-divider:                quote("/") !default;
//
//$breadcrumb-border-radius:          $border-radius !default;


// Carousel

//$carousel-control-color:             $white !default;
//$carousel-control-width:             15% !default;
//$carousel-control-opacity:           .5 !default;
//$carousel-control-hover-opacity:     .9 !default;
//$carousel-control-transition:        opacity .15s ease !default;
//
//$carousel-indicator-width:           30px !default;
//$carousel-indicator-height:          3px !default;
//$carousel-indicator-hit-area-height: 10px !default;
//$carousel-indicator-spacer:          3px !default;
//$carousel-indicator-active-bg:       $white !default;
//$carousel-indicator-transition:      opacity .6s ease !default;
//
//$carousel-caption-width:             70% !default;
//$carousel-caption-color:             $white !default;
//
//$carousel-control-icon-width:        20px !default;
//
//$carousel-control-prev-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23") !default;
//$carousel-control-next-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23") !default;
//
//$carousel-transition-duration:       .6s !default;
//$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

//$spinner-width:         2rem !default;
//$spinner-height:        $spinner-width !default;
//$spinner-border-width:  .25em !default;
//
//$spinner-width-sm:        1rem !default;
//$spinner-height-sm:       $spinner-width-sm !default;
//$spinner-border-width-sm: .2em !default;


// Close

//$close-font-size:                   $font-size-base * 1.5 !default;
//$close-font-weight:                 $font-weight-bold !default;
//$close-color:                       $black !default;
//$close-text-shadow:                 0 1px 0 $white !default;


// Code

//$code-font-size:                    87.5% !default;
//$code-color:                        $pink !default;
//
//$kbd-padding-y:                     .2rem !default;
//$kbd-padding-x:                     .4rem !default;
//$kbd-font-size:                     $code-font-size !default;
//$kbd-color:                         $white !default;
//$kbd-bg:                            $gray-900 !default;
//
//$pre-color:                         $gray-900 !default;
//$pre-scrollable-max-height:         340px !default;


// Utilities

//$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
//$overflows: auto, hidden !default;
//$positions: static, relative, absolute, fixed, sticky !default;


// Printing

//$print-page-size:                   a3 !default;
//$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;

//App Bar Height
$app-bar-height: 70px !default;

//Footer Height
$footer-height: 50px !default;

//Side Nav Width
$side-nav-width: 250px !default;
$mini-drawer-width: 80px !default;
$sidebar-bg: #252525 !default;
$sidebar-text-color: #a1a1a1 !default;
$sidebar-bg-darken: darken($sidebar-bg, 3%);
$sidebar-hover-color: $white;
$sidebar-bg-darken-highlight: $app-primary;

//Default Image Location
$image_path: "../assets/images" !default;

// Motion
// https://material.google.com/motion/duration-easing.html#duration-easing-natural-easing-curves
$transition-bezier: .05, .74, .27, .99 !default; // for nav, quickview

$transition-ease: cubic-bezier(0.4, 0.0, 0.2, 1) !default;
$transition-ease-out: cubic-bezier(0.0, 0.0, 0.2, 1) !default;
$transition-ease-in: cubic-bezier(0.4, 0.0, 1, 1) !default;

//latter Spacing variables
$letter-spacing-base: 3px !default;
$letter-spacing-lg: 6px !default;
$letter-spacing-xl: 8px !default;

//Sizes Variables
$size-10: 10px !default;
$size-20: 20px !default;
$size-30: 30px !default;
$size-40: 40px !default;
$size-50: 50px !default;
$size-60: 60px !default;
$size-70: 70px !default;
$size-80: 80px !default;
$size-90: 90px !default;
$size-100: 100px !default;
$size-120: 120px !default;

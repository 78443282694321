.noteButtonRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px !important;
  height: 32px !important;
  min-width: inherit !important;
  max-width: 32px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 3px !important;

  &:hover {
    background-color: white !important;
  }

  &.NoteAdd {
    &:hover {
      svg {
        fill: white;

        path {
          fill: white;

          &:first-child {
            fill: var(--asr-a-blue);
          }

          &:last-child {
            fill: var(--asr-a-blue);
          }
        }
      }
    }
  }

  &.AddAuditorNote:hover {
    svg {
      path {
        fill: var(--asr-a-blue) !important;
      }
    }
  }
}

.noteButtonLabel {
  width: auto !important;
}

.MuiButtonBase-root.MuiIconButton-root {

}

@import "src/assets/styles/abstracts/mixins_media";

.asr-app-main-content {
  position: relative;
  flex: 1;
  width: 100%;
}

.asr-app-wrapper {
  position: relative;
  padding: 30px;
  width: 100%;
  max-width: var(--asr-app-wrapper-max-width);

  @include media-breakpoint-down(575px) {
    padding: 20px;
  }
}

.asr-centred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.link-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
//
.asr-break-word{
  word-break: break-word;
}

// MARGIN LEFT
.asr-ml-0 {
  margin-left: 0;
}
// W-100
.asr-w-100{
  width: 100%;
}
@import '../../assets/styles/base/base';

.public-survey__container {
    display: flex;
    justify-content: center;
}

.public-survey__wrapper {
    max-width: 770px;

    @media screen and (max-width: 768px){
        max-width: 100%;
    }

    .sv_header, .sv_body {
        padding-right: 0!important;
    }
}

.public-survey__button-start {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $main-background-color;
    text-transform: uppercase;

    .here {
        color: #FFFFFF;
        text-transform: none;
    }
}


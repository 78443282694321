@import "src/assets/styles/base/base";

.verifiedButtonTooltipPopper {
  .verifiedButtonTooltip {
    max-width: 150px;
    padding: 5px 10px;
    background-color: $client-content-color;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: 0.15008px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;

    font-family: Roboto, sans-serif;
    font-style: normal;
    top: 5px;
  }

  .verifiedButtonArrow {
    color: $client-content-color;
    font-size: 20px;
  }
}


.verifiedButtonTooltipHashtags, .tooltipShared, .tooltipIncludeExports {
  padding: 8px !important;
  text-align: left;
}

.verifiedButtonTooltipHashtags {
  max-width: 235px !important;
}

//.tooltipShared {
//  max-width: 120px !important;
//}

//.tooltipIncludeExports {
//  max-width: 150px !important;
//}

.tooltipIncludeExportsRoot {
  margin-right: 0 !important;
}


.lockedNoteTooltip {
  max-width: 150px !important;
  padding: 8px !important;
  letter-spacing: 0.15008px;
}

@import "src/assets/styles/abstracts/mixins_media";

.EditFormContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;
}

.InputField {
  padding: 8.5px 15px 11.5px 15px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid var(--asr-a-grey-5);
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;

  &.Password {
    padding-right: 32px;
  }
}

.InputLabel {
  color: white;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: left;
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  align-items: center;

  &.NewPasswordLabel {
    justify-content: space-between;
  }

  .SubLabel {
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: lowercase;
  }
}

.FullNameFieldsContainer {
  display: flex;
  flex-flow: nowrap;
  gap: 20px;

  @include tablets() {
    flex-flow: column;
  }

  .TitleSelectFieldRoot {
    border-radius: 5px;
    background: black;
    height: 36px !important;

    .TitleFieldRoot {
      max-width: 80px;
      min-width: 80px;
      background: transparent;

      @include tablets() {
        max-width: unset;
        min-width: unset;
      }

      fieldset {
        border: 1px solid var(--asr-a-grey-5);
      }

      span {
        font-weight: 300;
        letter-spacing: 0;
        text-align: left;
        color: white;
      }
    }

    .TitleFieldInput {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      text-align: left;
      color: white;
    }

    .PopupIndicator {
      background: var(--asr-a-grey-3);

      &:hover {
        background: var(--asr-a-grey-3);
      }

      svg {
        path {
          fill: white;
          transform: scale(0.75);
          transform-origin: center 9px;
        }
      }
    }

    .EndAdornment {
      right: 7px !important;
    }
  }

  .NameFieldsContainer {
    display: flex;
    flex-flow: nowrap;
    gap: 20px;

    @include phones() {
      flex-flow: column;
    }
  }

  .NameFieldRoot {
    min-width: 140px;
  }
}

.NewPasswordFieldsContainer {
  display: flex;
  flex-flow: nowrap;
  gap: 20px;
  align-items: stretch;

  @include phones() {
    flex-flow: column;
  }
}

.EyeIconContainer {
  top: 6px;
  right: 4px;
  border-radius: 15px;
  width: 24px;
  height: 24px;
  background-color: var(--asr-a-grey-3);

  &.CloseEye {
    background-color: var(--asr-a-red);
  }

  path {
    stroke: white;
  }
}

.EyeIconTooltip {
  background-color: var(--asr-a-blue) !important;

  span::before {
    color: var(--asr-a-blue) !important;
  }

  &.CloseEye {
    background-color: var(--asr-a-red) !important;

    span::before {
      color: var(--asr-a-red) !important;
    }
  }
}

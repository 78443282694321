@import "src/assets/styles/abstracts/mixins_media";

.EditorSideMenuContainer {
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  background-color: black;
  text-align: center;
  border-radius: 5px 0 0 5px;

  @include tablets() {
    max-width: unset;
    border-radius: 5px 5px 0 0;
  }
}

.UserPreviewContainer {
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-flow: column;
  font-size: 9px;
  line-height: 11px;
  font-weight: 300;

  .AvatarContainer {
    padding-bottom: 20px;

    .AvatarBlock {
      position: relative;
      width: 80px !important;
      height: 80px !important;
      margin-left: auto;
      margin-right: auto;

      @include tablets() {
        width: 150px !important;
        height: 150px !important;
      }

      &:hover {
        .AvatarOverlay {
          display: flex;
        }
      }
    }

    .AvatarOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(75, 172, 198, .7);
      top: 0;
      border-radius: 50%;
      border: 1px solid white;
      display: none;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 8px;

      @include tablets() {
        gap: 15px;
      }

      .OverlayButton {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 1px;
        text-align: center;
        color: white;
        cursor: pointer;
        border-radius: 6px;
        width: fit-content;
        padding: 1px 6px;
        text-transform: lowercase;

        @include tablets() {
          font-size: 16px;
          line-height: 18px;
        }

        &:hover {
          background: white;
          color: #4BACC6;
        }
      }
    }

    .AvatarImage {
      border: 1px solid white;
      width: 80px !important;
      height: 80px !important;
      margin: auto;
      position: relative;

      @include tablets() {
        width: 150px !important;
        height: 150px !important;
      }

      img {
        height: 100%;
      }
    }
  }

  .UserInfoContainer {
    display: flex;
    flex-flow: column;
    gap: 5px;

    .UserName {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }

    .RegisteredUserId {
      font-weight: 400;
      font-size: 8px;
      line-height: 9px;
      text-transform: capitalize;
    }

    .JoinedInfo {
      text-transform: capitalize;
    }
  }
}

.SideMenuTabsContainer {
  display: flex;
  flex-flow: column;

  .SideMenuTab {
    flex: 1;
    background-color: transparent;
    padding: 8px 10px 8px 20px;

    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
    text-transform: capitalize;

    &.Active {
      background-color: var(--asr-a-grey-bluish);
      letter-spacing: 0.1em;
    }

    &:hover:not(.Active) {
      letter-spacing: 0.1em;
      background-color: var(--asr-a-red);
    }
  }
}
.PreviewImageActions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  padding-top: 40px;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 100%;
  height: 100%;

  &.Rounded {
    border-radius: 50%;
  }
}

.PreviewImageActionsButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;

  min-width: 60px;

  background: #FFFFFF;

  border: 1px solid var(--asr-a-grey-1);
  border-radius: 3px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;

  label {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.PreviewImageActionsButtonsReplace {
  border: 1px solid var(--asr-a-blue);
  color: var(--asr-a-blue);

  &:hover {
    background: var(--asr-a-blue);
    color: white;
  }
}

.PreviewImageActionsButtonsRemove {
  border: 1px solid var(--asr-a-red);
  color: var(--asr-a-red);

  &:hover {
    background: var(--asr-a-red);
    color: white;
  }
}
@import "../../../../../../assets/styles/abstracts/mixins_media";

.questionTitleContainer {
  display: flex;
  margin-right: 15px;
}

.questionNo, .questionTitle, .questionStatusInfo {
  color: white !important;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.05em;
  word-break: break-word;

  @include phones() {
    font-size: 18px;
  }
}

.questionNo {
  width: fit-content;
  margin-right: 15px;
  word-break: keep-all;
}

.questionInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionTitle {
  margin-bottom: 10px;
}

.required {
  margin-left: 10px;
  @include phones() {
    margin-left: 0;
  }
}

.description {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: var(--asr-a-grey-6);
  word-break: break-word;
}
.ReadOnlyFormContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;

  .InputLabel {
    color: white;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-align: left;
  }

  .InputField {
    color: white;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    background-color: transparent;
    padding: 8.5px 15px 11.5px 15px;
    height: 36px;
    width: 100%;

    border-radius: 5px;

    &:disabled {
      border: 1px dashed var(--asr-a-grey-5) !important;
      margin-left: 1px;
      margin-right: 1px;
    }
  }

  .PasswordChangeInfoText {
    color: white;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0;
    text-align: left;

    .Bold {
      font-weight: 500;
    }
  }
}
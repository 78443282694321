@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";

.assessmentHeaderTooltip {
  padding: 15px !important;
  background-color: var(--asr-a-grey-6) !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25) !important;
  width: 360px !important;

  @include phones() {
    min-width: 150px;
    max-width: 100%;
  }
}

.assessmentHeaderTooltipArrow {
  color: var(--asr-a-grey-6) !important;
}

.assessmentTooltipContent {
  padding: 0 !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 23px !important;
  letter-spacing: 0.05em !important;
  color: $client-content-color !important;
}

.assessmentTooltipTermContainer {
  border-bottom: none !important;
}

.assessmentHeaderTooltipLink {
  white-space: pre;
  display: inline-flex;
  margin-bottom: 2px;
  line-height: normal;
  @include phones() {
    white-space: pre-line;
  }
}

.renderedName {
  font-size: inherit !important;
  line-height: inherit !important;

  span {
    font-size: inherit !important;
    line-height: inherit !important;
  }
}
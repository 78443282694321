.asr-default-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;


  max-width: 200px !important;
  background-color: var(--asr-a-grey-bluish) !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
}

.asr-default-tooltip-arrow {
  color: var(--asr-a-grey-bluish) !important;
}

// CLASSIC
.asr-classic-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;

  background-color: var(--asr-a-grey-1) !important;

  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 15px !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;

  &--br-0 {
    border-radius: 0 !important;
  }
}

.asr-classic-tooltip-arrow {
  color: var(--asr-a-grey-1) !important;
  font-size: 15px;
}

// TABLE BUTTONS TOOLTIP

.asr-table-popper {
  .asr-table-tooltip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    text-align: center;

    background-color: var(--asr-a-grey-1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #FFFFFF;

    margin-bottom: 7px !important;
  }

  .asr-table-arrow {
    color: var(--asr-a-grey-1);
  }

  .asr-table-tooltip-placement-top {
    margin: 5px 0;
  }
}

// CAMPAIGN / ACCOUNT DASHBOARD TOOLTIPS

.asr-dashboard-popper {
  max-width: 100%;
  overflow: hidden;
  z-index: 9 !important;
  inset: 0px -28px auto auto !important;

  .asr-dashboard-tooltip {
    animation: slideLeft 0.3s ease-out alternate forwards;
    min-width: 230px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 32px 7px 99px;
    margin: 0 !important;
    background: linear-gradient(270deg, var(--asr-a-grey-1) calc(100% - 89px), rgba(51, 51, 51, 0) 100%);
    box-shadow: none;
    border-radius: 0;
    word-break: break-word;
    
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: var(--asr-a-grey-3);
  }

  .asr-account-dashboard-tooltip {
    min-width: 186px;
    padding: 7px 32px 7px 60px;
    background: linear-gradient(270deg, var(--asr-a-grey-1) calc(100% - 50px), rgba(51, 51, 51, 0) 100%);
  }

  .asr-dashboard-arrow {
    display: none;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@import "src/assets/styles/base/base";

@mixin scroll-bar($height : 3px, $width: 3px) {
  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--asr-side-nav-bg);
  }

  scrollbar-color: var(--asr-side-nav-bg);
  scrollbar-width: thin;
}

@mixin scroll-bar-autocomplete($height : 3px, $width: 15px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--asr-a-grey-6);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--asr-a-grey-4);
    border: 5px solid var(--asr-a-grey-6);
  }

  scrollbar-color: var(--asr-a-grey-6);
  scrollbar-width: thin;
}

@mixin scroll-bar-autocomplete-transparent($height : 3px, $width: 15px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--asr-a-grey-4);
    border: 5px solid white;
  }
}

@mixin survey-custom-select() {
  .svd-toolbar-dropdown {
    &__select {
      border: 0.5px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: $client-content-color;
    }

    .svd_custom_select {
      &:hover {
        &:before {
          background-image: url("../../images/dropdown-icon-hover.svg");
        }
      }

      &:before {
        background-image: url("../../images/dropdown-icon.svg");
        background-color: white;
      }
    }
  }
}

@mixin signature-pad() {
  .sv_q_signaturepad {
    width: fit-content;


    .sjs_sp_controls {
      bottom: 8px;
      right: 2px;
    }

    .sjs_sp_clear {
      position: absolute;
      bottom: 0;
      right: 0;
      min-width: 10px;
      width: 32px;
      height: 32px;
      color: var(--asr-a-blue);
      font-size: 1.3em;
      background: white;
      padding: 0 !important;

      &:hover {
        background: var(--asr-a-blue);
        color: white;
      }
    }

    canvas {
      border: 1px dashed grey;
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}

// STATEMENTS
@mixin statement-type-tooltip {
  .tooltip {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px;
    margin: 7px 0;
    min-height: 28px;

    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background-color: var(--asr-a-grey-1);
    max-width: 250px;
  }

  .tooltipArrow {
    color: var(--asr-a-grey-1);
    font-size: 15px;
  }

  .TypeTooltipContainer {
    display: flex;
    //max-width: fit-content;
  }

  .TypeTooltipClass {
    margin-right: 10px;
    text-transform: capitalize;
  }

  .TypeTooltipExpression {
    color: var(--asr-a-grey-6);
    width: fit-content;
    max-width: 72px;
  }
}
@import "src/assets/styles/abstracts/mixins_media";

.svc-creator {
  max-height: calc(100% - 72px); // remove height of topbar of fullscreen

  // TAB: DESIGNER
  #scrollableDiv-designer {
    .sd-container-modern {
      margin: auto;
      max-width: calc(770px + 148px) !important;
      min-width: auto;

      .svc-designer-header {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 16px;

        .sd-title {
          max-width: 100%;
          color: #333;
        }

        .sd-description {
          color: #333;
        }
      }

      // fix for radiogroup
      .svc-question__dropdown-choices--wrapper {
        padding-right: unset;
        padding-left: unset;
        width: 100%;
        margin: unset;

        .svc-item-value-wrapper {
          align-items: center;
        }
      }

      // fix for checkboxes
      .sd-selectbase {
        .svc-item-value-wrapper {
          align-items: center;
        }
      }
    }

    table {
      table-layout: initial;
    }
  }

  // TAB: PREVIEW
  #scrollableDiv-test {
    background-image: var(--asr-app-background-image);
    background-color: var(--asr-app-bg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .sd-body {
      max-width: 770px;
      margin-left: auto;
      margin-right: auto;
      padding: unset;
    }

    .svc-creator-tab__content {
      .sd-root-modern {
        padding-left: 10px;
        padding-right: 10px;
        background-color: transparent;
        overflow-x: hidden;

        .sd-container-modern {
          .sd-container-modern__title {
            max-width: 770px;
            margin-right: auto;
            margin-left: auto;
          }

          .sd-page {
            width: 100%;
            max-width: 770px;
            margin-right: auto;
            margin-left: auto;
          }

          .sd-element {
            box-shadow: 8px 8px 8px rgba(0, 0, 0, .1);
            background-color: var(--asr-a-grey-6);
          }

          .sd-title {
            .sd-header__text {
              .sd-title {
                color: #333;
              }

              .sd-description {
                color: #333;
              }
            }
          }

          .sd-page {
            padding-left: 0;
            padding-right: 0;
          }

          .sd-action-bar.sd-footer {
            display: flex;
            justify-content: space-between;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            max-width: 770px;
            margin-right: auto;
            margin-left: auto;

            #sv-nav-next, #sv-nav-complete {
              align-self: flex-end;
              margin-left: auto;
              flex-grow: initial;
            }

            .sd-btn {
              background-color: #333 !important;
              border-radius: 5px !important;
              color: #fff !important;
              font-size: 13px !important;
              font-style: normal;
              font-weight: 400 !important;
              height: 30px;
              max-width: 110px;
              width: 110px;
              margin-right: 0 !important;
              text-transform: capitalize;
              padding: 1px 6px;

              box-shadow: none !important;
            }
          }
        }
      }
    }

    .sd-page {
      min-width: auto; // fix style of defaultV2
      padding-top: 0;
    }

    a {
      color: var(--asr-link-color);
    }
  }

  .svc-item-value__item {
    width: 100%;
    overflow: auto;
  }

  // fix for svg sizes
  .spg-drag-element__svg {
    height: 40px;
    width: 40px;
  }
}

.creator_mobile_save_btn {
  position: absolute;
  bottom: 4px;
  right: 24px;
  fill: var(--primary) !important;

  svg {
    width: 24px;
    height: 24px;
  }

  // HIDE custom button when view is not mobile
  @media (min-width: 600px) {
    display: none !important;
  }
}

// fix background for divider in select toolbox
.sv-list__item--selected {
  background-color: transparent !important;
}

// fix height of draggable element
.svc-dragged-element-shortcut {
  height: auto !important;
}
@import "src/assets/styles/abstracts/mixins_media";

.checkbox {
  width: 14px;
  padding: 0 !important;

  input {
    width: 14px;
    height: 14px;
    margin-right: 0;
  }

  &:hover {
    background-color: transparent !important;

  }

  &:hover .icon {
    background: var(--asr-a-blue);
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    border: none;
  }
}

.icon {
  width: 14px;
  height: 14px;
  border: 1px solid var(--asr-a-grey-2);
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 0 !important;
}

.iconDisabled {
  border: 1px solid var(--asr-a-grey-4);
}

.checkedIcon {
  background: var(--asr-a-red);
  border: none;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.checked {
  &:hover .icon {
    background: var(--asr-a-red);
    border: none;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  }
}
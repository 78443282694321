.autocomplete-single-view1 {
  position: relative;
  width: 100%;

  .select__input {
    height: 100%;
    padding: 0 !important;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--asr-a-grey-1);

    &::placeholder {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .select__root {
    width: 100%;
    height: 46px !important;

    .MuiFormControl-root {
      height: 100%;
    }

    fieldset {
      border: 1px solid var(--asr-a-grey-1);
    }

    .Mui-focused {
      fieldset {
        border-color: var(--asr-a-blue) !important;
      }
    }
  }

  .select {
    background: #fff;
    height: auto;
    border-radius: 0;
    padding: 11px 60px 11px 14px !important;
  }

  .select__clear-indicator {
    .MuiIconButton-label {
      border-radius: 3px;
      background-color: #c0bfbf;

      path {
        fill: #ffffff;
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    height: 100%;
    top: 0 !important;
  }

  .select__popup-indicator {
    color: #333333;

    &:hover {
      background-color: transparent;

      .MuiIconButton-label {
        border-radius: 3px;
      }
    }

    svg {
      margin: 0 !important;
      width: 20px;
      height: 20px;
    }
  }

  .select__color-indicator {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 3px;
  }

  .select-loading-indicator {
    position: absolute;
  }
}

.sd-element--with-frame, .sd-page__row {
  // base settings for sd-radio
  .sd-radio {
    background: white;
    padding: 0;
    margin-bottom: 5px;

    .sd-selectbase__label {
      width: 100%;
      cursor: pointer;
      gap: 16px;
      padding: 10px;
    }

    .sd-radio__decorator {
      border: 1px solid var(--asr-a-grey-4);
    }

    .sd-item__control-label {
      margin: calc(1 * var(--base-unit, 8px)) 0;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .sd-radio__decorator {
    width: 40px !important;
    height: 40px !important;
    box-shadow: none !important;
    background-color: var(--sd-item-default-background, var(--background-dim-light, #f9f9f9)) !important;

    &:after {
      width: 20px !important;
      height: 20px !important;
      border-radius: 50%;
    }
  }

  .sd-radio--checked {
    background: var(--asr-a-grey);

    .sd-radio__decorator {
      border: none;

      &:after {
        content: " ";
        display: block;
        background: var(--secondary) !important;
      }
    }

    .sd-item__control-label {
      color: white !important;
    }
  }

  .sd-radio--allowhover {
    &:hover {
      background: var(--primary);

      .sd-radio__decorator {
        border: none;

        &:after {
          content: " ";
          display: block;
          background-color: var(--primary);
        }
      }

      .sd-item__control-label {
        color: white !important;
      }

      a {
        color: white !important;
        text-decoration: underline;
      }
    }
  }
}

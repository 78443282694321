@import "src/assets/styles/abstracts/mixins_media";

.ProfileContentContainer {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

.ProfileHandleButton {
  margin-left: auto;
  padding: 10px;
  border-radius: 6px;
  min-width: 120px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  color: white;
  background-color: var(--asr-a-grey-bluish-muted);
  text-transform: uppercase;
  cursor: pointer;

  @include tablets() {
    margin-right: auto;
  }

  &.ConfirmButton {
    background-color: var(--asr-a-red);
  }
}

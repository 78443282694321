.sd-multipletext__row {
  border: none;

  .sd-multipletext__item-container.sd-input {
    display: flex;
    flex-flow: column;
    background: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;

    .sd-multipletext__item-title {
      max-width: unset;
      height: unset;
      border: none;
      margin-bottom: 0;
      color: #333333;
      font-weight: 600;
      font-size: 18px;
    }

    .sd-multipletext__item {
      width: 100%;

      input {
        padding: 0 16px;
        border-radius: 0;
        border-bottom: 1px solid var(--asr-a-blue);
        height: 60px;
        font-family: Roboto,sans-serif;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 400;
        letter-spacing: .05em;
        line-height: 23px !important;
        box-shadow: none !important;
      }

      input::placeholder {
        font-size: 20px !important;
      }

      .sd-input--error {
        background-color: var(--red-light, rgba(230, 10, 62, 0.1));
        border-bottom: 1px solid var(--asr-a-red);
      }
    }
  }
}

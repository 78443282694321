@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

.asr-survey-auditor-analytics {
  background-color: white !important;
  border-radius: 8px;
  padding: 10px;
  margin-top: 17px;
  width: 100%;

  .sa-visualizer__content {
    min-height: 350px;

    @include phones() {
      min-height: 100px;
    }
  }

  .sa-panel__header {
    margin: 0;
    display: none;
  }

  .sa-question {
    padding: 0;
    width: 100%;

    .sa-visualizer__footer {
      display: none;
    }
  }

  .sa-question__content {
    background-color: inherit !important;
    padding: 0;

  }

  .sa-question__select {
    height: fit-content !important;
  }

  .sa-question__title {
    display: none;
  }

  // ANALYTICS CONTENT
  .main-svg {
    width: 100%;
    min-height: 350px;
    @include phones() {
      min-height: 100px;
    }

    .subplot.xy {
      background: #000;
      width: 10px;
    }
  }

  @include tablets() {
    .js-plotly-plot .plotly {
      overflow-x: auto;
      @include scroll-bar;
      height: 100%;
      max-width: 100%;
    }
    .sa-question__content {
      padding: 0;
    }
    .user-select-none.svg-container {
      width: 100% !important;
    }
  }


  // TOOLBAR
  .modebar {
    right: 20px !important;
  }

  .modebar-btn {
    &.active {
      path {
        fill: var(--asr-a-grey-2) !important;
      }
    }

    &:hover {
      path {
        fill: var(--asr-a-grey-2) !important;
      }
    }

    .icon {
      width: 16px !important;
      height: 16px !important;

      path {
        //fill: var(--asr-a-grey-2) !important;
      }
    }
  }


  // HIDE PLOTY LOGO
  .modebar-btn.plotlyjsicon.modebar-btn--logo {
    display: none;
  }
}
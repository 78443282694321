@import "src/assets/styles/abstracts/mixins_media";

.Modal {
  background: black;
  box-shadow: 0 0 15px 0 var(--asr-a-blue);
  border-radius: 5px;
}

.ModalContentContainer {
  display: flex;
  flex-direction: unset !important;
  align-items: normal !important;
  color: white;
  flex: 1;
  letter-spacing: 0.05em;
  width: calc(min(calc(100vw - 2.4rem - 50px), 700px));
  max-height: 500px;

  @include tablets() {
    flex-direction: column !important;
    max-height: unset;
  }

  @include phones() {
    width: 100%;
  }
}

.ProfileEditorContentContainer {
  background-color: var(--asr-a-grey-bluish);
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  border-radius: 0 5px 5px 0;

  @include tablets() {
    width: 100%;
    border-radius: 0 0 5px 5px;
  }
}

.ContentFooterContainer {
  width: 100%;
  height: 30px;
}

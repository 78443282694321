.SurveyQuestionCardFooter {
  display: flex;
  flex-wrap: wrap;
  background: #F2F2F2;
  padding: 0 30px 20px 30px;
}

.SurveyQuestionItem {
  font-family: Roboto, sans-serif;
  font-style: normal;
  letter-spacing: 0.05em;
  margin-right: 5px;
}

.SurveyQuestionItemLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4F4F4F;
}

.SurveyQuestionItemValue {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  word-break: break-word;
}


.SurveyQuestionCustomField {
  display: flex;
  align-items: center;
}

.SurveyQuestionCustomFieldItemContainer{
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}

.SurveyQuestionCustomFieldItemValue {
  &:after {
    content: ";";
  }

  &:last-child {
    &:after {
      content: "";
    }
  }
}
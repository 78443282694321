.sv_select_wrapper {
  padding-left: 0 !important;
  margin-bottom: 0;
  overflow: hidden;


  &:hover:before {
    background: var(--asr-a-blue) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-image: url("../../../../../images/chevron-small-lite.svg") !important;
  }

  &:before {
    width: 20px !important;
    height: 20px !important;
    background-size: cover;
    z-index: 1 !important;
    border-radius: 2px;
    border: none;
    padding: 0 !important;
    top: 11px !important;
    background: var(--asr-a-grey-5) url("../../../../../images/chevron-small-black.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transform: rotate(0deg);
    right: 10px !important;
  }

  .sv_q_dropdown_control.dropdown-input {
    height: 40px;
    border: 1px solid transparent;

    .sv_q_dropdown__value {
      display: flex;
      align-items: center;
    }

    .sv-string-viewer {
      position: relative;
      z-index: 10;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 19px !important;
      line-height: 40px;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-1) !important;
      -webkit-appearance: none;
      background: white;
    }

    .sv_q_dropdown__filter-string-input {
      border: none !important;
      padding-top: 8px;
      font-size: 20px !important;
      letter-spacing: 0.05em;
      &::placeholder {
        font-size: 19px;
      }
    }
    padding-left: 10px !important;
    &:focus-within, &:focus {
      border: none !important;
      padding-left: 11px !important;

    }
  }
}

.survey-question-card-body.read-only {
  .sv_select_wrapper {
    &:hover:before {
      width: 20px !important;
      height: 20px !important;
      background-size: cover;
      z-index: 1 !important;
      border-radius: 2px;
      border: none;
      padding: 0 !important;
      top: 11px !important;
      background: var(--asr-a-grey-5) url("../../../../../images/chevron-small-black.svg") !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      transform: rotate(0deg);
      right: 10px !important;
    }
  }
}
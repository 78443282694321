@import "src/assets/styles/abstracts/mixins_media";
@import "../base/base";

.form__error-text {
  color: var(--asr-error);
  margin-top: -4px;
  font-size: 0.7rem;
  padding-bottom: 0;

  &:first-letter {
    text-transform: uppercase;
  }
}

.asr-form-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.asr-passcode-field {
  width: 120px;
  height: 46px;
  text-align: center;
}

.asr-content-wrapper {
  width: 240px;
  display: flex;
  align-items: center;

  @include phones() {
    width: 100%;
    margin-top: 20px;
  }
}

.asr-passcode-description {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
  letter-spacing: 0.05em;
}

.asr-passcode-wrapper {
  width: 140px;
  background-color: #E0E0E0;
  padding: 8px 10px 10px;
  border-radius: 3px;

  @include phones() {
    width: 100%;
  }
}

.asr-section-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;

  &__field {
    position: relative;
    margin: 10px 20px 20px 20px;
  }

  @include phones() {
    flex-direction: column !important;
  }
}


.asr-form-container--indented {
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-direction: column;
  padding: 16px 20px;

  &.actions {
    padding: 16px 20px
  }

  &.collapse-section {
    padding: 16px 0
  }
}

.asr-form-container-bordered {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $form-border-color;
  padding: 16px 20px;

  &.multiLang-order {
    padding: 8px 20px 25px 20px;
  }

  &.logo-wrapper {
    padding: 20px;
  }
}

.asr-form--code {
  width: 80px;
  margin-right: 10px;

  .asr-code-field {
    padding: 5px;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-3);
    }
  }
}

.asr-form--item {
  width: 100%;
  border-bottom: 0.5px solid #7d7c7d;
  padding: 13px 20px 20px 20px;
}

.asr-form-visibility-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 7px;
  position: static;
  height: 46px;
  background: #f2f2f2;
  border-radius: 3px;
}

// Form container for name and code
.asr-form-item--label-name {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.asr-code-field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-right: 15px;

  .statements-set-exist-error {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    color: var(--asr-error);
  }

  input {
    height: 46px;
  }

  @include tablets() {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.asr-statement-name {
  flex: 1;

  input {
    height: 46px;
  }
}

// Form container for  statement sets description
.asr-form-item--statements-set-description {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

// ORDER FIELD
.asr-form--order {
  position: relative;
  width: 50px;
  margin-right: 10px;
}

.asr-order-field {
  width: 50px !important;
  height: 46px !important;
  //padding: 24px 5px;
  padding-right: 5px !important;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
}

.asr-text-field {
  width: 100%;
  border: 0.5px solid #ddd;
  border-radius: 3px;
  height: 50px;
  padding: 0 10px 0 0;

  .MuiOutlinedInput-root {
    height: 100%;
  }

  .Mui-focused.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
    border: none !important;
  }
}

.asr-form--field {
  width: 100%;
  margin-bottom: 1rem !important;
}

.asr-form-value-cont {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #828282;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0;
}

.asr-field-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--asr-a-grey-1);
  margin-bottom: 4px;

  &:first-letter {
    text-transform: capitalize;
  }
}

.MuiFormHelperText-root.Mui-error,
.MuiFormLabel-root.Mui-error {
  color: var(--asr-error) !important;
}

.asr-dialog-wrapper {
  background-image: var(--asr-app-background-image);
  background-color: var(--asr-app-bg) !important;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.asr-autocomplete-paper {
  width: max-content;
  min-width: 360px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      height: fit-content !important;
    }

    .MuiAutocomplete-option {
      background: white !important;

      &:hover {
        background: #F2F2F2 !important;
      }
    }

    .MuiAutocomplete-option[aria-selected="true"] {
      background: #F2F2F2;
    }
  }

  @include phones() {
    width: 100%;
    min-width: 100%;
  }
}

.asr-autocomplete-text-field {
  .MuiInputBase-input {

    &::placeholder {
      font-family: Roboto, sans-serif;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      letter-spacing: 0.05em;
      color: #333333 !important;
      opacity: 1;
      text-transform: none;
    }
  }
}

.asr-table-autocomplete-text-field {
  position: relative;

  //.MuiAutocomplete-inputRoot {
  //  padding-right: 9px !important;
  //}

  //.MuiAutocomplete-endAdornment {
  //  right: 0 !important;
  //}

  .MuiAutocomplete-popupIndicatorOpen {
    //transform: translateX(-4px) rotate(180deg);
  }

  .MuiAutocomplete-clearIndicator {
    //z-index: 1;
  }

  .MuiAutocomplete-popupIndicator {
    background: white;
    //padding-right: 6px;

    &:hover {
      background-color: #fff;
    }
  }

  .MuiInputBase-input {
    z-index: 1;

    &::placeholder {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #4F4F4F;
    }
  }
}

// MULTI LANG INPUT
body {
  .asr-multiLang-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    .asr-multiLang-container {
      width: 100%;
      border: 1px solid var(--asr-input-border-color);
      border-radius: 3px;
      height: 46px;
      padding: 0 5px 0 0;

      &:hover {
        border: 1px solid var(--asr-input-border--hover-color);
      }
    }

    .asr-multiLang-changeLocaleButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 11px 12px;
      width: 36px;
      height: 36px;
      background: #BDBDBD;
      border-radius: 2px;
      font-family: Roboto, sans-serif;
      color: white;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      &:hover {
        background: #24B2B2;
      }
    }

    .asr-multiLangInputItems {
      width: 100%;
    }

    .asr-multiLang-fieldInput {
      width: 95%;
      height: 40px;
      padding-left: 10px;

      &:disabled {
        border: none !important;
      }

      &:hover {
        border: none !important;
      }

      &.organization-multiLang {
        &::placeholder {
          font-weight: 300;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.05em;
          color: #828282;
        }
      }
    }
  }
}


.asr-collapse-action-btn {
  height: 20px;
  width: 20px;

  i {
    color: var(--asr-a-grey-1);
  }

  &:hover {
    background-color: transparent !important;

    i {
      color: var(--asr-a-blue);
      transform: rotate(180deg);
      transition: .4s;
      margin-top: -2px;
    }
  }
}

.asr-collapse-section-delete-btn {
  padding: 6px !important;

  &:hover {
    background-color: transparent !important;

    .MuiIconButton-label {
      color: var(--asr-a-blue);
      transform: rotate(-90deg);
      transition: .4s;
    }
  }
}

.asr-collapse-section-btn {
  padding: 6px !important;
  font-size: 30px;

  &:hover {
    background-color: transparent !important;

    .MuiIconButton-label {
      color: var(--asr-a-blue);
    }
  }
}

// ASSESSMENTS FROALA EDITOR
.asr-assessment-auditor-froala-editor {
  margin-top: 8px !important;

  &__description {
    margin: 0 !important;
  }

  .fr-toolbar.fr-top {
    border: none;
    border-radius: 0;
  }

  .fr-popup {

    .fr-input-line {
      padding-bottom: 10px;
    }

    .fr-command.fr-btn.fr-dropdown {
      margin: 0;
    }

    .fr-layer {
      margin: 0 15px 10px 15px;
    }

    .fr-checkbox-line {
      padding-bottom: 5px;

      .fr-checkbox {
        padding: 0 10px 0 0;
      }
    }


    input {
      height: 40px !important;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-1);

      &:focus {
        border: 1px solid var(--asr-a-blue) !important;
      }
    }
  }

  .fr-second-toolbar, .second-toolbar {
    display: none;
  }

  .fr-toolbar {
    //padding: 8px 0px;
    .fr-command.fr-btn {
      margin: 4px 0;
      min-width: fit-content !important;
    }

    & .fr-newline {
      background: var(--asr-a-grey-4);
      margin: 0;
    }
  }

  .fr-wrapper {
    border: none !important;
  }

  .fr-element.fr-view {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--asr-a-grey-2);

    a {
      color: var(--asr-a-blue) !important;
      text-decoration: none !important;
    }

    p {
      margin: 0;
    }
  }

  &__modal {
    .fr-element.fr-view {
      font-size: 14px;
    }

    .fr-btn-grp.fr-float-left {
      margin: 0 5px 0 10px;
    }

    .fr-command.fr-btn {
      .fr-svg {
        max-width: 20px;
        margin: 0 5px;
      }

    }
  }
}

.fr-popup.fr-desktop.fr-ltr {
  z-index: 10000 !important;
}

.fr-placeholder {
  white-space: normal !important;
}

.fr-strong {
  font-weight: 700;
}

.fr-popup .fr-input-line input[type="text"]:focus {
  border-color: var(--asr-a-blue);
}
.fr-command.fr-submit {
  color: var(--asr-a-blue);

  &:hover {
    background: transparent;
  }
}

.fr-link-insert-layer {

  .fr-action-buttons {

    .fr-command.fr-submit {
      background: var(--asr-a-grey-1);
      border-radius: 3px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.05em;
      height: 34px;
      color: #FFFFFF;

      &:hover {
        background: var(--asr-a-blue);
        color: white;
      }
    }
  }
}

// INPUTS GENERAL
.MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-root {
  color: var(--asr-a-grey-1);
}

.MuiOutlinedInput-root.MuiInputBase-root {

  &.Mui-focused {
    & + label {
      color: black;
    }

    fieldset {
      border-color: var(--asr-a-blue);
    }
  }

  &:hover {
    fieldset {
      border-color: var(--asr-a-blue);

      legend {
        color: var(--asr-a-grey-1);
      }
    }
  }

  fieldset {
    legend {
      color: var(--asr-a-grey-1);
    }
  }

  &.Mui-error {
    fieldset {
      border-color: var(--asr-error);

      legend {
        color: var(--asr-error);
      }
    }
  }
}

// DATE AND TIME PICKER
.MuiModal-root {
  .MuiDialogContent-root {
    .css-j7qwjs {
      flex-direction: column-reverse;

      .css-1v994a0 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }

      .MuiPickersToolbar-root {
        display: none;
      }

      .MuiButtonBase-root.MuiPickersDay-root {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        color: rgba(0, 0, 0, 0.87);

        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.38);
        }

        &.Mui-selected {
          background: var(--asr-a-blue);
          color: #fff;
        }
      }

      .MuiTabs-root {
        .MuiTab-root {
          border-top: 1px solid rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.54);

          &.Mui-selected {
            border-top: 2px solid var(--asr-a-blue);
            color: var(--asr-a-blue);
          }
        }
      }

      .css-dhopo2 {
        min-height: 264px;

        .css-i6bazn {
          top: 12px;
        }
      }
    }
  }

  .MuiDialogActions-root {
    display: none;
  }
}

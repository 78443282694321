.asr-global-icon--wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--asr-table-actions-icon-size);
  width: var(--asr-table-actions-icon-size);
  background: var(--asr-table-actions-icon-background);
  border-radius: 3px;
  margin-top: .2rem;
}

.bim-global-icon{
  color: rgb(34, 176, 195);
}
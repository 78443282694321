.SucceedModalContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--asr-a-grey-bluish);
}

.TitleText {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.05em;
}

.ConfirmEmailInfoBlock {
  display: flex;
  flex-flow: column;
  gap: 3px;
  align-items: center;

  .UserEmail {
    font-weight: 500;
    color: var(--asr-a-yellow);
    word-break: break-word;
  }
}

.CheckInboxInfoBlock {
  display: flex;
  flex-flow: column;
  gap: 8px;
  align-items: center;
}

.WaitingHandGif {
  width: 88px;
  height: 84px;
  margin: auto;
}
@import "src/assets/styles/abstracts/mixins_media";

.ImagePreviewSection {
  position: relative;
  display: flex;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 6px;

  &.Rounded {
    border-radius: 50%;
  }

  @include phones() {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .ImagePreviewActions {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: none;
  }

  &:hover {
    .ImagePreviewActions {
      display: flex;
    }
  }

  .PreviewImageActions {
    cursor: initial;
  }

  .Avatar {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    text-align: center;
    border: 3px solid black;
    color: var(--asr-a-grey-2);

    &.Rounded {
      border-radius: 50%;
    }

    &.Error {
      background: var(--asr-a-grey-6);
      border: unset;
    }
  }

  .AvatarFallback{
    padding: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-align: center;
  }
}

.PreviewImageLabel {
  width: 100%;
  height: 100%;
}

//
.PreviewImageContainer, .PreviewLinkContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  width: 100%;
  height: 100%;

  &.Rounded {
    border-radius: 50%;
  }

  &.Error {
    border: 3px solid var(--asr-a-red);
  }
}
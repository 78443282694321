@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

.SurveyQuestionAuditorActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  background: #FFFFFF;
  border-top: 1px solid var(--asr-a-red);
  //overflow: auto;

  @include scroll-bar;

  @include phones() {
    padding: 10px;
  }
}

.verifiedButtonRoot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 60px;

  background: #FFFFFF !important;
  border: 1px solid var(--asr-a-blue) !important;
  box-sizing: border-box;
  border-radius: 5px !important;

  color: var(--asr-a-blue) !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  letter-spacing: 0.05em !important;
}

.verifiedButtonRootVerified {
  background: var(--asr-a-blue) !important;
  color: white !important;
}

.verifiedButtonRootModified {
  background: var(--asr-a-red) !important;
  border: 1px solid var(--asr-a-red) !important;
  color: white !important;
}


.SurveyQuestionAuditorPanels {
  width: 100%;
  margin-top: 20px;
}
@import "src/assets/styles/abstracts/mixins_media";

.DefaultModalBox {
  width: calc(min(calc(100vw - 2.4rem), 440px));

  .ContentContainer {
    padding: 20px;
  }
}

.LightModalBox {
  width: calc(min(calc(100vw - 2.4rem), 400px));
}

.ModalFooter {
  @include phones() {
    margin-top: 10px;
    flex-flow: column;
    align-items: center;
  }
}

.SubmitButton {
  background-color: var(--asr-a-red) !important;

  &:hover {
    background-color: var(--asr-a-blue) !important;
  }

  span {
    color: white !important;
  }
}

.TextButton {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
  align-self: center;
  color: var(--asr-a-grey-1) !important;

  &:hover {
    color: var(--asr-a-blue) !important;
  }
}

.ModalContent {
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 100%;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: var(--asr-a-grey-1);

  .ListOfActions {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;

    .UlText {
      color: var(--asr-a-red);
    }

    ul {
      padding-left: 26px;
      margin-bottom: unset;
    }
  }

  .Bold {
    font-weight: 500;
  }
}

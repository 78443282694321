@import "src/assets/styles/abstracts/mixins_media";
@import 'src/assets/styles/base/base';

.complete-dialog-text {
  padding-right: 1.2rem;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  text-align: center;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #CCCCCC !important;
  min-width: 200px;

  .message-highlighted {
    color: $yellow;
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: $yellow;

    &:hover {
      color: $yellow;
      text-decoration: underline !important;
    }
  }
}

.complete-dialog-link {
  color: #24B2B2;
}

.complete-dialog-link:hover {
  color: #24B2B2;
}

.to-crm-btn {
  background-color: var(--asr-to-crm-btn-bg) !important;
  height: 40px;
}

.accept-btn {
  &:disabled {
    background-color: var(--asr-to-accept-btn-bg) !important;
  }
}

.accept-btn, .reject-btn, .to-crm-btn {
  @include phones() {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: .8rem !important;
  }

  &:disabled{
    opacity: .7;
  }
}
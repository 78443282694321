@import '../../../assets/styles/base/base';

.report-card__wrapper {
  max-width: 1100px;
  padding-left: 0;
  padding-right: 0;
}

.report-card__section__wrapper {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.report-card__container {
  padding: 0;
  //overflow: hidden;
}

.campaign-results__download__container {
  display: flex;

  .campaign-results__download {

    &.xlsx {
      background-color: var(--asr-a-grey-5);
      color: var(--asr-a-grey-1);
    }

    @media screen and (max-width: 767px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .campaign-results__download.pdf {
    margin-left: 15px;
    background-color: #24B2B2;
    color: var(--asr-a-grey-1);

    @media screen and (max-width: 767px) {
      margin-left: 5px;
    }
  }

  .campaign-results__download-label {
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
}

.campaign-results__download-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px !important;
  margin-left: -12px !important;

  &.csv {
    color: #24B3B3 !important;
  }

  &.pdf {
    color: #e0e0e0 !important;
  }
}

.campaign-results__modal__container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 75px;
  min-height: calc(100vh - 64px);

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

.campaign-results__cards-wrapper {
  padding: 15px;
  max-width: 1130px;

  @media screen and (max-width: 1100px) {
    padding: 3px;
  }
}

.report-card__header__container {
  background-color: $client-content-color;
  padding: 21px 33px;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;

  &.collapsed {
    border-radius: 6px;
  }
}

.report-card__section__header__container {
  background-color: #7D7C7D;
  padding: 11px 20px;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
}

.report-card__body__wrapper {
  padding: 21px 33px;
  background-color: #ffffff;
}

.report-card__body__container {
  padding: 5px;
  border: 0.5px solid #24B2B2;
}

.report-card__section__body__container {
  background-color: #ffffff;
}

.report-card__header__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #F5F5F5;
  letter-spacing: 1.2px;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.report-card__section__header__text {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.report-card__list__item {
  display: flex;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  color: $client-content-color;
  border-bottom: 1.5px solid #FFFFFF;
  min-height: 50px;

  .border_bottom_container {
    width: 100%;
    position: absolute;
    border-bottom: 0.5px solid #7D7C7D;
    bottom: 0;
  }

  @media screen and (max-width: 1099px) {
    flex-direction: column;
    border-bottom: none;
  }

  &.background {
    background: linear-gradient(90deg, #F2F2F2 50%, #FFFFFF 50%);

    @media screen and (max-width: 1099px) {
      background: none;
    }
  }

  .question__wrapper {
    padding: 10px 18px;
    width: 50%;
    font-size: 14px;

    @media screen and (max-width: 1099px) {
      width: 100%;
      border-bottom: 0.5px solid #7D7C7D;
    }
  }

  .card3-question__wrapper {
    width: calc(100% - 80px);
    border-bottom: none;
  }


  .question {
    word-break: break-word;

    a {
      cursor: pointer;
      color: #c64B6F;
    }
  }

  .answer__wrapper {
    width: 50%;
    display: flex;
    font-size: 20px;

    @media screen and (max-width: 1099px) {
      border-bottom: 0.5px solid #7D7C7D;
      width: 100%;
      font-size: 12px;
    }
  }

  .answer {
    font-weight: normal;
    padding-left: 38px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    width: calc(100% - 80px);

    a {
      color: #c64B6F;
      cursor: pointer;
    }

    @media screen and (max-width: 1099px) {
      width: 100%;
      padding-left: 20px;
    }

    &.multipletext-answer {
      flex-direction: column;
      align-items: flex-start;
    }

    .answer-value {
      word-break: break-word;
    }
  }

  &:last-child {
    border-bottom: none;

    .answer__wrapper {
      @media screen and (max-width: 1099px) {
        border-bottom: none;
      }
    }
  }
}

.card3-list__item {
  flex-direction: row;
  background-color: #F2F2F2;
}

.answer-status-note {
  display: flex;
  width: 80px;
  padding-top: 10px;
  z-index: 1;
  align-items: flex-start;

  .answer-status, .answer-note {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
  }

  .answer-status__container {
    position: relative;

    &:hover {
      .answer-status__tooltip {
        //visibility: visible;
        display: block;
      }
    }
  }

  .answer-note__container {
    position: relative;

    &:hover {
      .answer-note__tooltip {
        //visibility: visible;
        display: block;
      }
    }
  }

  .answer-status__tooltip, .answer-note__tooltip {
    //visibility: hidden;
    display: None;
    background-color: $client-content-color;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 26px;
    transform: translateX(-50%);
    transition: 0.3s;
    font-size: 12px;
    line-height: 14px;

    &:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
      top: 100%;
      content: "";
      height: 10px;
      position: absolute;
      left: 50%;
      width: 10px;
      transform: translateX(-50%);
    }
  }

  .answer-note__tooltip {
    width: 120px;
  }
}

.report-card__no-content {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $client-content-color;
}

.card3-content-container {
  display: flex;

  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }

  .contract-type, .client-type {
    width: 50%;

    @media screen and (max-width: 1099px) {
      width: 100%;
    }
  }

  .contract-type {
    border-right: 1.5px solid #FFFFFF;

    @media screen and (max-width: 1099px) {
      border-right: none;
      border-bottom: 1.5px solid #FFFFFF;
    }
  }
}

.card6-content-container {
  display: flex;
  flex-direction: column;
}

.bar-chart__title {
  padding: 15px 20px;
  background-color: #F5F5F5;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: $client-content-color;
  margin-bottom: 15px;
}
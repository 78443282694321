@import '../../../assets/styles/base/base';
@import "src/assets/styles/abstracts/mixins_media";

.styled-form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;

  .Mui-focused {
    fieldset {
      border: 0.5px solid #24B2B2 !important;
    }
  }

  fieldset {
    border: 0.5px solid #242323 !important;
  }

  &:hover {
    fieldset {
      border: 0.5px solid #24B2B2 !important;
    }
  }
}

.styled-form-input__label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
  text-transform: capitalize;
  color: var(--asr-a-grey-1);
}

.styled-form-input__input {
  border: 1px solid #242323;
  border-radius: 3px;
  height: 46px;
  padding-left: 10px;
  padding-right: 10px;
  color: $client-content-color;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;

  &:hover {
    border: 1px solid #24B2B2 !important;
  }

  &:focus {
    border: 1px solid #24B2B2;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #ffffff inset;
  }

  &:disabled {
    border: 0.5px solid #242323 !important;
  }

  &--error{
    border: 1px solid var(--asr-error);
    &:hover {
      border: 1px solid var(--asr-error) !important;
    }
  }

  &.asr-passcode-field {
    &::placeholder {
      font-size: 16px;
    }

    @include phones() {
      width: 100%;
    }
  }

  &::placeholder {
    font-weight: 300;
    font-size: 13px;
    //line-height: 20px;
    letter-spacing: 0.05em;
    color: #7D7C7D;
  }

  &.organization-code {
    &::placeholder {
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #828282;
    }
  }
}

.styled-form-input__select {
  padding-top: 15.5px;
  padding-bottom: 15.5px;
  //border: 0.5px solid #242323 !important;
  border-radius: 3px !important;
  background-color: transparent !important;
  text-transform: capitalize;

  &.MuiSelect-select.MuiSelect-select {
    padding-right: 20px;
  }
}

.styled-form-input__select-option {
  text-transform: capitalize;

  &.Mui-selected {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.asr-select-form-option-item {
  overflow: hidden;
  text-transform: none;
}

.styled-form-input__textarea {
  border: 0.5px solid #242323;
  border-radius: 3px;
  resize: none;
  height: 90px;
  padding: 10px;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $client-content-color;
  letter-spacing: 0.05em;

  &:hover {
    border: 1px solid #24B2B2 !important;
  }

  &:focus {
    border: 1px solid #24B2B2;
  }

  &::placeholder {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #7D7C7D;
  }
}

.styled-form-input__error {
  position: absolute;
  bottom: -18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  width: max-content;
  word-break: break-word;

  color: var(--asr-error);
  margin-top: 6px;

  &.passcode {
    bottom: -28px;
  }

  &:first-letter {
    text-transform: capitalize;
  }
}

.styled-form-checkbox {
  display: flex;
  flex-direction: column;
}

.styled-form-checkbox__label {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #242323;

  &.bold {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #7D7C7D;
  }

  &.active {
    font-weight: 500;
    color: #231F20;
  }

  &:hover {
    input ~ .styled-form-checkbox__checkmark {
      background-color: #24B2B2;
    }
  }

  input:checked ~ .styled-form-checkbox__checkmark {
    background-color: #B74250;
  }
}

.styled-form-checkbox__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.styled-form-checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: #E7E7E7;
  border: 1px solid #7D7C7D;
  box-sizing: border-box;
  border-radius: 3px;
}

.styled-form-toggle-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styled-form-toggle-switch__label {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #242323;
  margin-bottom: 6px;
}

.styled-form-toggle-switch__switch-wrapper {
  height: 46px;
  display: flex;
  align-items: center;
}

.styled-form-toggle-switch__container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.styled-form-toggle-switch__container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.styled-form-toggle-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
}

.styled-form-toggle-switch__container input:checked + .styled-form-toggle-switch__slider {
  background-color: #24B2B2;
}

.styled-form-toggle-switch__container input:checked + .styled-form-toggle-switch__slider:before {
  transform: translateX(28px);
}

.styled-form-submit-button {
  background-color: #7D7C7D;
  border-radius: 3px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  border: none;
  padding: 5px 15px;
  text-transform: capitalize;

  &:disabled {
    opacity: .6;

    &:hover {
      background: #7D7C7D;
    }
  }

  &:hover {
    background-color: #24B2B2;
  }
}

.styled-form-cancel-button {
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.05em;
  color: #231F20;
  border: none;
  padding: 5px 15px;

  &:hover {
    color: #24B2B2;
  }
}

.unlock-modal__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  flex: 1;
  max-width: 400px;

  .text {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $main-background-color;
    margin-bottom: 0;
  }
}

//
.asr-select-single {
  .select-dropdown-icon {
    right: 0;
    top: 0;
    color: var(--asr-a-grey-1);
    font-size: 2.8rem;
  }

  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
}
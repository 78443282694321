@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";

.sv_q.sv_qstn.rating-input__wrapper {
  .rating-input {
    padding-left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    fieldset{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .rating-input__text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: $client-content-color;
    max-width: 131px;
    word-wrap: break-word;
    display: inline-block;
  }

  .rating-input__label {
    display: flex !important;
    align-items: self-start;
    justify-content: space-between !important;
    margin-bottom: 2px;

    &:first-child > .rating-input__item {
      @media screen and (max-width: 575px) {
        margin-left: 0 !important;
      }
    }


    input:disabled ~ .rating-input__item {
      &:hover {
        background: white !important;
        color: $client-content-color;
        border: 1px solid var(--asr-a-grey-4) !important;
      }
    }
  }

  .rating-input__item {
    width: 60px;
    height: 60px;
    background: white;
    border: 1px solid var(--asr-a-grey-4) !important;
    margin-left: 5px !important;
    margin-top: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $client-content-color;
    transition: 0.3s;

    @media screen and (max-width: 499px) {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    &:hover {
      color: white;
      background-color: var(--asr-a-blue) !important;
      border-color: var(--asr-a-blue) !important;
    }
  }

  .sv_q_rating_item.active {
    .rating-input__item {
      color: white;
      background-color: var(--asr-a-red) !important;
      border-color: var(--asr-a-red) !important;
    }

    input:disabled ~ .rating-input__item {
      &:hover {
        color: white;
        background-color: var(--asr-a-red) !important;
        border-color: var(--asr-a-red) !important;
      }
    }
  }

  .rating-input__max-text {
    margin-left: 15px;
    @media screen and (max-width: 575px) {
      margin-left: 0;
      text-align: right;
      position: relative;
    }
  }

  .rating-input__min-text {
    margin-right: 10px;
    @media screen and (max-width: 575px) {
      margin-right: 0;
      position: relative;
    }
  }
}
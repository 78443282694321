.root {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // backgroundColor: "#00000014",
  filter: "drop-shadow(2px 4px 6px black)";
  z-index: 100;
  top: 0;
  left: 0;
}

.rootWithBackDrop {
  background: rgba(31, 18, 18, 0.03);
}

.popper {
.tooltip {
    background-color: #FFFFFF;
    color: var(--asr-side-nav-bg);
    width: 210px;
    font-size: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 5px;
    padding: 0;
    border-radius: 0;
  }

.arrow {
    color: var(--asr-side-nav-bg);
    font-size: 1rem;
  }
}


.termContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--asr-side-nav-bg);
}

.termHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 30px;
  background-color: #F2F2F2;
  padding: 8px;
  // borderBottom: "1px dashed #074873",
}

.termHeaderLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  word-break: break-word;
}


.termHeaderContent {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: var(--asr-side-nav-bg);
  padding: 8px;
}

.termTerm {
  border-bottom: 1px dotted #000000;
}


.openLinkIcon {
  svg {
    path {
      stroke: var(--asr-side-nav-bg)
    }
  }
}
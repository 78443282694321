.asr-froala-description {
  .fr-view {
    p {
      padding: 0;
      margin-bottom: 0;
    }

    a {
      color: var(--asr-link-color);
    }
  }
}

// FROALA STATIC PAGES
.asr-static-pages-froala-editor {
  border: 1px solid var(--asr-a-blue);
  //overflow: hidden;
  border-radius: 6px;

  &.sharp-corners {
    border-radius: unset;

    .fr-toolbar.fr-top,
    .fr-command.fr-submit,
    .fr-wrapper {
      border-radius: unset;
    }
  }

  .fr-toolbar.fr-top {
    border: none;
  }

  .fr-toolbar .fr-btn-grp{
    margin-right: 10px;
  }
  .fr-popup {
    .fr-input-line {
      padding-bottom: 10px;
    }

    .fr-command.fr-btn.fr-dropdown {
      margin: 0;
    }

    .fr-layer {
      margin: 0 15px 10px 15px;
    }

    .fr-checkbox-line {
      padding-bottom: 5px;

      .fr-checkbox {
        padding: 0 10px 0 0;
      }
    }


    input {
      height: 40px !important;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: var(--asr-a-grey-1);

      &:focus {
        border: 1px solid var(--asr-a-blue) !important;
      }
    }

    .fr-command.fr-submit {
      background: var(--asr-a-grey-1);
      border-radius: 3px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.05em;
      height: 34px;
      color: #FFFFFF;

      &:hover {
        background: var(--asr-a-blue);
        color: white;
      }
    }
  }

  .fr-second-toolbar, .second-toolbar {
    display: none;
  }

  .fr-toolbar {
    .fr-command.fr-btn {
      margin: 4px 0;
      min-width: fit-content !important;
    }

    & .fr-newline {
      background: var(--asr-a-grey-4);
      margin: 0;
    }
  }

  .fr-wrapper {
    border: none !important;
    height: 100%;
    border-radius: 10px;
  }

  .fr-element.fr-view {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--asr-a-grey-1);
    height: 100%;

    a {
      color: var(--asr-organization-link-color) !important;
      text-decoration: none !important;
    }

    p {
      margin: 0;
    }
  }

  ///


  .fr-btn-grp.fr-float-left {
    margin: 0 5px 0 10px;
  }

  .fr-command.fr-btn {
    .fr-svg {
      max-width: 20px;
      margin: 0 5px;
    }

  }
}
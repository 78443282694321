@import "src/assets/styles/abstracts/mixins_media";

.ContentContainer {
  flex: 1;
  padding: 10px 30px;
  overflow-y: auto;

  @include tablets() {
    padding: 20px;
  }
}

.ContentCard {
  padding: 20px;
  border-radius: 6px;
  gap: 15px;
  background-color: var(--asr-a-grey-bluish-muted);
}

.EmailChangeConfirmationContentContainer {
  display: flex;
  flex-flow: column;
  gap: 5px;
  letter-spacing: 0;
  max-width: 280px;

  .MessageTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }

  .MessageText {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
}
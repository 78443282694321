.note-modal {
  max-width: 1000px !important;
  width: 1000px !important;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $client-content-color;
    padding: 16px 33px 16px 38px;

    @media screen and (max-width: 414px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 1.2px;
      color: #F5F5F5;
      margin-bottom: 0 !important;
    }
  }


  .close-button {
    color: #fff;
    cursor: pointer;
  }

  .question-title__wrapper {
    background-color: #F5F5F5;
    padding: 22px 33px 22px 38px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 414px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .question-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 1.2px;
      color: $client-content-color;
      word-break: break-word;
    }

    .note-edited__wrapper {
      flex-shrink: 0;
      width: 40%;
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 15px;
        justify-content: flex-start;
      }

      .note-edited {
        display: flex;
        flex-direction: column;
        font-family: Roboto;
        font-style: normal;
        letter-spacing: 0.05em;
        color: #4C4C4C;

        .note-edited__title, .note-edited__date {
          font-size: 9px;
          line-height: 14px;
          font-weight: normal;
        }

        .note-edited__user {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .question-note__editor {
    padding: 22px 33px 22px 38px;

    @media screen and (max-width: 414px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .editor-wrapper {
      margin-bottom: 42px;
      z-index: 2000;
    }

    .checkboxes-container {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .checkbox-tooltip__wrapper:first-child {
        margin-right: 40px;
      }
    }

    .submit-button__wrapper {
      display: flex;
      justify-content: flex-end;

      .button {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #D2F1DB;
        text-transform: none;
        padding: 8px 20px;
      }

      .submit {
        background-color: $client-content-color;
      }

      .delete {
        background-color: #B74250;
        margin-right: 24px;
      }
    }
  }
}

.UrlField {
  width: 100%;

  .UrlInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
    gap: 10px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 3px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    width: 100%;

    &:read-only {
      background: var(--asr-a-grey-6);
      color: var(--asr-a-grey-4);
      border: 1px dashed var(--asr-a-grey-3);
    }

    &:hover {
      &:read-only {
        border: 1px dashed var(--asr-a-grey-3) !important;
      }
    }
  }

  .FieldError {
    bottom: -28px;
    left: -150px;
    width: 400px;
  }
}



@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

.table__autocomplete {
  width: 100%;
  height: 32px;

  .select__input {
    padding: 0 !important;
    height: 100%;
    color: $client-content-color;
    letter-spacing: 0.05em;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    &::placeholder {
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #7d7c7d;
      opacity: 1;
    }
  }

  .select__root {
    width: 100%;
    height: 100%;

    .MuiFormControl-root {
      height: 100%;
    }

    fieldset {
      border: 1px solid var(--asr-a-grey-1) !important;
    }

    .Mui-focused {
      fieldset {
        border-color: #24b2b2;
      }
    }

    @media screen and (max-width: 767px) {
      flex: 1;
    }
  }

  .select {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    border-radius: 3px;
    padding-top: 4px;
    padding-bottom: 4px;

    &:hover {
      fieldset {
        border: 1px solid #24b2b2;
      }
    }
  }

  .select__clear-indicator {
    .MuiIconButton-label {
      border-radius: 3px;
      background-color: #c0bfbf;

      path {
        fill: #ffffff;
      }
    }
  }

  .select__popup-indicator {
    border-radius: 0;

    &:hover {
      background-color: transparent;

      .MuiIconButton-label {
        background-color: transparent;
        border-radius: 3px;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

// =========================  TABLE PANEL AUTOCOMPLETE  ================================
body {
  .asr-table-autocomplete {
    width: 100%;
    height: 28px;
    overflow: inherit;

    .MuiAutocomplete-endAdornment {
      right: 5px !important;
    }

    .select {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      border-radius: 0;
      box-sizing: border-box;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      padding-right: 30px !important;

      &:hover {
        background: var(--asr-a-blue);

        .asr-table-autocomplete--multi-render-input {
          span {
            color: white;
          }
        }

        fieldset {
          border-color: white !important;
        }

        .select__popup-indicator {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .select__root {
      min-width: 69px;

      fieldset {
        legend {
          width: 0;
        }
      }

      &.Mui-focused, &--expanded:hover, &--filled {
        .select {
          background: var(--asr-a-red);
          width: var(--asr-autocomplete-right-padding) !important;

          @include tablets() {
            width: 100% !important;;
          }

          input {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;

            letter-spacing: 0.05em;
            color: white;
          }

          &:hover {
            background: var(--asr-a-red);

            fieldset {
              border-color: white !important;
            }
          }

          fieldset {
            border-color: white !important;

            legend {
              width: 0;
            }
          }

          .asr-table-autocomplete--multi-render-input {
            span {
              color: white;
            }
          }
        }

        .select__popup-indicator {
          background: transparent;

          svg {
            path {
              fill: white;
            }
          }
        }

        .select__clear-indicator {
          background: var(--asr-a-red);
          z-index: 10;
          display: flex;
        }


        fieldset {
          border: 1px solid var(--asr-a-grey-1);
        }
      }
    }


    .select__clear-indicator {
      border-radius: 0;
      padding: 0;
      //display: none;
      background: var(--asr-a-red) !important;
      z-index: 10;

      &:hover {
        background-color: var(--asr-a-red);
      }

      background: transparent;

      svg {
        width: 16px;
        height: 16px;
      }

      path {
        fill: #ffffff;
      }
    }

    .select__popup-indicator {
      background: transparent;
      height: 100%;

      &:hover {
        background: transparent;

        svg {
          path {
            fill: white;
          }
        }
      }

      svg {
        width: 12px;
        height: 4.5px;
      }
    }

    // SELECTED
    &.table__autocomplete--selected {
      .select {
        background: var(--asr-a-red);

        fieldset {
          border: 1px solid var(--asr-a-red) !important;
        }

        .asr-table-autocomplete--multi-render-input {
          span {
            color: white;
          }
        }
      }

      .select__clear-indicator {
        visibility: visible;
      }

      .select__popup-indicator {
        background: transparent;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

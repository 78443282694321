.LightModal {
  width: fit-content;
  border-radius: 20px !important;
  padding: unset;
}

.LightModalBox {
  min-height: unset !important;
}

.LightModalGapContainer {
  padding: 20px;
  background: var(--asr-a-grey-6);
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.LightModalTitleContainer {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  padding-bottom: 20px;
  word-break: break-word;
}

.LightModalContentContainer {
  border-radius: 10px;
  padding: 30px;
  background: white;
  height: 100%;
  width: 100%;

  font-weight: 500;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--asr-a-grey-bluish);

  &.DashedBorder {
    padding: 29px;
    border: 1px dashed var(--asr-a-grey-3);
  }
}

.ModalFooter {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 20px;
  word-break: break-word;
}

.DefaultFooterButton {
  background-color: var(--asr-a-yellow) !important;
  border: none !important;

  &:hover {
    background-color: var(--asr-a-yellow) !important;
  }

  span {
    color: var(--asr-a-grey-bluish) !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    word-break: initial;
  }
}

.TextFooterButton {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--asr-a-yellow);
  cursor: pointer;
  align-self: center;
}
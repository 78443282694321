.sjs-sortablejs-source {
  border: none !important;
  margin-top: 30px !important;

  .sjs-sortablejs-item--label {
    color: #333333 !important;
  }
}

.sjs-sortablejs-result {
  border: 1px solid #333333 !important;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  padding: 10px;

  .sjs-sortablejs-item {
    background-color: var(--asr-a-grey) !important;
    text-align: left;
    margin: 0 0 10px 0 !important;

    .handle svg > * {
      fill: #FFFFFF;
    }

    .sjs-sortablejs-item--label {
      color: #FFFFFF !important;
    }
  }

  & > span {
    color: var(--asr-a-grey-4);
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 28px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}

// FIXED sortable js view of items
.sjs-sortablejs-item {
  display: flex;
  gap: 10px;
  padding: 15px !important;
  background-color: #FFFFFF !important;
  color: #333333 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-align: justify;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  .handle {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;

    svg > * {
      fill: var(--asr-a-blue);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--asr-a-blue) !important;
    color: #FFFFFF !important;

    .sjs-sortablejs-item--label {
      color: #FFFFFF !important;
    }

    .handle svg > * {
      fill: #FFFFFF;
    }

    a {
      color: #FFFFFF !important;
      text-decoration: underline !important;
    }
  }
}

// hide DnD from items in designer tab
.svc-creator {
  #scrollableDiv-designer {
    .sjs-sortablejs-item {
      .handle {
        display: none;
      }
    }
  }
}

.side-nav{
  background-color: var(--asr-side-nav-bg) !important;
}
.side-nav__container {
  padding-top: 40px;
}

.side-nav .user-profile{
  background-color: var(--asr-nav-user-profile-bg);
}

.side-nav-scrollbar-wrapper {
  flex: 1;
  & > .os-padding {
    .os-content {
      display: flex;
      flex-direction: column;
    }
  }
}

.nav-header {
  font-weight: 300;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: capitalize !important;
  color: #FFFFFF !important;
  margin-top: 0 !important;

  &.admin {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 19px;
    text-transform: uppercase !important;
    letter-spacing: 0.05em;
    color: #BFBFBF !important;
  }

}

.menu {
  &.no-arrow {
    font-family: var(--asr-app-font);
    font-style: normal;
    margin-bottom: 0 !important;

    .entity_name {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: #F4F4F4;
      padding: 7px 35px 8px 20px;
    }

    .selected-organization, .selected-campaign {
      background-color: #000000;

      .campaign-name {
        word-break: break-all;
      }
    }

    .organization-name, .campaign-name {
      font-size: 14px;
      line-height: 16px;
      color: #BFBFBF;
      text-transform: capitalize;
      padding: 7px 35px 8px 20px;
    }

    .nav-text.colored {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: #24B2B2;
    }

    .nav-text.colored.module {
      font-weight: 500;
      line-height: 17px;
      color: #A1A1A1;
    }

    .nav-text.colored.module.incomplete {
      color: #24B2B2;
    }

    .nav-text.brackets::before {
      content: "["
    }

    .nav-text.brackets::after {
      content: "]"
    }

    & .prepend-icon.active {
      color: white;
    }

    & .prepend-icon.admin.active {
      cursor: auto;
      color: var(--asr-nav-link-active-color);
      background-color: var(--asr-nav-link-active-bg);

      &:hover {
        color: var(--asr-nav-link-active-hover-color);
        background-color: var(--asr-nav-link-active-hover-bg);
      }
    }

    & .prepend-icon.admin.active.home {
      cursor: auto;
      color: var(--asr-nav-link-active-color);
      background-color: var(--asr-nav-link-active-home-bg);

      &:hover {
        color: var(--asr-nav-link-active-hover-color);
        background-color: var(--asr-nav-link-active-home-bg);
      }
    }


    & .prepend-icon.admin {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: var(--asr-nav-link-color);
      border-bottom: 0.5px solid #000000;

      &:hover {
        color: var(--asr-nav-link-hover-color);
        background-color: var(--asr-nav-link-hover-bg);
      }
    }

    & .other-link {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: var(--asr-nav-link-color);

      &:hover {
        color: var(--asr-nav-link-hover-color);
        background-color: var(--asr-nav-link-hover-bg);
      }
    }

    .assessor-not-staff {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #24B2B2;
      text-transform: uppercase;
      border-bottom: 0.5px solid #000000;

      &:hover {
        color: #000000;
        background-color: #24B2B2;
      }

      &.active {
        color: #BFBFBF;
        background-color: #000000;

        &:hover {
          color: #BFBFBF;
          background-color: #000000;
        }
      }
    }

    .nested-link {
      font-size: 14px;
      line-height: 16px;
      color: var(--asr-nav-link-color);
      text-transform: capitalize;
      background-color: #000000;
      word-break: break-all;

      &.active{
        color: var(--asr-nav-link-active-color);
        background-color: var(--asr-nav-link-active-bg);
      }

      &:hover {
        color: var(--asr-nav-link-hover-color);
        background-color: var(--asr-nav-link-hover-bg);
      }
    }
  }
}



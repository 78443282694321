@import "src/assets/styles/abstracts/mixins_media";

.EnterCodeModalContentContainer {
  // max modal width = 300px
  width: calc(min(calc(100vw - 2.4rem - 100px), 200px));

  @include phones() {
    width: unset;
  }

  .FormContent {
    display: flex;
    flex-flow: column;
    gap: 30px;
    padding: 10px 30px;

    @include media-breakpoint-down(300px) {
      padding: unset;
    }
  }
}

.EnterCodeSubmitFormWrapper {
  display: flex;
  flex-flow: column;
  gap: 20px;

  .EnterCodeInput {
    text-align: center;
    height: 36px;
    padding: 15px;
    border-radius: 4px;
    border: none !important;
    box-shadow: 3px 0 7px 0 #243A4733 inset;
    background: var(--asr-a-grey-5);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: white;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: white;
    }
  }

  .EnterCodeInputError {
    text-align: center;
  }

  .SubmitButton {
    width: 100%;
    background-color: var(--asr-a-yellow) !important;
    border: none !important;

    span {
      color: var(--asr-a-grey-bluish) !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      word-break: initial;
    }
  }
}

.AsrModalLogo {
  width: 117px !important;
  height: 117px !important;
}


.WrongCodeOverlayContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--asr-a-red);
  opacity: 90%;
  top: 0;
  left: 0;
  padding: 48px 30px;
  display: flex;
  flex-flow: column;
  gap: 50px;
  color: white;
  letter-spacing: 0.05em;
  text-align: center;

  .WrongCodeTitle {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }

  .WrongCodeDefinition {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
  }

  .WrongCodeActionText {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-decoration: underline;
    margin-top: auto;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
$client-content-color: #333333;
$main-background-color: #243B47;
$client-header-page: linear-gradient(180deg, #546057 0%, #7E9083 100%), #FFFFFF;
$yellow: #E1C123;
$link-color: #24B3B3;
$a-red: #EB5757;
$form-border-color: #cccccc;
$survey-creator-placeholder-img: "https://cdn.assessor.io/media/LogoSimple.svg";

$survey-card-body-padding: 30px;
$survey-card-body-padding-small: 10px;
$survey-image-picker-background-color: rgb(26, 179, 148);
$transition-time: .3s;
.Input {
  width: 100%;
}

.InputWithIcon {
  padding-right: 30px;
}

.tooltipErrorPopper {

  .tooltipErrorTooltip {
    background-color: var(--asr-error);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    max-width: 200px;
    padding: 7px;
  }

  .tooltipErrorArrow {
    position: absolute;
    color: var(--asr-error);
    z-index: 100;
  }

  .tooltipPlacementTop {
    margin-bottom: -10px !important;
  }
}

.InputIconContainer {
  position: absolute;
  top: 25%;
  right: 8px;

  &.Clickable {
    cursor: pointer;
  }
}


.errorScrollable {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}
@import "src/assets/styles/base/base";
@import "src/assets/styles/abstracts/mixins_media";

@mixin default-input-style() {
  position: relative;
  height: 60px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 23px !important;
  letter-spacing: 0.05em;
  color: $client-content-color;
  border-bottom: 1px solid #828282 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-right: 55px;
  padding-left: 20px;
  font-family: Roboto, sans-serif;
  transition: $transition-time;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px #FFFFFF inset; /* Change the color to your own background color */
    -webkit-text-fill-color: $client-content-color;
  }
}

.sv_q.sv_qstn.question-not-valid.text-input__wrapper {
  min-width: 100px !important;

  .survey-question-card-header {
    background: var(--asr-a-red);
  }

  &.text,
  &.email,
  &.number,
  &.date,
  &.datetime,
  &.datetime-local,
  &.month,
  &.tel,
  &.url,
  &.week,
  &.time,
  &.password {
    .survey-question-card-body {
      position: relative;

      &:before {
        content: url("../../../../../images/Exclamation.svg");
        position: absolute;
        z-index: 10;
        width: 30px;
        height: 30px;
        background-size: contain;
        top: calc(#{$survey-card-body-padding} + 13px);
        right: calc(#{$survey-card-body-padding} + 15px);
        @include phones() {
          top: calc(#{$survey-card-body-padding-small} + 13px);
          right: calc(#{$survey-card-body-padding-small} + 15px);
        }
      }
    }
  }

}

.sv_q.sv_qstn.text-input__wrapper {
  &.text,
  &.email,
  &.number,
  &.date,
  &.datetime,
  &.month,
  &.tel,
  &.url,
  &.week,
  &.time,
  &.datetime-local,
  &.password {
    .survey-question-card-body {
      position: relative;

      .text-input {
        @include default-input-style;

        &[aria-invalid="true"] {
          border-bottom: 1px solid var(--asr-a-red) !important;

          & .survey-question-card-header {
            background: var(--asr-a-red) !important;
          }
        }

        &[aria-invalid="true"] .survey-question-card-header {
          background: #000;
        }


        &:focus {
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #828282 !important;
        }

        &:hover {
          border-bottom: 1px solid var(--asr-a-blue) !important;
          color: var(--asr-a-blue);
          transition: $transition-time;
        }
      }
    }

    &.answered.question-valid, &.not-answered.not-required {
      .survey-question-card-body {
        .text-input {
          &[aria-invalid="false"] {
            border-bottom: 1px solid var(--asr-a-blue) !important;
          }
        }

        &:before {
          content: url("../../../../../images/verified-2.svg");
          position: absolute;
          z-index: 10;
          width: 30px;
          height: 30px;
          background-size: contain;
          top: calc(#{$survey-card-body-padding} + 18px);
          right: calc(#{$survey-card-body-padding} + 20px);

          @include phones() {
            top: calc(#{$survey-card-body-padding-small} + 18px);
            right: calc(#{$survey-card-body-padding-small} + 20px);
          }
        }
      }
    }
  }
}

// OTHER INPUTS
.sv_q.sv_qstn.text-input__wrapper {
  &.range, &.color {
    .survey-question-card-body {
      .text-input {
        @include default-input-style;
        padding: 0 20px;

        &:hover {
          border-bottom: 1px solid var(--asr-a-blue) !important;
          transition: $transition-time;
        }
      }
    }
  }

  &.color {
    .survey-question-card-body {
      .text-input {
        padding: 0 0 0 15px;
      }
    }
  }
}

// COMMENT
.survey-question-card-body {
  textarea {
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 23px !important;
    letter-spacing: 0.05em;
    color: $client-content-color;

    &:focus {
      border-color: rgb(231, 231, 231);
    }
  }
}
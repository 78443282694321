/*App Layout Styles*/

#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.app-container {
  @include display-flex(flex, row, nowrap);
  width: 100%;
}

.app-sidebar {
  @include display-flex(flex, column, nowrap);
  width: $side-nav-width;
  min-width: $side-nav-width;
  max-width: $side-nav-width;
}

.app-main-container {
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow: hidden;
}

.app-header {
  min-height: $app-bar-height;
}

.app-custom-scrollbar {
  & > .os-scrollbar {
    width: 10px;
    background: transparent;
    padding: 2px;
    & > .os-scrollbar-track {
      background: transparent;
      & > .os-scrollbar-handle {
        background: rgba(0, 0, 0, 0.5) !important;
        border: 1px solid #FFFFFF;
        border-radius: 3px !important;
      }
    }
  }
}

.app-main-content-scrollbar-wrapper {
  flex: 1;
  width: 100%;
}

.app-main-content-wrapper {
  width: 100%;
  min-height: 100%;
  @include display-flex(flex, column, nowrap);
}

.dotted-background {
  background: url("../../../../assets/images/dot-pattern-bg.png");
}

.app-container {
  &.fixed-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $side-nav-width;
      right: 0;

      @media screen and (max-width: 1199px) {
        left: 0;
        right: 0;
      }
    }
  }

  &.collapsible-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: 0;
      right: 0;
    }
  }

  &.mini-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $mini-drawer-width;
      right: 0;
    }

    .app-sidebar {
      width: $mini-drawer-width;
      min-width: $mini-drawer-width;
      max-width: $mini-drawer-width;
    }
  }
}

.app-header-horizontal {
  & .app-main-header {
    left: auto !important;
    position: relative !important;
    right: auto !important;

    & + .app-top-nav {
      @include box-shadow(0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
    }
  }

  & .app-top-nav {
    padding: 0 24px;
    position: relative;
    z-index: 1101;

    & .app-toolbar {
      min-height: $app-bar-height - 30px;
      height: $app-bar-height - 30px;
      max-height: $app-bar-height - 30px;
    }
  }

  & .app-main-header-top {
    z-index: 1102;
  }
}

.app-sidebar-description {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  padding: 10px;

  .license {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--asr-a-grey-2);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: var(--asr-a-grey-3);

    &:hover {
      color: var(--asr-a-grey-4);
      background: var(--asr-a-grey-2);

      .link {
        color: var(--asr-a-yellow);
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }

    .link {
      color: var(--asr-a-grey-3);
      text-decoration: underline;
      font-size: 10px;
      margin: 0 2px;
    }
  }
}
@import "src/assets/styles/abstracts/mixins_media";

.ModalContentContainer {
  display: flex;
  flex-flow: column;
  gap: 30px;
}

.ModalHeaderBlock {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  gap: 20px;

  @include phones() {
    flex-flow: wrap;
  }
}

.ModalHeader {
  display: flex;
  flex-flow: column;
  text-align: left;
  gap: 5px;

  .ModalHeaderTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  .ModalHeaderTextBlock {
    display: flex;
    flex-flow: wrap;
    gap: 6px;

    .ModalHeaderText {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

      &.Clickable {
        font-weight: 500;
        color: var(--asr-a-yellow);
        cursor: pointer;
      }
    }
  }
}

.ModalAccountBlock {
  display: flex;
  flex-flow: column;
  gap: 6px;
  justify-content: flex-end;
  text-align: right;

  @include phones() {
    justify-content: flex-start;
    text-align: left;
  }

  .AccountTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  .EnterCodeText {
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: var(--asr-a-yellow);
    cursor: pointer;
  }
}

.ModalFormFieldsBlockWrapper {
  display: flex;
  flex-flow: nowrap;
  gap: 40px;

  @include phones() {
    flex-flow: column;
    gap: 20px;
  }

  .ModalFormFieldsWrapper {
    flex: 1;
    order: 1;
    display: flex;
    flex-flow: column;
    gap: 20px;

    @include phones() {
      order: 2;
    }

    .InputRoot {
      font-weight: 500;
    }

    .InputLabel {
      text-transform: capitalize;
    }
  }

  .ModalFormFieldsLogo {
    padding: 10px;
    order: 2;

    @include phones() {
      order: 1;
    }
  }

  .FullNameFieldsWrapper {
    display: flex;
    flex-flow: nowrap;
    gap: 30px;

    @include survey-phones() {
      flex-flow: column;
      gap: 20px;
    }
  }
}

.ModalFooter {
  display: flex;
  flex-flow: nowrap;
  gap: 30px;
  word-break: break-word;

  @include phones() {
    flex-flow: column;
  }

  .SubmitButtonWrapper {
    order: 1;

    @include phones() {
      order: 2;
      margin: auto;
    }

    .SubmitButton {
      background-color: var(--asr-a-yellow) !important;
      border: none !important;

      span {
        color: var(--asr-a-grey-bluish) !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        word-break: initial;

        .AttachmentSpan {
          color: white !important;
          text-transform: lowercase;
        }
      }
    }
  }

  .AgreementBlockWrapper {
    align-self: center;
    order: 2;

    @include phones() {
      order: 1;
      width: 100%;
    }
  }

  .AgreementBlock {
    display: flex;
    flex-flow: nowrap;
    gap: 10px;
    align-items: center;

    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: var(--asr-a-grey-bluish);

    .Link {
      font-weight: 500;
      cursor: pointer;
      color: var(--asr-a-blue);
      text-decoration: underline;
    }
  }
}

.AsrModalLogo {
  width: 117px !important;
  height: 117px !important;
}


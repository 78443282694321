.headerClose {
  svg {
    cursor: pointer;
    color: #333333;

    &:hover {
      transform: rotate(-90deg);
      transition: .5s;

      path {
        fill: var(--asr-a-blue);
      }
    }
  }
}
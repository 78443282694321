@import "../base/base";

.asr-confirm-button {
  background-color: #242323 !important;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #d2f1db !important;
  text-transform: none;
  padding: 8px 20px;
}

.asr-cancel-button {
}

.asr-no-interactive-btn {
  &__table-unlock {
    &:hover {
      background: white;

      svg {
        path {
          fill: var(--asr-a-red);
        }
      }
    }
  }

  // TODO: REMOVE AFTER REFACTORING LABELS GROUP TABLE
  &__unlock:hover {
    background: var(--asr-table-actions-icon-background);

    svg {
      path {
        fill: $a-red;
      }
    }
  }
}

.asr-table-button {
  border: none;
  width: fit-content;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  &:disabled {
    cursor: default;
  }
}

.asr-mui-button {
  &__default.MuiButton-root {
    text-transform: initial;
    color: var(--asr-a-grey-1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.asr-card-add-button, .asr-card-add-button.MuiButton-root {
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: var(--asr-organization-link-color);
  border: none;
  background-color: transparent;
  text-transform: none;

  &:hover {
    background-color: transparent;
  }
}

.asr-select-add-item-button {
  width: 32px;
  height: 32px;
  background: var(--asr-a-grey-3);
  border-radius: 3px;
  border: none;
  margin-bottom: 6px;


  svg{
    width: 20px;
    height: 20px;
    path {
      fill: white;
    }
  }

  &:hover{
    background: #24B3B3;
  }
}
@import 'assets/styles/base/base';
@import "assets/styles/app";
@import "overlayscrollbars/css/OverlayScrollbars.css";

//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 300;
//  font-display: optional;
//  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 400;
//  font-display: optional;
//  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 500;
//  font-display: optional;
//  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Changa One';
//  font-style: normal;
//  font-weight: 400;
//  font-display: optional;
//  src: url('./assets/fonts/ChangaOne-Regular.ttf') format('truetype');
//}

.loading {
  position: absolute;
  top: 50%;
  right: 48%;
}

body {
  letter-spacing: 0.05em !important;
  font-family: var(--asr-app-font);
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


.MuiPaper-root.MuiPopover-paper  {
  .Mui-selected {
    background-color: var(--asr-a-grey-6);
    &:hover {
      background-color: var(--asr-a-grey-6);
    }
  }
}

.MuiPaper-root.MuiAutocomplete-paper{
  .MuiAutocomplete-option{
    &:hover{
      background-color: var(--asr-a-grey-6);
    }
    &[aria-selected="true"], &[aria-selected="true"].Mui-focused {
      background-color: #fff;
      &:hover{
        background-color: var(--asr-a-grey-6);
      }
    }
  }
}

fieldset {
  legend {
    width: fit-content;
  }
}

// INPUT
.MuiOutlinedInput-root.MuiInputBase-root{
   &.Mui-focused{
   fieldset{
       border-width: 1px;
     }
   }
}
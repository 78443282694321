.survey-question-card-body {
  .widget-datepicker {
    height: 60px !important;
    border-radius: unset;
  }
}

.question-not-valid{
  .survey-question-card-body {
    .widget-datepicker {
      border-bottom: 1px solid var(--asr-a-red) !important;

      &:hover, &:focus{
        border-bottom: 1px solid var(--asr-a-blue) !important;
      }
    }
  }
}

.question-valid{
  .survey-question-card-body {
    .widget-datepicker {
      border-bottom: 1px solid var(--asr-a-blue) !important;
    }
  }
}
.sa-visualizer__header {
  font-family: var(--font-family-secondary, Raleway, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
}

.sa-visualizer__content {
  font-family: var(--font-family, "Segoe UI", SegoeUI, Arial, sans-serif);
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.sa-visualizer__footer {
  margin-top: 10px;
}

.sa-visualizer__footer-title {
  display: inline-block;
  font-family: var(--font-family-secondary, Raleway, Arial, sans-serif);
  font-size: var(--root-font-size, 14px);
  color: #404040;
  line-height: 34px;
  vertical-align: middle;
  margin: 0;
}

.sa-visualizer__footer-content {
  margin-top: 10px;
}
.EvolutionaryContentInfoBlock {
  display: flex;
  flex-flow: column;
  gap: 8px;
  align-items: center;

  .Bold {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .Link {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    color: var(--asr-a-yellow);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }


  .ContactSupportText {
    margin-top: 16px;
  }
}

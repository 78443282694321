
.LanguageBox {
  position: relative;
  display: flex;
  align-items: center;

  .LanguageLabel {
    margin-right: 1.5em;
  }
}

.Dropdown {
  width: 100%;

  .DropdownToggle {
    align-items: center;
    display: flex;
    flex-flow: nowrap;
    cursor: pointer;

    span {
      text-transform: capitalize;
    }
  }
}

.LanguagesDropdownMenu {
  background-color: #000000;
  top: 13px !important;
  padding: 5px 0;
  min-width: unset;

  &[aria-hidden="true"] {
    display: none;
  }

  .LanguagesDropdownList {
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 6px 10px;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      flex-flow: nowrap;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
      min-width: 100px;

      &.IsSystemLang {
        min-width: 120px;
        padding-left: 10px;
      }

      &.EmptyIconLang {
        padding-left: 30px;
      }

      &.Active, &:hover {
        background-color: var(--asr-a-yellow);

        .LanguageName {
          color: #000000;
        }

        circle {
          fill: var(--asr-header-logo-color-st1) !important;
        }

        path {
          fill: var(--asr-header-logo-color-st0) !important;
        }
      }

      svg {
        width: 12px !important;
        height: 12px !important;
      }

      .LanguageName {
        color: var(--asr-a-yellow);
        text-transform: capitalize;
      }

      circle {
        fill: var(--asr-header-logo-color-st0) !important;
      }

      path {
        fill: var(--asr-header-logo-color-st1) !important;
      }
    }
  }
}
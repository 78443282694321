@import "src/assets/styles/abstracts/mixins_media";
@import "src/assets/styles/abstracts/mixins";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 3s;

@mixin background-gradient() {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

.surveyTermTooltipContainer {
  position: relative;
  display: inline-block;

  a {
    display: block;
  }
}

.surveyTermTooltipContent {
  position: absolute;
  width: 360px;
  background-color: var(--asr-a-grey-6);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  color: var(--asr-a-grey-1);
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
  word-break: break-word;
  z-index: 200000;

  top: 0;
  left: 0;

  @include tablets() {
    width: 300px;
  }

  @include phones() {
    width: 180px;
    font-size: 10px;
    line-height: 12px;
  }

  &[data-placement="top"] {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      right: var(--term-tooltip-left);
      bottom: -15px;
      transform: rotate(180deg);
      border: 15px solid transparent;
      border-top: 0;
      border-bottom: 15px solid var(--asr-a-grey-6);
    }
  }

  &[data-placement="bottom"] {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      left: var(--term-tooltip-left);
      top: -10px;
      border: 15px solid transparent;
      border-top: 0;
      border-bottom: 15px solid var(--asr-a-grey-6);
    }
  }
}


// PLACEHOLDER
.surveyTermTooltipPlaceholder {
  position: relative;
}

.skeletonLine {
  width: 100%;
  height: 10px;
  display: block;
  background: lightgray;
  margin-bottom: 5px;

  @include background-gradient;
  animation: shine-lines $animation-duration infinite linear;
}

@keyframes shine-lines {
  0% {
    background-position: 100%;
  }
  40%, 100% {
    background-position: -200%;
  }
}

.innerTooltipTerm {
  border-bottom: 1px dotted black;
}